import {
  Box,
  Grid,
  Typography,
  IconButton,
  Divider,
  FormControlLabel,
  Stepper,
  Step,
  StepLabel,
  Radio,
  Backdrop,
  CircularProgress
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import styles from './UserBulkUpload.module.scss'
import { BreadCrumb } from '../../../UIComponents/Breadcrumb/BreadCrumb'
import { DownloadIcon } from '../../../utils/icons/DownloadIcon'
import { ErrorIcon } from '../../../utils/icons/ErrorIcon'
import { CustomButton } from '../../../UIComponents/Button/CustomButton'
import { CheckCircleIcon } from '../../../utils/icons/CheckCircleIcon'
import { BoldInfoIcon } from '../../../utils/icons/BoldInfoIcon'
import { PrimaryCheckbox } from '../../../UIComponents/CheckboxButton/PrimaryCheckBox'
import { useDispatch, useSelector } from 'react-redux'
import withStore from '../../../store/withStore'
import {
  downloadExecutionJob,
  downloadUploadedRecords,
  executionJob,
  importUploadedRecords
} from '../../../store/UserBulkImport/actions'
import { verifyAccessToken } from '../../../store'
import { BulkUploadModal } from '../UserList/BulkUploadModal'
import UUIToastService from '../../../utils/ToastService'
import { DraftIcon } from '../../../utils/icons/DraftIcon'
import { Progressicon } from '../../../utils/icons/ProgressIcon'
import _env from '../../../../env'
import { useHistory } from 'react-router-dom'
import { ChevronNext } from '../../../utils/icons/ChevronNextIcon'
import { FileError } from '../../../utils/icons/FileError'

function getSteps() {
  return ['Review and import', 'Summary']
}

const UserBulkUpload = ({ jobId, config, isProfile }) => {
  _env.init(config, isProfile)
  const history = useHistory()
  const [isOpenImportUsersModal, setOpenImportUsersModal] = useState(false)
  const [importStatus, setImportStatus] = useState(2) // 1 - inprogress 2- draft 3- completed 4 -failed
  const [canShowBlankScreen, setBlankScreen] = useState(false)
  const [blankScreenMessage, setBlankScreenMessage] = useState('')
  const [activeStep, setActiveStep] = React.useState(0)
  const [canUpdateExistingUsers, setCanUpdateExistingUsers] = useState(false)
  const [isFileDownloading, setDownlStatus] = useState(false)
  const { isTokenVerified } = useSelector((state) => state.common)
  const steps = getSteps()
  const { isProcessDone: isApiProcessDone, userBulkImportInfo } = useSelector(
    (state) => state.userBulkImport
  )

  const dispatch = useDispatch()
  useEffect(() => {
    if (isTokenVerified === true) {
      const getData = async () => {
        await dispatch(executionJob(jobId, 'is_validation_completed'))
          .then((res) => {
            setImportStatus(res.data.bulk_result)
            setActiveStep(getActiveStep(res.data.is_job_completed))
            setCanUpdateExistingUsers(res.data.exist_users > 0)
          })
          .catch((rej) => {
            if (rej) {
              if (!rej.response.data.status) {
                setImportStatus(4)
                setBlankScreen(true)
                setBlankScreenMessage(rej.response.data.message)
              }
            }
          })
      }
      getData()
    }
  }, [isTokenVerified])

  useEffect(() => {
    const roleCanAccess = [
      'travel_itilite_admin',
      'travel_admin',
      'expense_itilite_admin',
      'expense_admin'
    ]
    dispatch(verifyAccessToken(roleCanAccess))
  }, [])

  const importUserHandler = () => {
    setOpenImportUsersModal(true)
  }

  const getActiveStep = (status) => {
    if (!status) return 0
    else return 1
  }

  const closeImportUsersModal = () => {
    setOpenImportUsersModal(false)
  }

  const handleImportPagenavigation = (jobId) => {
    history.push(
      config.routes.bulkUpload.import.path.replace(
        `:${config.routes.bulkUpload.import.paramKeys.jobId}`,
        jobId
      )
    )
  }

  const closeImportModal = (jobId) => {
    setOpenImportUsersModal(false)
    handleImportPagenavigation(jobId)
    // dispatch(executionJob(jobId, 'is_validation_completed'))
  }

  const handleDownloadRecords = async (type) => {
    setDownlStatus(true)
    await dispatch(downloadUploadedRecords(type, jobId))
      .then((validatedRes) => {
        if (validatedRes.data?.status) {
          dispatch(downloadExecutionJob(validatedRes.data.audit_id, 'status'))
            .then((res) => {
              window.open(res.data.presigned_url, '_self').focus()
            })
            .catch((reject) => {
              if (reject) {
                if (!reject.response.data.status) {
                  UUIToastService.warningNotify(reject.response.data.message)
                }
              }
            })
            .finally(() => {
              setDownlStatus(false)
            })
        }
      })
      .catch((reject) => {
        if (reject) {
          if (!reject.response.data.status) {
            UUIToastService.warningNotify(reject.response.data.message)
            setDownlStatus(false)
          }
        }
      })
  }

  const handleImportNumbers = () => {
    const value =
      +userBulkImportInfo.success +
      (canUpdateExistingUsers ? +userBulkImportInfo.exist_users : 0)
    return value < 2 ? value + ' user' : value + ' users'
  }

  const handleSuccessDesc = () => {
    var newUsers =
      +userBulkImportInfo.success > 0
        ? +userBulkImportInfo.success === 1
          ? userBulkImportInfo.success + ' new user '
          : userBulkImportInfo.success + ' new users '
        : ' '
    var existingUser =
      +userBulkImportInfo.exist_users > 0
        ? +userBulkImportInfo.exist_users === 1
          ? userBulkImportInfo.exist_users + ' existing user '
          : userBulkImportInfo.exist_users + ' existing users '
        : ' '
    return +userBulkImportInfo.success + +userBulkImportInfo.exist_users < 2
      ? newUsers +
          existingUser +
          ' found with a valid format. This record can be successfully imported. '
      : newUsers +
          existingUser +
          ' found with a valid format. These records can be successfully imported. '
  }

  const handleSuccessTitle = () => {
    var totalPassedRecords =
      +userBulkImportInfo.success + +userBulkImportInfo.exist_users
    return totalPassedRecords === 1
      ? totalPassedRecords + ' record passed validation'
      : totalPassedRecords + ' records passed validation'
  }

  const handleImportUploadRecords = async () => {
    if (
      userBulkImportInfo.success === 0 &&
      userBulkImportInfo.exist_users > 0
    ) {
      if (!canUpdateExistingUsers) {
        UUIToastService.warningNotify('Select update any existing users box')
        return false
      }
    }
    setImportStatus(1)
    const res = await dispatch(
      importUploadedRecords(jobId, canUpdateExistingUsers ? 1 : 0)
    )
    if (res.data.status) {
      await dispatch(executionJob(jobId, 'is_job_completed'))
        .then((resp) => {
          if (resp.data.status) {
            setImportStatus(+resp.data.bulk_result)
            setActiveStep(1)
          } else {
            setImportStatus(+resp.data.bulk_result)
            setActiveStep(0)
          }
        })
        .catch((rej) => {
          if (rej) {
            if (!rej.response.data.status) {
              setImportStatus(4)
              setBlankScreen(true)
              setBlankScreenMessage(rej.response.data.message)
            }
          }
        })
    }
  }

  const handleExistingUserUpdation = (event) => {
    setCanUpdateExistingUsers(!canUpdateExistingUsers)
  }

  const CustomStepIcon = (step) => {
    if (
      (importStatus === 1 || importStatus === 2 || importStatus === 4) &&
      step.step === Number(activeStep)
    ) {
      return (
        <Radio
          checked
          className={clsx(styles.customRadioEach)}
          size='small'
          disabled
        />
      )
    }
    if (
      (importStatus === 1 || importStatus === 2 || importStatus === 4) &&
      step.step !== Number(activeStep)
    ) {
      return (
        <Radio className={clsx(styles.customRadioEach)} size='small' disabled />
      )
    }

    if (importStatus === 3 && step.step === Number(activeStep)) {
      return (
        <Radio
          checked
          className={clsx(styles.customRadioEach)}
          size='small'
          disabled
        />
      )
    }

    if (importStatus === 3 && step.step !== Number(activeStep)) {
      return <CheckCircleIcon width='20' height='20' color='#9CA3AF' />
    }
  }

  const customStatus = () => {
    switch (importStatus) {
      case 2:
        return (
          <React.Fragment>
            <DraftIcon />
            <Typography className={styles.descText}>Draft</Typography>
          </React.Fragment>
        )
      case 1:
        return (
          <React.Fragment>
            <Progressicon />
            <Typography className={styles.descText}>In progress</Typography>
          </React.Fragment>
        )
      case 3:
        return (
          <React.Fragment>
            <CheckCircleIcon width='16' height='16' color='#059669' />
            <Typography className={styles.descText}>Completed</Typography>
          </React.Fragment>
        )
      case 4:
        return (
          <React.Fragment>
            <ErrorIcon />
            <Typography className={styles.descText}>Failed</Typography>
          </React.Fragment>
        )
    }
  }

  return (
    <Box component='div' className={styles.UUIUserManagementImport}>
      <Backdrop
        open={importStatus === 1 || isFileDownloading}
        className={styles.backdrop}
      >
        <CircularProgress color='inherit' />
      </Backdrop>
      <React.Fragment>
        <Box component='div' className={clsx(styles.pageHeader)}>
          <Box component='div' className={styles.breadcrumb}>
            <BreadCrumb
              breadCrumbs={[
                {
                  href: config.routes.users.list.path,
                  label: 'Users',
                  hasLink: true
                },
                {
                  href: config.routes.bulkUpload.bulkActionHistory.path,
                  label: 'Bulk action history',
                  hasLink: true
                }
              ]}
              customStyle={{ color: '#6B7280' }}
              separator={<ChevronNext />}
              forward
            />
          </Box>
          <Box
            component='div'
            display='flex'
            justifyContent='space-between'
            alignItems='center'
          >
            <Box component='div' className={styles.headingWrapper}>
              <Typography
                variant='h3'
                className={clsx(styles.heading, styles.headingLarge)}
              >
                Import users
              </Typography>
            </Box>
          </Box>
        </Box>
      </React.Fragment>
      <Divider className={styles.dividerColor} />
      {canShowBlankScreen && (
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          flexDirection='column'
          className={styles.fileEmptyScreenClass}
        >
          <FileError />
          <Typography className={styles.emptyScreenText}>
            {blankScreenMessage}
          </Typography>
        </Box>
      )}
      {isApiProcessDone && !canShowBlankScreen && (
        <Grid container className={styles.container}>
          <Grid
            item
            lg={9}
            xl={9}
            md={9}
            sm={12}
            xs={12}
            style={{ maxWidth: '950px', width: '800px' }}
          >
            <Stepper
              activeStep={activeStep}
              nonLinear
              className={styles.stepper}
            >
              {steps.map((label, _i) => (
                <Step key={label}>
                  <StepLabel icon={<CustomStepIcon step={_i} />}>
                    {label}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
            {importStatus === 1 && (
              <Box
                display='flex'
                alignItems='center'
                justifyContent='center'
                width='800px'
                borderRadius='6px'
                className={styles.importProgressInfo}
              >
                <BoldInfoIcon width='18' height='18' color='#7C2D12' />
                <Typography className={styles.importProgressInfotext}>
                  Import is in progress. This can take a few minutes. You can
                  check the status from Bulk action history.
                </Typography>
              </Box>
            )}
            {importStatus !== 3 && (
              <Box paddingLeft='12px'>
                <Grid container className={styles.recordsDetails}>
                  <Grid
                    item
                    className={styles.rightBorder}
                    lg={3}
                    md={3}
                    xl={3}
                  >
                    <Box
                      display='flex'
                      justifyContent='flex-start'
                      alignItems='center'
                      padding='16px 0px 0px 26px'
                      style={{ gap: '45px' }}
                    >
                      <Typography className={clsx(styles.mildText)}>
                        Total &nbsp; records
                      </Typography>
                      {+userBulkImportInfo.total_records > 0 && (
                        <IconButton
                          onClick={() => handleDownloadRecords('total')}
                          className={styles.buttonTopalignment}
                        >
                          <DownloadIcon width='11' height='14' />
                        </IconButton>
                      )}
                    </Box>
                    <Box paddingLeft='24px'>
                      <Typography
                        className={clsx(styles.recordValue, {
                          [styles.lightLargeFont]:
                            +userBulkImportInfo.total_records === 0
                        })}
                      >
                        {userBulkImportInfo.total_records}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid
                    item
                    className={styles.rightBorder}
                    lg={3}
                    md={3}
                    xl={3}
                  >
                    <Box
                      display='flex'
                      justifyContent='flex-start'
                      alignItems='center'
                      padding='16px 0px 0px 26px'
                      style={{ gap: '45px' }}
                    >
                      <Typography className={styles.mildText}>
                        Valid new records
                      </Typography>
                      {+userBulkImportInfo.success > 0 && (
                        <IconButton
                          onClick={() => handleDownloadRecords('success')}
                          className={styles.buttonTopalignment}
                        >
                          <DownloadIcon width='11' height='14' />
                        </IconButton>
                      )}
                    </Box>
                    <Box paddingLeft='24px'>
                      <Typography
                        className={clsx(styles.recordValue, {
                          [styles.lightLargeFont]:
                            +userBulkImportInfo.success === 0
                        })}
                      >
                        {userBulkImportInfo.success}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid
                    item
                    className={styles.rightBorder}
                    lg={3}
                    md={3}
                    xl={3}
                  >
                    <Box
                      display='flex'
                      justifyContent='flex-start'
                      alignItems='center'
                      padding='16px 0px 0px 26px'
                      style={{ gap: '45px' }}
                    >
                      <Typography className={styles.mildText}>
                        Valid existing records
                      </Typography>
                      {+userBulkImportInfo.exist_users > 0 && (
                        <IconButton
                          onClick={() => handleDownloadRecords('exist')}
                          className={styles.buttonTopalignment}
                        >
                          <DownloadIcon width='11' height='14' />
                        </IconButton>
                      )}
                    </Box>
                    <Box paddingLeft='24px'>
                      <Typography
                        className={clsx(styles.recordValue, {
                          [styles.lightLargeFont]:
                            +userBulkImportInfo.exist_users === 0
                        })}
                      >
                        {userBulkImportInfo.exist_users}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={3} md={3} xl={3}>
                    <Box
                      display='flex'
                      justifyContent='flex-start'
                      alignItems='center'
                      padding='16px 0px 0px 26px'
                      style={{ gap: '45px' }}
                    >
                      <Typography className={styles.mildText}>
                        Invalid records
                      </Typography>
                      {+userBulkImportInfo.failure > 0 && (
                        <IconButton
                          onClick={() => handleDownloadRecords('failure')}
                          className={styles.buttonTopalignment}
                        >
                          <DownloadIcon width='11' height='14' />
                        </IconButton>
                      )}
                    </Box>
                    <Box paddingLeft='24px'>
                      <Typography
                        className={clsx(styles.recordValue, {
                          [styles.lightLargeFont]:
                            +userBulkImportInfo.failure === 0
                        })}
                      >
                        {userBulkImportInfo.failure}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
                {+userBulkImportInfo.failure > 0 && (
                  <Box>
                    <Typography className={styles.validationLine}>
                      <Box component='span' display='inline-flex'>
                        <ErrorIcon />
                      </Box>
                      {+userBulkImportInfo.failure === 1
                        ? userBulkImportInfo.failure +
                          ' record failed validation'
                        : userBulkImportInfo.failure +
                          ' records failed validation'}
                    </Typography>
                    <Typography
                      className={clsx(styles.descText)}
                      style={{ paddingLeft: '27px' }}
                    >
                      {+userBulkImportInfo.failure === 1
                        ? ' This record will have to be corrected and re-imported.'
                        : ' These records will have to be corrected and re-imported. '}
                    </Typography>
                    <Box display='flex' style={{ paddingLeft: '28px' }}>
                      <CustomButton
                        buttonText='Download invalid records'
                        type='text'
                        onClickHandler={() => handleDownloadRecords('failure')}
                      />
                      <CustomButton
                        buttonText='Upload again'
                        type='text'
                        onClickHandler={importUserHandler}
                      />
                    </Box>
                  </Box>
                )}
                {(+userBulkImportInfo.success > 0 ||
                  +userBulkImportInfo.exist_users > 0) && (
                  <React.Fragment>
                    <Divider className={styles.divider} />
                    <Box>
                      <Typography className={styles.validationLine}>
                        <Box component='span' display='inline-flex'>
                          <CheckCircleIcon
                            width='16'
                            height='16'
                            color='#059669'
                          />
                        </Box>
                        {handleSuccessTitle()}
                      </Typography>
                      <Typography
                        className={clsx(styles.descText)}
                        style={{ paddingLeft: '27px' }}
                      >
                        {handleSuccessDesc()}
                      </Typography>
                      {/* {+userBulkImportInfo.exist_users > 0 && ( */}
                      <Box display='flex' style={{ paddingLeft: '28px' }}>
                        <FormControlLabel
                          control={
                            <PrimaryCheckbox
                              handleCheckBoxHandler={handleExistingUserUpdation}
                              checkboxFlag={canUpdateExistingUsers}
                            />
                          }
                          label='Update any existing users'
                          className={clsx(styles.checkboxlabel, {
                            [styles.disabledText]:
                              +userBulkImportInfo.exist_users === 0
                          })}
                          disabled={+userBulkImportInfo.exist_users === 0}
                        />
                      </Box>
                      {/* )} */}
                      <Box
                        display='flex'
                        style={{ paddingLeft: '27px' }}
                        marginTop='15px'
                      >
                        <CustomButton
                          buttonText={
                            importStatus !== 1
                              ? 'Import ' + handleImportNumbers()
                              : 'Importing ' + handleImportNumbers()
                          }
                          type='contained'
                          onClickHandler={handleImportUploadRecords}
                          disabled={importStatus === 1}
                        />
                      </Box>
                    </Box>
                  </React.Fragment>
                )}
              </Box>
            )}
            {importStatus === 3 && (
              <Box className={styles.completedContainer}>
                <Box>
                  <Typography className={styles.importStatus}>
                    Import was successful
                  </Typography>
                </Box>
                <Box className={styles.recordRow}>
                  <Box
                    display='flex'
                    justifyContent='space-between'
                    alignItems='center'
                  >
                    <Typography className={styles.numberText}>
                      {userBulkImportInfo.total_records}
                      <span>
                        {' '}
                        {+userBulkImportInfo.total_records === 1
                          ? 'total record'
                          : 'total records'}{' '}
                      </span>
                    </Typography>
                    <CustomButton
                      buttonText='Download records'
                      type='text'
                      onClickHandler={() => handleDownloadRecords('total')}
                    />
                  </Box>
                  <Divider />
                  {+userBulkImportInfo.success > 0 && (
                    <React.Fragment>
                      <Box
                        display='flex'
                        justifyContent='space-between'
                        alignItems='center'
                      >
                        <Typography className={styles.numberText}>
                          {userBulkImportInfo.success}{' '}
                          <span>
                            {' '}
                            {+userBulkImportInfo.success === 1
                              ? 'new user added '
                              : 'new users added '}{' '}
                          </span>
                        </Typography>
                        <CustomButton
                          buttonText='Download records'
                          type='text'
                          onClickHandler={() =>
                            handleDownloadRecords('success')
                          }
                        />
                      </Box>
                      <Divider />
                    </React.Fragment>
                  )}
                  {+userBulkImportInfo.exist_users > 0 && (
                    <React.Fragment>
                      <Box
                        display='flex'
                        justifyContent='space-between'
                        alignItems='center'
                      >
                        <Typography className={styles.numberText}>
                          {userBulkImportInfo.exist_users}{' '}
                          <span>
                            {' '}
                            {+userBulkImportInfo.exist_users === 1
                              ? 'existing user updated'
                              : 'existing users updated'}{' '}
                          </span>
                        </Typography>
                        <CustomButton
                          buttonText='Download records'
                          type='text'
                          onClickHandler={() => handleDownloadRecords('exist')}
                        />
                      </Box>
                      <Divider />
                    </React.Fragment>
                  )}
                  {+userBulkImportInfo.failure > 0 && (
                    <React.Fragment>
                      <Box
                        display='flex'
                        justifyContent='space-between'
                        alignItems='center'
                      >
                        <Typography className={styles.numberText}>
                          {userBulkImportInfo.failure}{' '}
                          <span>
                            {' '}
                            {+userBulkImportInfo.failure === 1
                              ? 'invalid record'
                              : 'invalid records'}{' '}
                          </span>
                        </Typography>
                        <CustomButton
                          buttonText='Download records'
                          type='text'
                          onClickHandler={() =>
                            handleDownloadRecords('failure')
                          }
                        />
                      </Box>
                      <Divider />
                    </React.Fragment>
                  )}
                </Box>
              </Box>
            )}
          </Grid>
          <Grid
            item
            lg={3}
            xl={3}
            md={3}
            sm={12}
            xs={12}
            style={{ paddingLeft: '15px' }}
          >
            <Box
              display='flex'
              flexDirection='column'
              className={styles.colGap}
              padding='40px 0px'
            >
              <Box display='flex' style={{ gap: '8px' }} alignItems='center'>
                {customStatus()}
              </Box>
              <Box>
                <Typography className={styles.infoLbl}>Import ID</Typography>
                <Typography className={styles.infoText}>
                  {userBulkImportInfo.import_id}
                </Typography>
              </Box>
              <Box>
                <Typography className={styles.infoLbl}>File name</Typography>
                <Typography className={styles.infoText}>
                  {userBulkImportInfo.file_name}
                </Typography>
              </Box>
              <Box>
                <Typography className={styles.infoLbl}>
                  File uploaded by
                </Typography>
                <Typography className={styles.infoText}>
                  {userBulkImportInfo.uploaded_by.first_name +
                    ' ' +
                    userBulkImportInfo.uploaded_by.last_name}
                </Typography>
              </Box>
              <Box>
                <Typography className={styles.infoLbl}>
                  Import confirmed by
                </Typography>
                <Typography className={styles.infoText}>
                  {userBulkImportInfo.uploaded_by.first_name +
                    ' ' +
                    userBulkImportInfo.uploaded_by.last_name}
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      )}
      <BulkUploadModal
        isOpenImportUsersModal={isOpenImportUsersModal}
        closeImportUsersModal={closeImportUsersModal}
        closeImportModal={closeImportModal}
      />
    </Box>
  )
}

export default withStore(UserBulkUpload)
