import {
  SET_ACTIVE_STATUS,
  SET_USER_LIST_FIELDS,
  SET_BULK_DEACTIVATED_USERS,
  SHOW_LOADER,
  STOP_LOADER
} from './types'

const initialState = {
  isDataFetching: false,
  userListInfo: {
    count: 0,
    next: null,
    previous: null,
    results: []
  },
  deactivatedUsers: []
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_LOADER: {
      return {
        ...state,
        isDataFetching: true
      }
    }
    case STOP_LOADER: {
      return {
        ...state,
        isDataFetching: false
      }
    }
    case SET_USER_LIST_FIELDS: {
      const userListInfo = action.payload
      const deactivatedUsers =
        userListInfo.results.length > 0
          ? userListInfo.results
              .filter((val) => !val.is_active)
              .map((obj) => obj.id.toString())
          : []
      return {
        ...state,
        userListInfo: action.payload,
        deactivatedUsers: deactivatedUsers
      }
    }
    case SET_ACTIVE_STATUS: {
      const userListInfo = { ...state.userListInfo }
      userListInfo.results.length > 0 &&
        userListInfo.results.map((o, i) => {
          if (o.id === +action.payload.id) {
            userListInfo.results[i].is_active = action.payload.is_active
            // return true // stop searching
          }
        })
      const deactivatedUsers =
        userListInfo.results.length > 0
          ? userListInfo.results
              .filter((val) => !val.is_active)
              .map((obj) => obj.id.toString())
          : []
      return {
        ...state,
        userListInfo: {
          ...state.userListInfo,
          results: [...userListInfo.results]
        },
        deactivatedUsers: deactivatedUsers
      }
    }

    case SET_BULK_DEACTIVATED_USERS: {
      const userListInfo = { ...state.userListInfo }
      userListInfo.results.length > 0 &&
        userListInfo.results.map((o, i) => {
          action.payload.map((i) => {
            if (o.id === +i) {
              o.is_active = false
              // return true // stop searching
            }
          })
        })
      const deactivatedUsers =
        userListInfo.results.length > 0
          ? userListInfo.results
              .filter((val) => !val.is_active)
              .map((obj) => obj.id.toString())
          : []
      return {
        ...state,
        userListInfo: {
          ...state.userListInfo,
          results: [...userListInfo.results]
        },
        deactivatedUsers: deactivatedUsers
      }
    }

    default:
      return state
  }
}

export default reducer
