import React from 'react'
import './Userpanel.scss'
import { CustomModal } from '../../../UIComponents/Modal'
import { Box } from '@material-ui/core'
// import AsyncCustomSelect from '../../../UIComponents/Select/AsyncCustomSelect'
// import { commonLoadOptions } from '../../../UIComponents/Select/asyncLoadOptions'
import { useSelector } from 'react-redux'
import { BoldInfoIcon } from '../../../utils/icons/BoldInfoIcon'
import { CustomLabelComponent } from '../../../UIComponents/Label'
import AsyncCustomSelect from '../../../UIComponents/Select/AsyncCustomSelect'
import { commonLoadOptions } from '../../../UIComponents/Select/asyncLoadOptions'
export default function MoveUserDialog({
  name,
  tableName,
  isOpen,
  closeHandler,
  submitHandler,
  customSelectHandler,
  list,
  value,
  isError
}) {
  const { dialogData } = useSelector((state) => state.userPanel)
  const data = dialogData.data
  const labelData = dialogData.labels
  return (
    <CustomModal
      isOpen={isOpen}
      closeHandler={closeHandler}
      headerText={labelData.headerText}
      cancelLabel='Cancel'
      submitLabel='Add'
      submitHandler={submitHandler}
      width='400px'
      hideCloseIcon={false}
      modalBodyStyle={{
        maxHeight: '600px',
        overflow: 'auto',
        margin: '0px'
      }}
      footerStyle={{
        justifyContent: 'end'
      }}
    >
      <Box
        display='flex'
        justifyContent='center'
        alignItems='center'
        flexDirection='column'
        // padding='8px 24px'
      >
        <Box
          display='flex'
          style={{
            gap: '12px',
            border: '1px solid #FDE047',
            backgroundColor: '#FEFCE8',
            borderRadius: '6px',
            padding: '16px'
          }}
        >
          <BoldInfoIcon width='35' height='35' />
          <CustomLabelComponent
            label={`${
              data.name
            } cannot be deleted as it is associated with some users. You can delete this ${labelData.module?.toLowerCase()} only once you move the users to a different ${labelData.module?.toLowerCase()}.`}
            style={{ fontWeight: 'normal' }}
          />
        </Box>
      </Box>

      <AsyncCustomSelect
        id={name}
        additional={{
          page: 1,
          tabelName: tableName,
          placeholder: 'Select ' + name?.toLowerCase()
        }}
        label={`Users from this ${labelData.module?.toLowerCase()} will be moved to:`}
        // secondaryLabel={_p.secondaryLabel}
        title={`Select ${name?.toLowerCase()}.`}
        value={value}
        name={name}
        customSelectHandler={customSelectHandler}
        // inputType={_p.inputType}
        loadOption={commonLoadOptions}
        isError={isError}
        errorMsg={`Please select ${name?.toLowerCase()}.`}
        // required={_p.required}
        // readOnly={_p.readOnly}
        // placeHolderCheck={
        //   inputState[_p.name] !== undefined &&
        //   inputState[_p.name].value === ''
        // }
        // hasInfoIcon={!!_p.tooltip}
        // tooltipText={_p.tooltip}
      />
    </CustomModal>
  )
}
