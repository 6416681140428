import React from 'react'
import Button from '@material-ui/core/Button'
import clsx from 'clsx'
import classes from './CustomButton.module.scss'
import {
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Typography
} from '@material-ui/core'
import { ExpandMoreIcon } from '../../utils/icons/ExpandMoreIcon'

export const CustomSelectButton = (props) => {
  const {
    buttonText,
    disableRipple = true,
    onClickHandler = () => {},
    menuListInfo = []
  } = props
  const [open, setOpen] = React.useState(false)
  const anchorRef = React.useRef(null)

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }

    setOpen(false)
    onClickHandler(event)
  }

  const handleListKeyDown = (event) => {
    if (event.key === 'Tab') {
      event.preventDefault()
      setOpen(false)
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open)
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus()
    }

    prevOpen.current = open
  }, [open])
  return (
    <React.Fragment>
      <Button
        endIcon={<ExpandMoreIcon width='10' height='10' />}
        className={clsx(classes.muiBtn, classes.outlinedBtn)}
        disableRipple={disableRipple}
        variant='outlined'
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup='true'
        onClick={handleToggle}
      >
        {buttonText}
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        style={{ zIndex: '9' }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom'
            }}
          >
            <Paper className={classes.menuListContainer} elevation={0}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  className={classes.menuList}
                  id='menu-list-grow'
                  onKeyDown={(e) => handleListKeyDown(e)}
                >
                  {menuListInfo.map((val, _i) => {
                    return (
                      <MenuItem
                        onClick={val.handler}
                        key={_i}
                        className={classes.menuItemContainer}
                        disableRipple
                      >
                        <Typography className={classes.menuItemtext}>
                          {val.label}
                        </Typography>
                      </MenuItem>
                    )
                  })}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </React.Fragment>
  )
}
