import React from 'react'
import styles from './paymentCard.module.scss'
import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'
import clsx from 'clsx'
import { Grid, Divider } from '@material-ui/core'
import { CustomLabelComponent } from '../../../../UIComponents/Label'
import _env from '../../../../../env'
import { UUILocalStorageService } from '../../../../utils/LocalStorageService'
import {
  deletePaymentCard,
  setDefaultPaymentCard
} from '../../../../store/index'
import { useDispatch } from 'react-redux'

const CreditCard = ({
  cardId,
  lastDigit,
  expMonth,
  expYear,
  cardBrand,
  isDefaultCard = false,
  pg
}) => {
  const dispatch = useDispatch()
  const handleCardDelete = () => {
    dispatch(
      deletePaymentCard({
        pmId: cardId,
        vendorId: pg.vendor_id
      })
    )
  }

  const setDefaultCard = () => {
    dispatch(
      setDefaultPaymentCard({
        user_id: +pg.user_id,
        method_id: cardId,
        vendor_id: +pg.vendor_id,
        app_type: 1
      })
    )
  }

  return (
    <div className={styles.card}>
      <div className={styles.cardLabel}>
        {isDefaultCard ? (
          <span className={styles.defaultLabel}>Default Card</span>
        ) : (
          <button
            type='button'
            onClick={setDefaultCard}
            className={styles.defaultBtn}
          >
            Make This Default
          </button>
        )}
      </div>
      {!isDefaultCard && (
        <div className={styles.cardDeleteBtn}>
          <button type='button' onClick={handleCardDelete}>
            <DeleteIcon width='10' height='10' />
          </button>
        </div>
      )}
      <div className={styles.cardNumber}>
        <span className={styles.cardDigit}>XXXX</span>
        <span className={styles.cardDigit}>XXXX</span>
        <span className={styles.cardDigit}>XXXX</span>
        <span className={styles.cardDigit}>{lastDigit}</span>
      </div>
      <div className={styles.expiryDate}>
        {expMonth} / {expYear}
      </div>
      <div className={clsx(styles.cardImage, styles[cardBrand])} />
    </div>
  )
}

const AddCard = ({ pg }) => {
  const handleAddCard = () => {
    window.location.assign(
      `${_env.getPaymentUrl()}/api/v1/auth/${UUILocalStorageService.getAccessToken()}/1/${
        pg.entity_id
      }/0/${pg.vendor_id}/1`
    )
  }
  return (
    <div
      className={clsx(styles.card, styles.cursonPointer)}
      onClick={handleAddCard}
    >
      <div className={styles.addCard}>
        <div>
          <AddIcon />
        </div>
        <div>Add New Card</div>
      </div>
    </div>
  )
}

const PaymentCards = ({ paymentDetails }) => {
  if (
    !paymentDetails ||
    Object.keys(paymentDetails).length === 0 ||
    ((!paymentDetails.cards || paymentDetails.cards.length === 0) &&
      (paymentDetails?.pg?.gateway.toLowerCase() !== 'stripe' ||
        paymentDetails?.pg?.save_card_enable !== 1))
  ) {
    // If there is no cards to render also add new card button is not active then don't render the component, because if we render, then user will just see the label, no add button and no card info will be there.
    return <React.Fragment />
  }
  return (
    <React.Fragment>
      <Divider className={styles.dividerColor} />
      <Grid container className={clsx(styles.sectionWrapper)}>
        <Grid item sm={12} md={4}>
          <CustomLabelComponent
            label='Card Details'
            headerType
            description=''
          />
        </Grid>
        <Grid item sm={12} md={8} lg={8} xl={6}>
          <Grid container className={styles.formFieldsWrapper}>
            <React.Fragment>
              {paymentDetails.cards &&
                paymentDetails.cards.map((card) => {
                  return (
                    <CreditCard
                      key={card.id}
                      cardId={card.id}
                      lastDigit={card.card.last4}
                      expMonth={card.card.exp_month}
                      expYear={card.card.exp_year}
                      cardBrand={card.card.brand}
                      isDefaultCard={!!+card.metadata.default_card} // first convert (+)string to number then (!!)number to bool
                      pg={paymentDetails.pg}
                    />
                  )
                })}
              {(!paymentDetails.cards || paymentDetails.cards.length < 6) &&
                paymentDetails.pg.gateway.toLowerCase() === 'stripe' &&
                paymentDetails.pg.save_card_enable === 1 && (
                  <AddCard pg={paymentDetails.pg} />
                )}
            </React.Fragment>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

export default PaymentCards
