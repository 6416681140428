import React from 'react'
import './Userpanel.scss'
import { CustomModal } from '../../../UIComponents/Modal'
import { Box } from '@material-ui/core'
import { UUIInput } from '../../../UIComponents/Input'

export default function AddPanelDialog({
  name,
  isOpen,
  closeHandler,
  submitHandler,
  customHandler,
  isError,
  isDisabledSubmitBtn,
  validation
}) {
  return (
    <CustomModal
      isDisabledSubmitBtn={isDisabledSubmitBtn}
      isOpen={isOpen}
      closeHandler={closeHandler}
      headerText={`Add ${name.toLowerCase()}`}
      cancelLabel='Cancel'
      submitLabel='Add'
      submitHandler={submitHandler}
      width='365px'
      hideCloseIcon={false}
      modalBodyStyle={{
        maxHeight: '600px',
        overflow: 'auto',
        margin: '0px'
      }}
      footerStyle={{
        justifyContent: 'end'
      }}
    >
      <Box
        component='div'
        display='flex'
        justifyContent='center'
        alignItems='center'
        flexDirection='column'
        padding='8px 20px'
      >
        <UUIInput
          label={`${name} name`}
          // secondaryLabel={_p.secondaryLabel}
          // fullWidth={_p.fullWidth}
          name='name'
          defaultValue=''
          customHandler={customHandler}
          placeholder=''
          validation={validation}
          isError={isError}
          errorMsg={`Please enter ${name.toLowerCase()} name.`}
          required
        />
      </Box>
    </CustomModal>
  )
}
