import { Box, OutlinedInput, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import { CustomLabelComponent } from '../Label'
import classes from './CustomInput.module.scss'
import { checkEmptyObject } from '../../utils/_helpers'
import Tooltip from '@material-ui/core/Tooltip'
export const UUIInput = (props) => {
  const {
    label = null,
    secondaryLabel = null,
    description = null,
    defaultValue = '',
    placeholder = null,
    required = false,
    fullWidth = false,
    isError = false,
    type = 'text',
    customHandler = () => {},
    readOnly = false,
    hasInfoIcon = false,
    headerType = false,
    name,
    id = '',
    customClassName = '',
    validation = {},
    errorMsg = '',
    tooltipText = '',
    multiline = false,
    multiRow = 4,
    disableText = ''
  } = props

  const [value, setValue] = useState(defaultValue)

  const inputOnChange = (e) => {
    const _value = validation.isNumber ? Number(e.target.value) : e.target.value
    if (String(_value).trim() === '') {
      setValue('')
    } else {
      if (!checkEmptyObject(validation) && validation.regexOnChange) {
        if (!validation.regexOnChange.test(_value)) return false

        if (
          validation.maxLength &&
          String(_value).length > validation.maxLength
        )
          return false
      }
      setValue(_value)
    }
  }
  const inputFocusOut = (e) => {
    let _isError = false
    let _errorMsg = ''
    const _value = validation.isNumber ? Number(e.target.value) : e.target.value
    if (required) {
      if (_value.trim() === '') {
        _isError = true
        _errorMsg = 'Value cannot be empty'
      } else {
        if (!checkEmptyObject(validation) && validation.regexOnBlur) {
          if (!validation.regexOnBlur.test(_value)) {
            _isError = true
            _errorMsg = 'Invalid input'
          }
        }
      }
    } else {
      if (_value.trim() !== '') {
        if (validation.regexOnBlur) {
          if (!validation.regexOnBlur.test(_value)) {
            _isError = true
            _errorMsg = 'Invalid input'
          }
        }
      }
    }

    if (typeof customHandler === 'function')
      customHandler(name, value, _isError, _errorMsg)
  }
  useEffect(() => {
    // update local state whenever root lvl state get changed
    setValue(defaultValue)
  }, [defaultValue])

  return (
    <Box>
      <CustomLabelComponent
        label={label}
        secondaryLabel={secondaryLabel}
        description={description}
        required={required}
        hasInfoIcon={hasInfoIcon}
        headerType={headerType}
        tooltipText={tooltipText}
      />
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Tooltip
          placement='top-start'
          disableHoverListener={!readOnly}
          disableFocusListener={!readOnly}
          disableTouchListener={!readOnly}
          title={disableText}
        >
          <OutlinedInput
            id={id}
            value={value}
            required={required}
            placeholder={placeholder}
            className={clsx(
              classes.inputStyle,
              classes.inputSelectorCls,
              classes.marginVal,
              customClassName,
              { [classes.fullWidth]: fullWidth },
              { [classes.inputBorder]: !isError },
              { [classes.errorBorder]: isError },
              { [classes.readOnly]: readOnly }
            )}
            // error={isError}
            type={type}
            onChange={inputOnChange}
            onBlur={inputFocusOut}
            // inputProps={{...inputProps}}
            readOnly={readOnly}
            name={name}
            multiline={multiline}
            rows={multiRow}
          />
        </Tooltip>

        {isError && (
          <Typography
            variant='body2'
            component='span'
            className={clsx(classes.errorTextCls, {
              [classes.fullWidth]: fullWidth
            })}
          >
            {errorMsg}
          </Typography>
        )}
      </div>
    </Box>
  )
}
