const UUILocalStorageService = (function () {
  let __service

  function __getService() {
    if (!__service) {
      __service = this
      return __service
    }
  }

  function _uuiAccessTokenVerify() {
    if (localStorage.getItem('uui_il_access_token')) {
      return localStorage.getItem('uui_il_access_token')
    } else {
      return false
    }
  }
  function _uuiAccessTokenCreate(token) {
    window.localStorage.removeItem('uui_il_access_token')
    localStorage.setItem('uui_il_access_token', token)
  }
  function _uuiAccessTokenRemove() {
    localStorage.removeItem('uui_il_access_token')
  }
  function _uuiRefreshTokenVerify() {
    if (localStorage.getItem('uui_il_refresh_token')) {
      return localStorage.getItem('uui_il_refresh_token')
    } else {
      return false
    }
  }
  function _uuiRefreshTokenCreate(token) {
    window.localStorage.removeItem('uui_il_refresh_token')
    localStorage.setItem('uui_il_refresh_token', token)
  }
  function _uuiRefreshTokenRemove() {
    localStorage.removeItem('uui_il_refresh_token')
  }
  function _uuiRemoveTokens() {
    localStorage.removeItem('uui_il_access_token')
    localStorage.removeItem('uui_il_refresh_token')
  }

  function _uuiAccessCompanyId() {
    if (localStorage.getItem('uui_il_company')) {
      return localStorage.getItem('uui_il_company')
    } else {
      return false
    }
  }

  function _uuiAccessRole() {
    if (localStorage.getItem('uui_il_role')) {
      return localStorage.getItem('uui_il_role')
    } else {
      return false
    }
  }

  function _uuiCompanyIdCreate(company) {
    window.localStorage.removeItem('uui_il_company')
    localStorage.setItem('uui_il_company', company)
  }

  function _uuiRoleCreate(role) {
    window.localStorage.removeItem('uui_il_role')
    localStorage.setItem('uui_il_role', role)
  }

  function _uuiAccessClientId() {
    if (localStorage.getItem('client_id')) {
      return localStorage.getItem('client_id')
    } else {
      return false
    }
  }

  return {
    getService: __getService,
    getAccessToken: _uuiAccessTokenVerify,
    setAccessToken: _uuiAccessTokenCreate,
    removeAccessToken: _uuiAccessTokenRemove,
    getRefreshToken: _uuiRefreshTokenVerify,
    setRefreshToken: _uuiRefreshTokenCreate,
    removeRefreshToken: _uuiRefreshTokenRemove,
    removeUUITokens: _uuiRemoveTokens,
    getCompanyId: _uuiAccessCompanyId,
    getRole: _uuiAccessRole,
    setCompanyId: _uuiCompanyIdCreate,
    setRole: _uuiRoleCreate,
    getClientId: _uuiAccessClientId
  }
})()

export { UUILocalStorageService }
