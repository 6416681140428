import React from 'react'
import { Box, Typography } from '@material-ui/core'
import clsx from 'clsx'
import styles from './CustomFields.module.scss'
import { CustomButton } from '../../../UIComponents/Button/CustomButton'
import AddIcon from '../../../utils/icons/AddIcon'
import AddCustomFieldModal from '../Components/AddCustomFieldModal'
import { useSelector, useDispatch } from 'react-redux'
import { toggleModal, resetEditModal } from '../../../store'

const PageHeader = () => {
  const dispatch = useDispatch()
  const { editLabel, isOpenModal } = useSelector((state) => state.customFields)
  const { isILadmin, companyName } = useSelector((state) => state.common)

  const handleAddCustomField = () => {
    dispatch(resetEditModal())
    dispatch(toggleModal(true))
  }

  return (
    <Box component='div' className={clsx(styles.pageHeader)}>
      <Box
        component='div'
        display='flex'
        justifyContent='space-between'
        alignItems='center'
      >
        <Box component='div' className={styles.headingWrapper}>
          <Typography
            variant='h3'
            className={clsx(styles.heading, styles.headingLarge)}
          >
            {isILadmin && companyName ? `${companyName} - ` : ''}
            Custom fields
          </Typography>
        </Box>
        <Box
          component='div'
          className={styles.buttonWrapper}
          display='flex'
          justifyContent='space-between'
          alignItems='center'
        >
          <Box
            component='div'
            className='pageHeader--button-single button-right'
          >
            <CustomButton
              buttonText='Add custom field'
              type='contained'
              iconComponent={<AddIcon width='9' height='9' color='white' />}
              onClickHandler={handleAddCustomField}
            />
          </Box>
        </Box>
      </Box>
      <Box
        component='div'
        display='flex'
        justifyContent='space-between'
        alignItems='center'
      />
      <Box component='div' className={styles.headingWrapper}>
        <Typography variant='body2' className={clsx(styles.description)}>
          Configure fields based on the information you require from your
          employees in their profile
        </Typography>
      </Box>
      <AddCustomFieldModal
        isOpenModal={isOpenModal}
        closeModalHandler={() => dispatch(toggleModal(false))}
        fetchDataOnSuccess
        isEditMode={editLabel.isEditMode}
        editId={editLabel.attribute_column}
        defaultValue={editLabel.field_name}
      />
    </Box>
  )
}

export default PageHeader
