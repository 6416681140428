import { Box, Grid, Typography, Divider } from '@material-ui/core'
import React, { useState } from 'react'
import { CustomLabelComponent } from '../../../UIComponents/Label'
import styles from './UserAddEdit.module.scss'
import clsx from 'clsx'
import { CustomButton } from '../../../UIComponents/Button/CustomButton'
import AddIcon from '../../../utils/icons/AddIcon'
import { UUIInput } from '../../../UIComponents/Input'

import { useSelector } from 'react-redux'

import AddCustomFieldModal from '../Components/AddCustomFieldModal'

export const CustomFields = ({
  inputState = {},
  onChangeHandler = () => {},
  handleCustomFieldsNavigation = () => {},
  isProfile = false
}) => {
  const customFields = useSelector(
    (state) => state.userAddEdit.renderFields.custom_attributes
  )
  const maxCustomAttributes = useSelector(
    (state) => state.userAddEdit.maxCustomAttributes
  )

  const [isOpenModal, setOpenModalFlag] = useState(false)

  const openCustomFieldModal = () => {
    setOpenModalFlag(true)
  }

  if (isProfile && customFields.length === 0) {
    return null
  }

  return (
    <React.Fragment>
      <Divider className={styles.dividerColor} />
      <Grid
        container
        className={clsx(styles.personal_info, styles.sectionWrapper)}
      >
        <Grid item sm={12} md={4}>
          <CustomLabelComponent
            label='Custom fields'
            headerType
            description={
              isProfile ? '' : `You can add up to ${maxCustomAttributes} fields`
            }
            hasInfoIcon={!isProfile}
            tooltipText='Use custom fields to collect additional information that help you tailor data for your business’ needs'
          />
          {/* {!isProfile && (
            <CustomButton
              buttonText='Manage custom fields'
              type='text'
              customClassName={styles.paddingTop}
              onClickHandler={handleCustomFieldsNavigation}
            />
          )} */}
        </Grid>
        <Grid item sm={12} md={8} lg={8} xl={6}>
          <Grid container className={styles.formFieldsWrapper}>
            {customFields.length === 0 && (
              <Box className={styles.emptyFieldContainer}>
                <Typography className={styles.emptyFieldDescription}>
                  Add custom fields to collect additional information that help
                  you tailor data for your business’ needs
                </Typography>
                {!isProfile && (
                  <Box className={styles.emptyFieldBtnContainer}>
                    <CustomButton
                      buttonText='Add custom field'
                      type='text'
                      iconComponent={
                        <AddIcon width='10' height='10' color='#EC5D25' />
                      }
                      customClassName={styles.paddingTop}
                      onClickHandler={openCustomFieldModal}
                    />
                  </Box>
                )}
              </Box>
            )}
            {customFields.length > 0 &&
              customFields.map((p, i) => {
                if (i >= maxCustomAttributes) {
                  return null
                }
                return (
                  <Grid key={i.toString()} item md={8} lg={8} xl={8}>
                    <UUIInput
                      label={p.field_name || `Custom ${p.attribute_column}`}
                      fullwidth={false}
                      defaultValue={inputState[`custom${p.attribute_column}`]}
                      name={`custom${p.attribute_column}`}
                      customHandler={onChangeHandler}
                      readOnly={isProfile}
                    />
                  </Grid>
                )
              })}
            {!isProfile &&
              customFields.length > 0 &&
              customFields.length < maxCustomAttributes && (
                <Grid item md={8} lg={8} xl={8}>
                  <CustomButton
                    buttonText='Add custom field'
                    type='text'
                    iconComponent={
                      <AddIcon width='10' height='10' color='#EC5D25' />
                    }
                    customClassName={styles.paddingTop}
                    onClickHandler={openCustomFieldModal}
                  />
                </Grid>
              )}
          </Grid>
        </Grid>
      </Grid>

      <AddCustomFieldModal
        isOpenModal={isOpenModal}
        closeModalHandler={() => setOpenModalFlag(false)}
      />
    </React.Fragment>
  )
}
