import React, { useEffect } from 'react'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import { Typography } from '@material-ui/core'
import clsx from 'clsx'
import classes from './CustomRadioComponent.module.scss'

export const CustomRadioComponent = (props) => {
  const {
    value = '',
    customHandler = () => {},
    list = [],
    disabled = false,
    className = ''
  } = props
  const [radioValue, setRadioValue] = React.useState(value)

  const handleChange = (event) => {
    setRadioValue(event.target.value)
    customHandler(event.target.value)
  }

  useEffect(() => {
    setRadioValue(value)
  }, [value])

  return (
    <FormControl component='fieldset'>
      <RadioGroup
        value={radioValue}
        onChange={handleChange}
        className={clsx(classes.customRadio, className)}
      >
        {list &&
          list.length > 0 &&
          list.map((_c, _i) => {
            return (
              <FormControlLabel
                key={_i}
                value={_c.name}
                disabled={disabled}
                control={
                  <Radio
                    className={clsx(classes.customRadioEach)}
                    size='small'
                  />
                }
                label={
                  <React.Fragment>
                    <Typography className={clsx(classes.radioLbl)}>
                      {_c.label}
                    </Typography>
                    <Typography className={clsx(classes.radioSubLbl)}>
                      {_c.description}
                    </Typography>
                  </React.Fragment>
                }
              />
            )
          })}
      </RadioGroup>
    </FormControl>
  )
}
