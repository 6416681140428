import React, { useState, useEffect } from 'react'
import { Box } from '@material-ui/core'
import styles from './index.module.scss'
import { CustomModal } from '../../../../UIComponents/Modal'
import { UUIInput } from '../../../../UIComponents/Input'
import { useDispatch } from 'react-redux'
import {
  addCustomFieldLabel,
  fetchCustomFields,
  editCustomFieldLabel
} from '../../../../store/index'

const AddCustomFieldModal = ({
  isOpenModal,
  closeModalHandler = () => {},
  fetchDataOnSuccess = false,
  isEditMode = false,
  editId = null,
  defaultValue = ''
}) => {
  const [inputValue, setCustomLabelValue] = useState('')
  const [inputValueErr, setCustomLabelErr] = useState('')
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    setCustomLabelValue(isEditMode && editId ? defaultValue : '')
  }, [isEditMode, editId, defaultValue])

  const addCustomFieldsLabel = (_name, _value) => {
    setCustomLabelValue(_value)
  }

  const addCustomFields = (e) => {
    if (!inputValue) {
      setCustomLabelErr('Please enter field label.')
      return
    }

    if (defaultValue && inputValue === defaultValue) {
      setCustomLabelErr('Please enter new label value.')
      return
    }
    setLoading(true)
    if (isEditMode && editId) {
      dispatch(
        editCustomFieldLabel({
          field_name: inputValue,
          attribute_column: editId
        })
      )
        .then((res) => {
          setCustomLabelValue('')
          setCustomLabelErr('')
          closeModalHandler()
        })
        .finally(() => {
          setLoading(false)
        })
    } else {
      dispatch(
        addCustomFieldLabel({
          label: inputValue
        })
      )
        .then((res) => {
          if (fetchDataOnSuccess) {
            dispatch(fetchCustomFields())
          }
          setCustomLabelValue('')
          setCustomLabelErr('')
          closeModalHandler()
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }

  const closeHandler = () => {
    if (!isEditMode) {
      setCustomLabelValue('')
    }
    setCustomLabelErr('')
    closeModalHandler()
  }
  return (
    <CustomModal
      isOpen={isOpenModal}
      closeHandler={closeHandler}
      headerText='Add custom field'
      cancelLabel='Cancel'
      submitLabel='Save'
      submitHandler={addCustomFields}
      width='365px'
      isDisabledSubmitBtn={loading}
      modalBodyStyle={{
        maxHeight: '600px',
        overflow: 'auto',
        margin: '0px'
      }}
      footerStyle={{
        justifyContent: 'end'
      }}
    >
      <Box
        component='div'
        display='flex'
        justifyContent='center'
        alignItems='center'
        flexDirection='column'
        padding='8px 20px'
      >
        <UUIInput
          label='Field label'
          fullwidth={false}
          customClassName={styles.inputWidth}
          customHandler={addCustomFieldsLabel}
          required
          errorMsg={inputValueErr}
          isError={!!inputValueErr}
          defaultValue={inputValue}
        />
      </Box>
    </CustomModal>
  )
}

export default AddCustomFieldModal
