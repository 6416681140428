import { Select, MenuItem, Typography, FormControl } from '@material-ui/core'
import { ExpandMoreIcon } from '../../utils/icons/ExpandMoreIcon'
import React from 'react'
import clsx from 'clsx'
import classes from './CustomSelect.module.scss'
import { CustomLabelComponent } from '../Label/index.js'
import Tooltip from '@material-ui/core/Tooltip'

export const CustomSelect = ({
  label,
  description,
  required,
  list = [],
  valueKey = '',
  value = '',
  fullWidth = false,
  customSelectHandler = () => {},
  menuItemTitle = '',
  hasInfoIcon = false,
  headerType = false,
  name = '',
  isError = false,
  errorMsg = '',
  readOnly = false,
  tooltipText = '',
  id = '',
  disableText
}) => {
  const handleChange = (event) => {
    customSelectHandler(event.target.name, event.target.value)
  }

  return (
    <React.Fragment>
      <CustomLabelComponent
        label={label}
        description={description}
        required={required}
        hasInfoIcon={hasInfoIcon}
        headerType={headerType}
        tooltipText={tooltipText}
      />
      <Tooltip
        placement='top-start'
        disableHoverListener={!readOnly}
        disableFocusListener={!readOnly}
        disableTouchListener={!readOnly}
        title={disableText}
      >
        <FormControl disabled={readOnly}>
          <Select
            id={id}
            displayEmpty
            name={name}
            value={value}
            onChange={handleChange}
            IconComponent={(_props) => (
              <div className={classes.pr_icon} {..._props}>
                <ExpandMoreIcon width='10' height='10' />
              </div>
            )}
            error={isError}
            renderValue={() => {
              return value === '' ? menuItemTitle : value
            }}
            className={clsx(
              classes.customSelect,
              classes.customSelectSelector,
              classes.marginVal,
              classes.customIcon,
              { [classes.menuTitleColor]: value === '' },
              { [classes.readOnly]: readOnly }
            )}
            variant='outlined'
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left'
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left'
              },
              getContentAnchorEl: null,
              disableScrollLock: true
            }}
            fullWidth={fullWidth}
          >
            <MenuItem
              value=''
              className={clsx(classes.backgroundColor, classes.popOverText)}
            >
              {menuItemTitle}
            </MenuItem>
            {list.map((_c, _i) => {
              return (
                <MenuItem
                  key={_i}
                  id={_c.id}
                  value={_c[valueKey]}
                  className={clsx(classes.backgroundColor, classes.popOverText)}
                >
                  <Typography variant='inherit'>{_c[valueKey]}</Typography>
                </MenuItem>
              )
            })}
          </Select>
        </FormControl>
      </Tooltip>

      {isError && (
        <Typography
          variant='body2'
          component='div'
          className={classes.errorTextCls}
        >
          {errorMsg}
        </Typography>
      )}
    </React.Fragment>
  )
}
