const IUILocalStorageService = (function () {
  let __service

  function __getService() {
    if (!__service) {
      __service = this
      return __service
    }
  }

  function _iuiAccessTokenVerify() {
    if (localStorage.getItem('uui_il_access_token')) {
      return localStorage.getItem('uui_il_access_token')
    } else {
      return false
    }
  }
  function _iuiAccessTokenCreate(token) {
    window.localStorage.removeItem('uui_il_access_token')
    localStorage.setItem('uui_il_access_token', token)
  }
  function _iuiAccessTokenRemove() {
    localStorage.removeItem('uui_il_access_token')
  }
  function _iuiRefreshTokenVerify() {
    if (localStorage.getItem('uui_il_refresh_token')) {
      return localStorage.getItem('uui_il_refresh_token')
    } else {
      return false
    }
  }
  function _iuiRefreshTokenCreate(token) {
    window.localStorage.removeItem('uui_il_refresh_token')
    localStorage.setItem('uui_il_refresh_token', token)
  }
  function _iuiRefreshTokenRemove() {
    localStorage.removeItem('uui_il_refresh_token')
  }
  function _iuiRemoveTokens() {
    localStorage.removeItem('uui_il_access_token')
    localStorage.removeItem('uui_il_refresh_token')
  }

  function _iuiAccessCompanyId() {
    if (localStorage.getItem('uui_il_company')) {
      return localStorage.getItem('uui_il_company')
    } else {
      return false
    }
  }

  function _iuiAccessRole() {
    if (localStorage.getItem('uui_il_role')) {
      return localStorage.getItem('uui_il_role')
    } else {
      return false
    }
  }

  function _iuiCompanyIdCreate(company) {
    window.localStorage.removeItem('uui_il_company')
    localStorage.setItem('uui_il_company', company)
  }

  function _iuiRoleCreate(role) {
    window.localStorage.removeItem('uui_il_role')
    localStorage.setItem('uui_il_role', role)
  }

  function _iuiAccessClientId() {
    if (localStorage.getItem('client_id')) {
      return localStorage.getItem('client_id')
    } else {
      return false
    }
  }
  function _iuiClientIdCreate(client) {
    window.localStorage.removeItem('client_id')
    localStorage.setItem('client_id', client)
  }

  return {
    getService: __getService,
    getAccessToken: _iuiAccessTokenVerify,
    setAccessToken: _iuiAccessTokenCreate,
    removeAccessToken: _iuiAccessTokenRemove,
    getRefreshToken: _iuiRefreshTokenVerify,
    setRefreshToken: _iuiRefreshTokenCreate,
    removeRefreshToken: _iuiRefreshTokenRemove,
    removeUUITokens: _iuiRemoveTokens,
    getCompanyId: _iuiAccessCompanyId,
    getRole: _iuiAccessRole,
    setCompanyId: _iuiCompanyIdCreate,
    setRole: _iuiRoleCreate,
    getClientId: _iuiAccessClientId,
    setClientId: _iuiClientIdCreate
  }
})()

export default IUILocalStorageService
