import React from 'react'
import Skeleton from '@material-ui/lab/Skeleton'
import styles from './CustomFields.module.scss'
import { Box } from '@material-ui/core'
export default function SkeletonLoader() {
  return (
    <Box className={styles.tablewrapper} style={{ margin: 20 }}>
      <Skeleton
        variant='rect'
        height={120}
        style={{ marginTop: 40, marginBottom: 20 }}
      />
      <Skeleton variant='rect' height={50} style={{ marginBottom: 20 }} />
      <Skeleton variant='rect' height={500} style={{ marginBottom: 20 }} />
    </Box>
  )
}
