/* eslint-disable prefer-promise-reject-errors */
import {
  GENERATE_TEMPLATE,
  SHOW_LOADER,
  STOP_LOADER,
  SET_IMPORT_USER_INFO,
  SET_BULK_ACTION_FIELDS
} from './types'

import api from '../../utils/Api'
import { userBulkImport } from '../../utils/Urls'
import axios from 'axios'

export const showLoader = () => {
  return {
    type: SHOW_LOADER
  }
}

export const stopLoader = () => {
  return {
    type: STOP_LOADER
  }
}

export const setGeneratedTemplate = (payload) => {
  return {
    type: GENERATE_TEMPLATE,
    payload
  }
}

export const setExecutedResults = (payload) => {
  return {
    type: SET_IMPORT_USER_INFO,
    payload
  }
}

export const setBulkActionHistoryFields = (payload) => {
  return {
    type: SET_BULK_ACTION_FIELDS,
    payload
  }
}

export const generateTemplate = (payload) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      api
        .get(`${userBulkImport.generateTemplate()}`)
        .then((res) => {
          // dispatch(setGeneratedTemplate(res.data))
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}

export const uploadBulkUsersFile = (payload) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      api
        .get(
          `${userBulkImport.uploadUrl()}?action=upload_bulk_users&item_type=bulk_users&file_name=${
            payload.fileName
          }`
        )
        .then((res) => {
          const resolveResponse = {
            file_type: 'bulk_users',
            file_path: res.data.presigned_url.fields.key,
            user_file_name: res.data.presigned_url.file_name,
            upload: 'new'
          }
          uploadFileToS3(res.data.presigned_url, payload.file)
            .then((res) => {
              resolve(resolveResponse)
            })
            .catch((err) => {
              reject(err)
            })
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}

// here preSignedPostData is the data returned from our backend upload doc API
const uploadFileToS3 = (presignedPostData, file) => {
  return new Promise((resolve, reject) => {
    // create a form obj
    const formData = new FormData()

    // append the fields in presignedPostData in formData
    Object.keys(presignedPostData.fields).forEach((key) => {
      formData.append(key, presignedPostData.fields[key])
    })

    // append the file
    formData.append('file', file)

    // post the data on the s3 url
    axios
      .post(presignedPostData.url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(function (response) {
        resolve(response)
      })
      .catch(function (error) {
        reject(error)
      })
  })
}

export const validateUploadedFile = (fileName) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      api
        .post(`${userBulkImport.validateUploadedFile()}?file_name=${fileName}`)
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}

export const executionJob = (jobId, type) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      api
        .get(`${userBulkImport.executionResult()}?job_id=${jobId}`)
        .then((res) => {
          if (!res.data[type]) {
            setTimeout(() => {
              dispatch(executionJob(jobId, type)).then(
                (res) => res.data[type] && resolve(res)
              )
            }, 10000)
          } else {
            dispatch(setExecutedResults(res))
            clearTimeout()
            resolve(res)
          }
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}

export const downloadExecutionJob = (auditId, type) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      api
        .get(`${userBulkImport.executionResult()}?audit_id=${auditId}`)
        .then((res) => {
          if (!res.data[type]) {
            setTimeout(() => {
              dispatch(downloadExecutionJob(auditId, type)).then(
                (res) => res.data[type] && resolve(res)
              )
            }, 10000)
          } else {
            // dispatch(setExecutedResults(res))
            clearTimeout()
            resolve(res)
          }
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}

export const downloadUploadedRecords = (type, jobId) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      api
        .get(
          `${userBulkImport.downloadUploadedRecords()}?status=${type}&job_id=${jobId}`
        )
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}

export const importUploadedRecords = (jobId, canUpdateExistingUsers) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      api
        .post(
          `${userBulkImport.importRecords()}?job_id=${jobId}&update=${canUpdateExistingUsers}`
        )
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}

export const fetchBulkactionHistory = (url) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      api
        .get(url)
        .then((res) => {
          if (res.status === 204) {
            dispatch(
              setBulkActionHistoryFields({
                count: 0,
                next: null,
                previous: null,
                results: []
              })
            )
            dispatch(stopLoader())
            resolve()
          }
          if (res.status === 200) {
            dispatch(setBulkActionHistoryFields(res.data))
            dispatch(stopLoader())
            resolve()
          }
        })
        .catch((err) => {
          dispatch(stopLoader())
          reject(err)
        })
    })
  }
}
