import api from '../../utils/Api'
import { userAddEditUrl } from '../../utils/Urls'

export const commonLoadOptions = async (
  search,
  loadedOptions,
  { page, tabelName, placeholder } // additional parameters
) => {
  try {
    var url =
      userAddEditUrl.dropdownList() +
      `?page=${page}&table_name=${tabelName}&search=${search}`
    const response = await api.get(url).then((res) => res.data)
    const mappedValue = response.results.map((item) => {
      return {
        value: item.id,
        label: item.name
      }
    })

    return {
      options: mappedValue,
      hasMore: !!response.next,
      additional: {
        page: page + 1,
        tabelName
      }
    }
  } catch (error) {
    return {
      options: [],
      hasMore: false,
      additional: {
        page: 1,
        tabelName
      }
    }
  }
}

export const currencyLoadOptions = async (
  search,
  loadedOptions,
  { page, placeholder }
) => {
  try {
    const response = await api
      .get(`${userAddEditUrl.currencyCode()}&search=${search}&page=${page}`)
      .then((res) => res.data)
    const mappedValue = response.results.map((item) => {
      return {
        value: item.currency_code,
        label: item.currency_code
      }
    })
    return {
      options: mappedValue,
      hasMore: !!response.next,
      additional: {
        page: page + 1
      }
    }
  } catch (error) {
    return {
      options: [],
      hasMore: false,
      additional: {
        page: 1
      }
    }
  }
}

export const approversOptions = async (
  search,
  loadedOptions,
  { page, product, userId, placeholder }
) => {
  try {
    const response = await api
      .get(
        `${userAddEditUrl.approversSearch()}?page=${page}&product=${product}&search=${search}&user_id=${userId}`
      )
      .then((res) => res.data)

    const mappedValue = response.results.map((item) => {
      return {
        value: item.approver,
        label: item.approver
      }
    })

    const placeholderLbl = { value: '', label: placeholder }
    mappedValue.unshift(placeholderLbl)

    return {
      options: mappedValue,
      hasMore: !!response.next,
      additional: {
        page: page + 1,
        product,
        userId
      }
    }
  } catch (error) {
    return {
      options: [],
      hasMore: false,
      additional: {
        page: 1,
        product,
        userId
      }
    }
  }
}

export const countryOptions = async (
  search,
  loadedOptions,
  { page, tabelName, placeholder }
) => {
  try {
    const response = await api
      .get(
        `${userAddEditUrl.countryPaginated()}&search=${search}&page=${page}&info=${tabelName}`
      )
      .then((res) => res.data)

    const mappedValue = response.results.map((item) => {
      return {
        value: item[tabelName],
        label: item[tabelName]
      }
    })

    // const placeholderLbl = { value: '', label: placeholder }
    // mappedValue.unshift(placeholderLbl)

    return {
      options: mappedValue,
      hasMore: !!response.next,
      additional: {
        page: page + 1,
        tabelName
      }
    }
  } catch (error) {
    return {
      options: [],
      hasMore: false,
      additional: {
        page: 1,
        tabelName
      }
    }
  }
}
