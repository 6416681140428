function findObjectInArray(_list, _keySearchable, _keyValue) {
  let _index = null
  let _found = false
  for (let i = 0; i < _list.count; i++) {
    if (_list[i][_keySearchable] === _keyValue) {
      _index = i
      _found = true
      break
    }
  }
  return {
    found: _found,
    index: _index
  }
}
function checkEmptyObject(obj) {
  return Object.keys(obj).length === 0 && obj.constructor === Object
}
const capitalize = (s) => s && s[0].toUpperCase() + s.slice(1)

export { findObjectInArray, checkEmptyObject, capitalize }
