import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import { Box, Divider, TableSortLabel, Typography } from '@material-ui/core'
import clsx from 'clsx'
import styles from './CustomTable.module.scss'
import { Searchbar } from '../Search'
import { FilterIcon } from '../../utils/icons/FilterIcon'
import { PrimaryCheckbox } from '../CheckboxButton/PrimaryCheckBox'
import Pagination from '@material-ui/lab/Pagination'

const useStyles = makeStyles({
  root: {
    width: '100%',
    boxShadow: 'none'
  },
  container: {
    maxHeight: 'calc(100vh - 300px)'
  }
})

export const CustomDataTable = (props) => {
  const {
    hasFilter = false,
    filterComponent,
    searchPlaceholder = '',
    isCheckboxTable = true,
    columns = [],
    rows = [],
    rowsPerPage,
    page,
    handleChangePage = () => {},
    handleChangeRowsPerPage = () => {},
    count,
    handleSelectAllClick = () => {},
    selected,
    handleSingleCheckBoxClick = () => {},
    orderBy,
    order,
    onRequestSort = () => {},
    onSearchHandler = () => {},
    searchHide = true,
    hideFilter = true,
    handleRowClick = () => {},
    isRowClickable = false,
    disableHeaderCheckbox = false,
    disableRowCheckbox
    // isDataFetching = false
  } = props
  const classes = useStyles()
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property)
  }

  const createSearchHandler = (event) => {
    onSearchHandler(event.target.value)
  }

  const TablePaginationActions = () => {
    return (
      <Box
        sx={{
          flexShrink: 0,
          ml: 2.5
        }}
      >
        <Pagination
          count={Math.ceil(count / rowsPerPage)}
          page={page}
          variant='outlined'
          shape='rounded'
          onChange={handleChangePage}
        />
      </Box>
    )
  }

  return (
    <Paper className={clsx(classes.root, styles.tableContainer)}>
      {hasFilter && (
        <React.Fragment>
          <Box
            component='div'
            display='flex'
            alignItems='center'
            justifyContent='space-between'
            className={styles.filterBar}
          >
            {!hideFilter && (
              <Box
                component='div'
                display='flex'
                className={clsx(styles.gap, styles.large)}
              >
                <Box
                  component='div'
                  display='flex'
                  alignItems='center'
                  justifyContent='space-between'
                  className={clsx(styles.gap, styles.small)}
                >
                  <FilterIcon width='17' height='17' />
                  <Typography
                    variant='body1'
                    className={clsx(styles.description, styles.semiBoldtext)}
                  >
                    Filters
                  </Typography>
                </Box>
                {filterComponent}
              </Box>
            )}

            {!searchHide && (
              <Box component='div'>
                <Searchbar
                  placeholder={searchPlaceholder}
                  onChangeHandler={(e) => createSearchHandler(e)}
                />
              </Box>
            )}
          </Box>
          <Divider className={styles.divider} />
        </React.Fragment>
      )}
      <TableContainer className={classes.container}>
        <Table
          stickyHeader
          aria-label='sticky table'
          style={{ height: 'auto !important' }}
        >
          <TableHead>
            <TableRow>
              {isCheckboxTable && (
                <TableCell padding='checkbox' style={{ minWidth: '12px' }}>
                  <PrimaryCheckbox
                    checkboxFlag={
                      rows.length > 0 &&
                      selected &&
                      selected[page] &&
                      selected[page].length ===
                        rows.length - disableRowCheckbox.length
                    }
                    disabled={disableHeaderCheckbox}
                    handleCheckBoxHandler={(event) =>
                      handleSelectAllClick(event, page)
                    }
                  />
                </TableCell>
              )}

              {columns.map((column, index) => (
                <TableCell
                  key={index}
                  align={column.align}
                  style={{
                    ...column.style,
                    minWidth: column.minWidth,
                    width: column.width,
                    maxWidth: column.minWidth
                  }}
                  className={clsx(column.style)}
                >
                  {column.sortable ? (
                    <TableSortLabel
                      direction={orderBy === column.id ? order : 'asc'}
                      onClick={createSortHandler(column.id)}
                    >
                      <Typography
                        variant='body1'
                        className={styles.columnHeader}
                      >
                        {column.label}
                      </Typography>
                      {/* {orderBy === column.id ? (
                        <span className={classes.visuallyHidden}>
                          {order === 'desc'
                            ? 'sorted descending'
                            : 'sorted ascending'}
                        </span>
                      ) : null} */}
                    </TableSortLabel>
                  ) : (
                    <Typography variant='body1' className={styles.columnHeader}>
                      {column.label}
                    </Typography>
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {/* className={clsx({ [styles.bodyHeight]: isDataFetching })} */}

            {count === 0 ? (
              <TableRow>
                <TableCell align='center' colSpan={6}>
                  <Box className={styles.emptyData}>No data available</Box>
                </TableCell>
              </TableRow>
            ) : (
              rows.map((row, index) => {
                // const isItemSelected = isSelected(row.id.toString())
                return (
                  <TableRow
                    hover
                    role='checkbox'
                    tabIndex={-1}
                    key={index}
                    onClick={(e) => handleRowClick(e, row.id)}
                    style={{ cursor: isRowClickable ? 'pointer' : 'default' }}
                  >
                    {isCheckboxTable && (
                      <TableCell
                        padding='checkbox'
                        style={{ minWidth: '12px' }}
                      >
                        <PrimaryCheckbox
                          handleCheckBoxHandler={(e) =>
                            handleSingleCheckBoxClick(
                              e,
                              row.id.toString(),
                              page
                            )
                          }
                          checkboxFlag={
                            selected && selected[page]
                              ? selected[page].indexOf(row.id.toString()) !== -1
                              : false
                          }
                          id={row.id.toString()}
                          disabled={
                            disableRowCheckbox
                              ? disableRowCheckbox.indexOf(
                                  row.id.toString()
                                ) !== -1
                              : false
                          }
                        />
                      </TableCell>
                    )}
                    {columns.map((column, index) => (
                      <TableCell
                        key={index}
                        align={column.align}
                        style={{
                          minWidth: column.minWidth,
                          width: column.width,
                          maxWidth: column.minWidth
                        }}
                      >
                        {column.cell(row)}
                      </TableCell>
                    ))}
                  </TableRow>
                )
              })
            )}
            {/* {isDataFetching && (
              <Grid container className={styles.progressContainer}>
                <Grid item lg='12' md='12' xl='12'>
                  <Box
                    display='flex'
                    justifyContent='center'
                    alignItems='center'
                    position='relative'
                  >
                    <Typography>Processing...</Typography>
                  </Box>
                </Grid>
              </Grid>
            )} */}
            {/* {count === 0 && !isDataFetching && (
              <Grid container className={styles.progressContainer}>
                <Grid item lg='12' md='12' xl='12'>
                  <Box
                    display='flex'
                    justifyContent='center'
                    alignItems='center'
                    position='relative'
                  >
                    <Typography>No reuslts found</Typography>
                  </Box>
                </Grid>
              </Grid>
            )} */}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 100]}
        component='div'
        count={count}
        rowsPerPage={rowsPerPage}
        page={+page - 1}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        className={classes.pageRoot}
        ActionsComponent={TablePaginationActions}
      />
    </Paper>
  )
}
