import {
  Box,
  Divider,
  Grid,
  Typography,
  Backdrop,
  CircularProgress
} from '@material-ui/core'
import React, { useState, useEffect, Fragment } from 'react'
import { BreadCrumb } from '../../../UIComponents/Breadcrumb/BreadCrumb'
import { CustomButton } from '../../../UIComponents/Button/CustomButton'
import styles from './UserAddEdit.module.scss'
import clsx from 'clsx'
import { travelConstants, expenseConstants } from './constants'

import { GetInputType } from './helperFuntions'
import { TravelExpenseFields } from './TravelExpenseFields'
import { CustomLabelComponent } from '../../../UIComponents/Label'
import { CustomFields } from './CustomFields'

import { useDispatch, useSelector } from 'react-redux'
import withStore from '../../../store/withStore'
import {
  fetchRenderFileds,
  addTravelApproverField,
  addExpenseApproverField,
  fetchCountryCodes,
  createUser,
  verifyAccessToken,
  uploadSingleDocumnet,
  showLoader,
  stopLoader,
  fetchUserDetails,
  updateUser,
  setStaticFieldsVisibility,
  downloadSingleDocumnet,
  setPaymentDetails
} from '../../../store/index'
import { AdditionalInfo } from './AdditionalInfo'
import { useHistory } from 'react-router-dom'
import UUIToastService from '../../../utils/ToastService'
import _env from '../../../../env'
import VerificationError from '../Components/VerificationError'
import { ErrorMessages } from './ErrorMessages'

const UserAddEdit = (props) => {
  const { isEditMode, editUserId, config, isProfile = false } = props
  _env.init(config, isProfile)

  const {
    loading: isPageLoading,
    renderFields,
    dropdownList,
    dateFormat,
    countryCode,
    defaultCurrency,
    isTravelApproverLabelUpadted,
    isExpenseApproverLabelUpadted,
    optionalFields,
    fieldsVisibility,
    isRendered,
    maxTravelApprovers,
    maxExpenseApprovers,
    maxMembershipInput
  } = useSelector((state) => state.userAddEdit)
  const { isTokenVerified, id, hasVerificationError, isILadmin, companyName } =
    useSelector((state) => state.common)

  const history = useHistory()

  const [productAccess, setProductAccess] = useState({
    travel: false,
    expense: false
  })

  const addProductAccessSelected = (e) => {
    var value = e.target.value
    errorArray.splice(errorArray.indexOf('products'), 1)
    errorMsgs.products = ''
    const _productAccess = { ...productAccess }
    _productAccess[value] = !_productAccess[value]
    if (_productAccess.travel === false && _productAccess.expense === false) {
      const _errorArray = [...errorArray]
      const _errorMsgs = { ...errorMsgs }
      if (_errorArray.indexOf('products') === -1) {
        _errorArray.push('products')
      }
      _errorMsgs.products = ErrorMessages.products
      setErrorArray(_errorArray)
      setErrorMsgs(_errorMsgs)
      const el = document.getElementById('products')
      if (el) {
        el.scrollIntoView({ behavior: 'smooth', block: 'center' })
      }
    } else {
      setErrorArray(errorArray)
      setErrorMsgs(errorMsgs)
    }
    setProductAccess(_productAccess)
  }

  // Form state
  const [personalInfoInput, setPersonalInfoInput] = useState({
    first_name: '',
    last_name: '',
    email: '',
    contact_number: '',
    dob: '',
    gender: '',
    country_code: countryCode,
    contact_country_code: ''
  })

  const [employeeInfoInput, setEmployeeInfoInput] = useState({
    emp_level: { value: '', label: 'Select employee level' },
    employee_id: '',
    entity: { value: '', label: 'Select entity' },
    business_unit: { value: '', label: 'Select unit' },
    department: { value: '', label: 'Select department' },
    designation: '',
    base_location: '',
    currency: { value: defaultCurrency, label: defaultCurrency }
  })

  const [userTagType, setUserTagType] = useState({})

  const [scroll, setScroll] = useState(false)

  const [customFieldsInput, setCustomFieldsInput] = useState({})

  const [travelRole, setTravelRole] = useState('traveler')
  const [travelApproversInput, setTravelApproversInput] = useState({})
  const [travelFYI, setTravelFYI] = useState({
    value: '',
    label: 'Select approver'
  })
  const [membershipInfoInput, setMembershipInfoInput] = useState([])

  const [travelPreferenceInfo, settravelPreferenceInfo] = useState({
    flight_seat_preference: '',
    meal_preference: ''
  })

  const [secureTravelInputInfo, setSecureTravelInputInfo] = useState({
    trusted_traveller: '',
    redress_number: ''
  })

  const [passportInfoInput, setPassportInfoInput] = useState({
    passport_issue_country: { value: '', label: 'Select country' },
    passport_number: '',
    nationality: { value: '', label: 'Select nationality' },
    passport_issue_date: '',
    passport_expiry_date: '',
    passport_photo: [],
    travel_docs: []
  })

  const [filesForDelete, setFilesForDelete] = useState([])

  const [isAdditionalInfoExpanded, setAdditionalInfoFlag] = useState(
    config.env.isTravelProduct && isProfile ? 'open' : '' // Additional info should be expanded by default if page is profile and product is travel
  )

  const [expenseRole, setExpenseRole] = useState('submitter')
  const [expenseApproversInput, setExpenseApproversInput] = useState({})
  const [expenseFYI, setExpenseFYI] = useState({
    value: '',
    label: 'Select approver'
  })

  const [errorArray, setErrorArray] = useState([])
  const [errorMsgs, setErrorMsgs] = useState({
    first_name: '',
    last_name: '',
    email: '',
    contact_number: '',
    dob: '',
    gender: '',
    emp_level: '',
    employee_id: '',
    entity: '',
    business_unit: '',
    department: '',
    designation: '',
    base_location: '',
    currency: '',
    flight_seat_preference: '',
    meal_preference: '',
    trusted_traveller: '',
    redress_number: '',
    passport_issue_country: '',
    passport_number: '',
    nationality: '',
    passport_issue_date: '',
    passport_expiry_date: '',
    passport_photo: '',
    travel_docs: '',
    products: '',
    userTagType: ''
  })

  const [inputChangeLog, updateInputChangeLog] = useState({
    first_name: false,
    last_name: false,
    email: false,
    contact_number: false,
    dob: false,
    gender: false,
    emp_level: false,
    employee_id: false,
    entity: false,
    business_unit: false,
    department: false,
    designation: false,
    base_location: false,
    currency: false,
    flight_seat_preference: false,
    meal_preference: false,
    trusted_traveller: false,
    redress_number: false,
    passport_issue_country: false,
    passport_number: false,
    nationality: false,
    passport_issue_date: false,
    passport_expiry_date: false,
    userTagType: false
  })

  const [uploadedFiles, setUploadedFiles] = useState([])

  const onPersonalInfoChange = (
    _name,
    _value,
    _isError,
    _errorMsg,
    _obj = {}
  ) => {
    if (_name === 'contact_number') {
      setPersonalInfoInput({
        ...personalInfoInput,
        contact_number: _value.trim(),
        country_code: _obj.code,
        contact_country_code: _obj.contact_country_code
      })
    } else {
      setPersonalInfoInput({
        ...personalInfoInput,
        [_name]: _value
      })
    }
    updateError(_name, _isError, _errorMsg)
    if (Object.hasOwnProperty.call(inputChangeLog, _name)) {
      updateInputChangeLog({
        ...inputChangeLog,
        [_name]: true
      })
    }
  }

  const onEmployeeInfoChange = (_name, _value, _isError, _errorMsg, _obj) => {
    setEmployeeInfoInput({
      ...employeeInfoInput,
      [_name]: _value
    })
    updateError(_name, _isError, _errorMsg)
    if (Object.hasOwnProperty.call(inputChangeLog, _name)) {
      updateInputChangeLog({
        ...inputChangeLog,
        [_name]: true
      })
    }
  }

  const getUserTagTypeId = (name) => {
    const tag = renderFields.userTagType.find((tag) => tag.name === name)
    return tag.id
  }

  const onUserTagTypeChange = (e) => {
    setUserTagType({
      ...userTagType,
      [e.target.name]: userTagType[e.target.name]
        ? false
        : getUserTagTypeId(e.target.name)
    })
    if (Object.hasOwnProperty.call(inputChangeLog, 'userTagType')) {
      updateInputChangeLog({
        ...inputChangeLog,
        userTagType: true
      })
    }
  }

  const onCustomFieldsChange = (_name, _value) => {
    setCustomFieldsInput({
      ...customFieldsInput,
      [_name]: _value
    })
  }

  const onTravelApproversInputChange = (_name, _value) => {
    setTravelApproversInput({
      ...travelApproversInput,
      [_name]: _value
    })
  }

  const onExpenseApproversInputChange = (_name, _value) => {
    setExpenseApproversInput({
      ...expenseApproversInput,
      [_name]: _value
    })
  }

  const onMemInfoInputChange = (index, key, val) => {
    const _membershipInfoInput = [...membershipInfoInput]
    _membershipInfoInput[index][key] = val
    _membershipInfoInput[index].isEditMode = isEditMode
    setMembershipInfoInput(_membershipInfoInput)
  }

  const addMemInfoInput = (membershipType) => {
    if (membershipInfoInput.length >= maxMembershipInput) return
    setMembershipInfoInput([
      ...membershipInfoInput,
      {
        membership_type: membershipType,
        name: '',
        number: '',
        isEditMode: isEditMode
      }
    ])
  }

  const handleRemoveMemFields = (index) => {
    const _membershipInfoInputTemp = [...membershipInfoInput].filter(
      (item, key) => key !== index
    )
    setMembershipInfoInput(_membershipInfoInputTemp)
  }

  const travelPreferenceOnChange = (_name, _value, _isError, _errorMsg) => {
    settravelPreferenceInfo({
      ...travelPreferenceInfo,
      [_name]: _value
    })
    if (Object.hasOwnProperty.call(inputChangeLog, _name)) {
      updateInputChangeLog({
        ...inputChangeLog,
        [_name]: true
      })
    }
    updateError(_name, _isError, _errorMsg)
  }

  const onSecureTraveelerInfoInputChange = (
    _name,
    _value,
    _isError,
    _errorMsg
  ) => {
    setSecureTravelInputInfo({
      ...secureTravelInputInfo,
      [_name]: _value
    })
    if (Object.hasOwnProperty.call(inputChangeLog, _name)) {
      updateInputChangeLog({
        ...inputChangeLog,
        [_name]: true
      })
    }
    updateError(_name, _isError, _errorMsg)
  }

  const onPassportInfoInputChange = async (
    _name,
    _value,
    _obj = {},
    _isError = false,
    _errorMsg = ''
  ) => {
    if (
      (_name === 'passport_photo' || _name === 'travel_docs') &&
      _obj.openFile === true
    ) {
      if (_obj.file.isExistingFile) {
        const link = document.createElement('a')
        link.setAttribute('download', _obj.file.name)
        link.href = _obj.file.presigned_url
        document.body.appendChild(link)
        link.click()
        link.remove()
      } else {
        // fetch file path with signature from AWS S3
        dispatch(showLoader())
        dispatch(
          downloadSingleDocumnet({
            itemType: _name === 'travel_docs' ? 'travel_docs' : 'passport',
            fileName: _obj.file.returned_file_name,
            userId: null
          })
        ).then((res) => {
          dispatch(stopLoader())
          const link = document.createElement('a')
          link.setAttribute('download', _obj.file.returned_file_name)
          link.href = res.data.presigned_url
          document.body.appendChild(link)
          link.click()
          link.remove()
        })
      }
      return
    }

    if (_name === 'passport_photo' || _name === 'travel_docs') {
      if (_obj.isUpload) {
        const checkAlreadyUploadedFiles = uploadedFiles
          .filter((_file) => {
            if (_name === 'passport_photo') {
              return _file.file_type === 'passport'
            } else {
              return _file.file_type === 'travel_docs'
            }
          })
          .map((_file) => _file.user_file_name)
        const docsData = await uploadDocs(
          _value.filter(
            (_val) => !checkAlreadyUploadedFiles.includes(_val.name)
          ),
          _name === 'passport_photo' ? 'passport' : 'travel_docs'
        )
        _value = _value.map((_val) => {
          const found = docsData.find((doc) => doc.user_file_name === _val.name)
          if (found) {
            _val.returned_file_name = found.returned_file_name
          }
          return _val
        })
      } else if (_obj.isDeleted) {
        const _deletedFiles = []
        const _uploadedFiles = uploadedFiles.filter((file) => {
          if (
            (_name === 'passport_photo' && file.file_type !== 'passport') ||
            (_name === 'travel_docs' && file.file_type !== 'travel_docs')
          ) {
            return true
          }
          const result = !!_value.find(
            (_file) => _file.name === file.user_file_name
          )
          if (result === false && file.isExistingFile === true) {
            _deletedFiles.push(file.id)
          }
          return result
        })
        setUploadedFiles(_uploadedFiles)
        setFilesForDelete([...filesForDelete, ..._deletedFiles])
      }
    }

    setPassportInfoInput({
      ...passportInfoInput,
      [_name]: _value
    })
    if (Object.hasOwnProperty.call(inputChangeLog, _name)) {
      updateInputChangeLog({
        ...inputChangeLog,
        [_name]: true
      })
    }

    updateError(_name, _isError, _errorMsg)
  }

  const updateError = (_name, _isError, _errorMsg) => {
    if (_isError) {
      if (errorArray.indexOf(_name) === -1) errorArray.push(_name)
    } else {
      if (errorArray.indexOf(_name) !== -1) {
        errorArray.splice(errorArray.indexOf(_name), 1)
        _errorMsg = ''
      }
    }
    setErrorArray(errorArray)
    setErrorMsgs({
      ...errorMsgs,
      [_name]: _errorMsg
    })
  }

  const dispatch = useDispatch()
  useEffect(() => {
    const roleCanAccess = [
      'travel_itilite_admin',
      'travel_admin',
      'expense_itilite_admin',
      'expense_admin'
    ]

    window.addEventListener('scroll', () => {
      setScroll(window.scrollY > 10)
    })
    dispatch(showLoader())
    dispatch(verifyAccessToken(roleCanAccess))

    return () => {
      /**
       * isProfilePafe modifies the value of role in rquest header, if this page is in Profile mode.
       * When user navigate to some other page reset the isProfilePafe flag so that we can reset the value of role in rquest header.
       */
      _env.setProfilePage(false)
    }
  }, [])

  useEffect(() => {
    if (isTokenVerified === true) {
      dispatch(fetchRenderFileds())
    }
  }, [isTokenVerified])

  useEffect(() => {
    if (isRendered === true) {
      setPersonalInfoInput({
        ...personalInfoInput,
        country_code: countryCode
      })
      setEmployeeInfoInput({
        ...employeeInfoInput,
        currency: { value: defaultCurrency, label: defaultCurrency }
      })
      dispatch(fetchCountryCodes())
        .then((_res) => {
          if (isEditMode) {
            if (!isProfile && !editUserId) return false
            dispatch(
              setStaticFieldsVisibility({
                email: {
                  readOnly: true, // read only
                  required: false
                }
              })
            )
            dispatch(
              fetchUserDetails({
                userId: isProfile ? id : editUserId
              })
            )
              .then((res) => {
                setPersonalInfoInput({
                  ...personalInfoInput,

                  first_name: res.first_name || '',
                  last_name: res.last_name || '',
                  email: res.email || '',
                  contact_number: res.personal_info.contact_number || '',
                  dob: res.personal_info.dob || '',
                  gender: res.personal_info.gender || '',
                  country_code: res.personal_info.country_code || countryCode,
                  contact_country_code:
                    res.personal_info.contact_country_code || ''
                })
                setEmployeeInfoInput({
                  ...employeeInfoInput,
                  emp_level: res.employee_info.emp_level
                    ? {
                        value: res.employee_info.emp_level.id,
                        label: res.employee_info.emp_level.value
                      }
                    : { value: '', label: 'Select employee level' },
                  employee_id: res.employee_info.employee_id || '',
                  entity: res.employee_info.entity
                    ? {
                        value: res.employee_info.entity.id,
                        label: res.employee_info.entity.value
                      }
                    : { value: '', label: 'Select entity' },
                  business_unit: res.employee_info.business_unit
                    ? {
                        value: res.employee_info.business_unit.id,
                        label: res.employee_info.business_unit.value
                      }
                    : { value: '', label: 'Select unit' },
                  department: res.employee_info.department
                    ? {
                        value: res.employee_info.department.id,
                        label: res.employee_info.department.value
                      }
                    : { value: '', label: 'Select department' },
                  designation: res.employee_info.designation || '',
                  base_location: res.employee_info.base_location || '',
                  currency: res.employee_info.currency
                    ? {
                        value: res.employee_info.currency,
                        label: res.employee_info.currency
                      }
                    : { value: defaultCurrency, label: defaultCurrency }
                })
                dispatch(setPaymentDetails(res.payment_details))

                if (res?.user_tags?.length) {
                  const _userTagType = {}
                  renderFields.userTagType.forEach((tag) => {
                    if (res.user_tags.find((id) => id === tag.id)) {
                      _userTagType[tag.name] = tag.id
                    }
                  })
                  setUserTagType(_userTagType)
                }

                if (res?.custom_attribute_fields?.length) {
                  const customAttributeFields = {}
                  res.custom_attribute_fields.forEach((attr) => {
                    customAttributeFields[`custom${attr.attribute_column}`] =
                      attr.value
                  })
                  setCustomFieldsInput(customAttributeFields)
                }

                const products = res.user_product.split('+')
                setProductAccess({
                  travel: products.includes('travel'),
                  expense: products.includes('expense')
                })

                if (products.includes('travel')) {
                  setAdditionalInfoFlag('open')

                  setTravelRole(res.travel_role || '')
                  if (res.travel_approvers.length) {
                    const travelApprovers = {}
                    res.travel_approvers.forEach((approver) => {
                      if (
                        !approver.sequence ||
                        (typeof approver.sequence === 'string' &&
                          approver.sequence.toUpperCase() === 'FYI')
                      ) {
                        setTravelFYI({
                          value: approver.email,
                          label: approver.email
                        })
                      } else {
                        travelApprovers[`sequence-${approver.sequence}`] =
                          approver.email
                            ? {
                                value: approver.email,
                                label: approver.email
                              }
                            : { value: '', label: 'Select Approver' }
                      }
                    })
                    setTravelApproversInput((prevState) => {
                      return {
                        ...prevState,
                        ...travelApprovers
                      }
                    })
                  }

                  const _passportPhotos = []
                  const _travelDocs = []
                  const _uploadedDocs = []

                  res.files.forEach((_file) => {
                    if (_file.file_type === 'passport') {
                      _passportPhotos.push({
                        name: _file.user_file_name,
                        id: _file.id,
                        isExistingFile: true,
                        file_path: _file.file_path,
                        file_type: _file.file_type,
                        presigned_url: _file.presigned_url
                      })
                    }
                    if (_file.file_type === 'travel_docs') {
                      _travelDocs.push({
                        name: _file.user_file_name,
                        id: _file.id,
                        isExistingFile: true,
                        file_path: _file.file_path,
                        file_type: _file.file_type,
                        presigned_url: _file.presigned_url
                      })
                    }

                    _uploadedDocs.push({
                      user_file_name: _file.user_file_name,
                      id: _file.id,
                      isExistingFile: true,
                      file_type: _file.file_type
                    })
                  })

                  setPassportInfoInput({
                    ...passportInfoInput,
                    passport_issue_country: res?.travel_info
                      ?.passport_issue_country
                      ? {
                          label: res.travel_info.passport_issue_country,
                          value: res.travel_info.passport_issue_country
                        }
                      : { value: '', label: 'Select country' },
                    nationality: res?.travel_info?.nationality
                      ? {
                          label: res.travel_info.nationality,
                          value: res.travel_info.nationality
                        }
                      : { value: '', label: 'Select nationality' },
                    passport_number: res.travel_info.passport_number || '',
                    passport_issue_date:
                      res.travel_info.passport_issue_date || '',
                    passport_expiry_date:
                      res.travel_info.passport_expiry_date || '',
                    passport_photo: _passportPhotos,
                    travel_docs: _travelDocs
                  })

                  setMembershipInfoInput(res.membership_info)

                  settravelPreferenceInfo({
                    ...travelPreferenceInfo,
                    flight_seat_preference: res?.travel_info
                      ?.flight_seat_preference
                      ? res.travel_info.flight_seat_preference
                      : '',
                    meal_preference: res?.travel_info?.meal_preference
                      ? res.travel_info.meal_preference
                      : ''
                  })

                  setSecureTravelInputInfo({
                    ...secureTravelInputInfo,
                    trusted_traveller: res?.travel_info?.trusted_traveller
                      ? res.travel_info.trusted_traveller
                      : '',
                    redress_number: res?.travel_info?.redress_number
                      ? res.travel_info.redress_number
                      : ''
                  })

                  setUploadedFiles(_uploadedDocs)
                }

                if (products.includes('expense')) {
                  setExpenseRole(res.expense_role || '')

                  if (res.expense_approvers.length) {
                    const expenseApprovers = {}
                    res.expense_approvers.forEach((approver) => {
                      if (
                        !approver.sequence ||
                        (typeof approver.sequence === 'string' &&
                          approver.sequence.toUpperCase() === 'FYI')
                      ) {
                        setExpenseFYI({
                          value: approver.email,
                          label: approver.email
                        })
                      } else {
                        expenseApprovers[`sequence-${approver.sequence}`] = {
                          value: approver.email,
                          label: approver.email
                        }
                      }
                    })
                    setExpenseApproversInput((prevState) => {
                      return {
                        ...prevState,
                        ...expenseApprovers
                      }
                    })
                  }
                }
              })
              .catch(() => {})
              .finally(() => {
                dispatch(stopLoader())
              })
          } else {
            dispatch(
              setStaticFieldsVisibility({
                email: {
                  readOnly: false,
                  required: true
                }
              })
            )

            // product checkbox should be selected as default
            const _productAccess = { ...productAccess }
            renderFields.products.forEach((product) => {
              _productAccess[product.name] = true
            })
            setProductAccess(_productAccess)
            dispatch(stopLoader())
          }
        })
        .catch(() => {})
    }
  }, [isRendered])

  useEffect(() => {
    if (renderFields?.travelApprovers?.length) {
      setTravelApproversInput((prevState) => {
        const _travelApproversInput = { ...prevState }

        renderFields.travelApprovers.forEach((approver) => {
          if (!_travelApproversInput[approver.name]) {
            _travelApproversInput[approver.name] = {
              value: '',
              label: 'Select approver'
            }
          }
        })

        return _travelApproversInput
      })
    }
  }, [renderFields.travelApprovers])

  useEffect(() => {
    if (renderFields?.expenseApprovers?.length) {
      setExpenseApproversInput((prevState) => {
        const _expenseApproversInput = { ...prevState }

        renderFields.expenseApprovers.forEach((approver) => {
          if (!_expenseApproversInput[approver.name]) {
            _expenseApproversInput[approver.name] = {
              value: '',
              label: 'Select approver'
            }
          }
        })

        return _expenseApproversInput
      })
    }
  }, [renderFields.expenseApprovers])

  const uploadDocs = async (docToUpload, type) => {
    dispatch(showLoader())
    const data = []
    const docs = []
    docToUpload.forEach((doc) => {
      docs.push({
        itemType: type,
        fileName: doc.name,
        file: doc
      })
    })
    for (let i = 0; i < docs.length; i++) {
      const res = await dispatch(uploadSingleDocumnet(docs[i]))
      data.push(res)
    }
    setUploadedFiles([...uploadedFiles, ...data])
    dispatch(stopLoader())
    return data
  }

  const formSubmitHandler = async () => {
    const checkAnyError = doesFormHasError()
    if (checkAnyError) {
      UUIToastService.warningNotify(checkAnyError)
      return
    }
    const payload = {
      personalInfoInput,
      employeeInfoInput,
      customFieldsInput,
      productAccess,
      travelRole,
      travelApproversInput,
      travelFYI,
      membershipInfoInput,
      travelPreferenceInfo,
      secureTravelInputInfo,
      passportInfoInput,
      expenseRole,
      expenseApproversInput,
      expenseFYI,
      uploadedFiles,
      filesForDelete,
      userTagType
    }
    const _errorArray = [...errorArray]
    const _errorMsgs = { ...errorMsgs }
    let _isError = false
    const {
      user_personal_info: userPersonalInfo,
      employee_info: employeeInfo,
      user_travel_info: userTravelInfo
    } = optionalFields

    const allMandatoryFields = ['first_name', 'last_name', 'email']

    allMandatoryFields.forEach((key, index) => {
      if (personalInfoInput[key] === '') {
        _isError = true
        if (_errorArray.indexOf(key) === -1) {
          _errorArray.push(key)
        }
        _errorMsgs[key] = ErrorMessages[key]
      } else {
        if (_errorArray.indexOf(key) !== -1) {
          _errorArray.splice(_errorArray.indexOf(key), 1)
        }
        _errorMsgs[key] = ''
      }
    })

    for (const key in userPersonalInfo) {
      if (Object.hasOwnProperty.call(userPersonalInfo, key)) {
        if (
          userPersonalInfo[key] === false &&
          personalInfoInput[key] !== undefined &&
          !personalInfoInput[key] &&
          fieldsVisibility.user_personal_info[key] !== 2
        ) {
          _isError = true
          if (_errorArray.indexOf(key) === -1) _errorArray.push(key)
          _errorMsgs[key] = ErrorMessages[key]
        } else {
          if (_errorArray.indexOf(key) !== -1)
            _errorArray.splice(_errorArray.indexOf(key), 1)
          _errorMsgs[key] = ''
        }
      }
    }

    for (const key in employeeInfo) {
      if (Object.hasOwnProperty.call(employeeInfo, key)) {
        if (
          employeeInfo[key] === false &&
          employeeInfoInput[key] !== undefined &&
          ((typeof employeeInfoInput[key] === 'string' &&
            !employeeInfoInput[key]) ||
            (typeof employeeInfoInput[key] === 'object' &&
              !employeeInfoInput[key].value)) &&
          fieldsVisibility.employee_info[key] !== 2
        ) {
          _isError = true
          if (_errorArray.indexOf(key) === -1) _errorArray.push(key)
          _errorMsgs[key] = ErrorMessages[key]
        } else {
          if (_errorArray.indexOf(key) !== -1)
            _errorArray.splice(_errorArray.indexOf(key), 1)
          _errorMsgs[key] = ''
        }
      }
    }

    if (!productAccess.travel && !productAccess.expense) {
      _isError = true
      if (_errorArray.indexOf('products') === -1) {
        _errorArray.push('products')
      }
      _errorMsgs.products = ErrorMessages.products
    } else {
      if (_errorArray.indexOf('products') !== -1) {
        _errorArray.splice(_errorArray.indexOf('products'), 1)
      }
    }

    if (productAccess.travel && userTravelInfo) {
      for (const key in userTravelInfo) {
        if (Object.hasOwnProperty.call(userTravelInfo, key)) {
          if (
            userTravelInfo[key] === false &&
            ((passportInfoInput[key] !== undefined &&
              !passportInfoInput[key]) ||
              (travelPreferenceInfo[key] !== undefined &&
                !travelPreferenceInfo[key]) ||
              (secureTravelInputInfo[key] !== undefined &&
                !secureTravelInputInfo[key])) &&
            fieldsVisibility.user_travel_info[key] !== 2
          ) {
            _isError = true
            if (_errorArray.indexOf(key) === -1) _errorArray.push(key)
            _errorMsgs[key] = ErrorMessages[key]
          } else {
            if (_errorArray.indexOf(key) !== -1)
              _errorArray.splice(_errorArray.indexOf(key), 1)
            _errorMsgs[key] = ''
          }
        }
      }

      if (
        userTravelInfo.passport_expiry_date === false &&
        passportInfoInput.passport_issue_date &&
        passportInfoInput.passport_expiry_date === ''
      ) {
        _isError = true
        if (_errorArray.indexOf('passport_expiry_date') === -1) {
          _errorArray.push('passport_expiry_date')
        }
        _errorMsgs.passport_expiry_date = 'Passport expiry date cannot be empty'
      } else {
        if (_errorArray.indexOf('passport_expiry_date') !== -1) {
          _errorArray.splice(errorArray.indexOf('passport_expiry_date'), 1)
        }
        _errorMsgs.passport_expiry_date = ''
      }

      if (
        userTravelInfo.passport_issue_date === false &&
        passportInfoInput.passport_issue_date === '' &&
        passportInfoInput.passport_expiry_date
      ) {
        _isError = true
        if (_errorArray.indexOf('passport_issue_date') === -1) {
          _errorArray.push('passport_issue_date')
        }
        _errorMsgs.passport_issue_date = 'Passport issue date cannot be empty'
      } else {
        if (_errorArray.indexOf('passport_issue_date') !== -1) {
          _errorArray.splice(errorArray.indexOf('passport_issue_date'), 1)
        }
        _errorMsgs.passport_issue_date = ''
      }

      if (
        passportInfoInput.passport_photo.length === 0 &&
        userTravelInfo.file_name === false && // is not optional
        fieldsVisibility.user_travel_info.file_name !== 2
      ) {
        _isError = true
        if (_errorArray.indexOf('passport_photo') === -1) {
          _errorArray.push('passport_photo')
        }
        _errorMsgs.passport_photo = ErrorMessages.passport_photo
      } else {
        if (_errorArray.indexOf('passport_photo') !== -1) {
          _errorArray.splice(_errorArray.indexOf('passport_photo'), 1)
        }
        _errorMsgs.passport_photo = ''
      }

      if (
        passportInfoInput.travel_docs.length === 0 &&
        userTravelInfo.file_name === false && // is not optional
        fieldsVisibility.user_travel_info.file_name !== 2
      ) {
        _isError = true
        if (_errorArray.indexOf('travel_docs') === -1) {
          _errorArray.push('travel_docs')
        }
        _errorMsgs.travel_docs = ErrorMessages.travel_docs
      } else {
        if (_errorArray.indexOf('travel_docs') !== -1) {
          _errorArray.splice(_errorArray.indexOf('travel_docs'), 1)
        }
        _errorMsgs.travel_docs = ''
      }
    }

    if (_isError) {
      setErrorArray(_errorArray)
      setErrorMsgs(_errorMsgs)
      for (const key in _errorMsgs) {
        if (Object.hasOwnProperty.call(_errorMsgs, key)) {
          if (_errorMsgs[key]) {
            if (
              key in passportInfoInput ||
              key in travelPreferenceInfo ||
              key in secureTravelInputInfo
            ) {
              setAdditionalInfoFlag('open')
            }
            const el = document.getElementById(
              key === 'country_code' || key === 'contact_country_code'
                ? 'contact_number'
                : key
            )
            if (el) {
              setTimeout(() => {
                el.scrollIntoView({ behavior: 'smooth', block: 'center' })
              }, 500)
              break
            }
          }
        }
      }

      return
    }

    if ((isEditMode && editUserId) || isProfile) {
      dispatch(showLoader())
      dispatch(updateUser(payload, isProfile ? id : editUserId, inputChangeLog))
        .then((res) => {
          if (isProfile) {
            config?.routes?.profile?.redirectUrl?.submit &&
              history.push(config.routes.profile.redirectUrl.submit)
          } else {
            config?.routes?.users?.list?.path &&
              history.push(config.routes.users.list.path)
          }
        })
        .catch((err) => {
          if (typeof err === 'object') {
            if (err.country_code) {
              err.contact_number = err.country_code
            }
            if (err.contact_country_code) {
              err.contact_number = err.contact_country_code
            }
            if (err.passport) {
              err.passport_photo = { ...err.passport }
              delete err.passport
            }
            setErrorArray(Object.keys(err))
            setErrorMsgs(err)
            for (const key in err) {
              if (Object.hasOwnProperty.call(err, key)) {
                if (err[key]) {
                  if (
                    key in passportInfoInput ||
                    key in travelPreferenceInfo ||
                    key in secureTravelInputInfo
                  ) {
                    setAdditionalInfoFlag('open')
                  }
                  const el = document.getElementById(
                    key === 'country_code' || key === 'contact_country_code'
                      ? 'contact_number'
                      : key
                  )
                  if (el) {
                    setTimeout(() => {
                      el.scrollIntoView({ behavior: 'smooth', block: 'center' })
                    }, 500)
                    break
                  }
                }
              }
            }
          } else if (typeof err === 'string') {
            UUIToastService.warningNotify(err)
          } else {
            UUIToastService.warningNotify('Opps, Something went wrong.')
          }
        })
        .finally(() => {
          dispatch(stopLoader())
        })
    } else {
      dispatch(showLoader())
      dispatch(createUser(payload))
        .then((res) => {
          config?.routes?.users?.list?.path &&
            history.push(config.routes.users.list.path)
        })
        .catch((err) => {
          if (typeof err === 'object') {
            if (err.country_code) {
              err.contact_number = err.country_code
            }
            if (err.contact_country_code) {
              err.contact_number = err.contact_country_code
            }
            if (err.passport) {
              err.passport_photo = { ...err.passport }
              delete err.passport
            }
            setErrorArray(Object.keys(err))
            setErrorMsgs(err)
            for (const key in err) {
              if (Object.hasOwnProperty.call(err, key)) {
                if (err[key]) {
                  if (
                    key in passportInfoInput ||
                    key in travelPreferenceInfo ||
                    key in secureTravelInputInfo
                  ) {
                    setAdditionalInfoFlag('open')
                  }
                  const el = document.getElementById(
                    key === 'country_code' || key === 'contact_country_code'
                      ? 'contact_number'
                      : key
                  )
                  if (el) {
                    setTimeout(() => {
                      el.scrollIntoView({ behavior: 'smooth', block: 'center' })
                    }, 500)
                    break
                  }
                }
              }
            }
          } else if (typeof err === 'string') {
            UUIToastService.warningNotify(err)
          } else {
            UUIToastService.warningNotify('Opps, Something went wrong.')
          }
        })
        .finally(() => {
          dispatch(stopLoader())
        })
    }
  }

  const handleCancelNavigation = () => {
    if (isProfile) {
      if (config.routes.profile.redirectUrl.cancel) {
        if (config.routes.profile.redirectUrl.sameDomain) {
          history.push(config.routes.profile.redirectUrl.cancel)
        } else {
          window.location.href = config.routes.profile.redirectUrl.cancel
        }
      }
    } else {
      config?.routes?.users?.list?.path &&
        history.push(config.routes.users.list.path)
    }
  }

  const handleCustomFieldsNavigation = () => {
    history.push(config.routes.customFields.list.path)
  }

  const doesFormHasError = () => {
    for (const key in errorMsgs) {
      if (Object.hasOwnProperty.call(errorMsgs, key)) {
        if (
          errorMsgs[key] !== '' &&
          key !== 'passport_photo' &&
          key !== 'travel_docs'
        ) {
          return errorMsgs[key]
        }
      }
    }
    return false
  }

  return (
    <Box component='div' className={styles.UUIUserManagementAddEdit}>
      {!hasVerificationError ? (
        <Fragment>
          <Backdrop open={isPageLoading} className={styles.backdrop}>
            <CircularProgress color='inherit' />
          </Backdrop>
          <Box
            component='div'
            className={clsx(styles.pageHeader, scroll ? styles.scrolled : '')}
          >
            {!isProfile && (
              <Box component='div' className={styles.breadcrumb}>
                <BreadCrumb
                  breadCrumbs={[
                    {
                      href: config.routes.users.list.path,
                      label: 'Users',
                      hasLink: true
                    }
                  ]}
                />
              </Box>
            )}
            <Box
              component='div'
              className={
                isProfile && config.env.isTravelProduct
                  ? styles.headingButtonWrapperProfile
                  : styles.headingButtonWrapper
              }
              display='flex'
              justifyContent='space-between'
              alignItems='center'
            >
              <Box component='div' className={styles.headingWrapper}>
                <Typography
                  variant='h3'
                  className={clsx(styles.heading, styles.headingLarge)}
                >
                  {isILadmin && companyName && !isProfile
                    ? `${companyName} - `
                    : ''}
                  {isEditMode
                    ? isProfile
                      ? 'Profile'
                      : 'Edit user'
                    : ' Add user'}
                </Typography>
              </Box>
              <Box
                component='div'
                className={styles.buttonWrapper}
                display='flex'
              >
                <Box
                  component='div'
                  className={clsx(styles.buttonSingle, styles.buttonLeft)}
                >
                  <CustomButton
                    buttonText='Cancel'
                    type='outlined'
                    onClickHandler={handleCancelNavigation}
                  />
                </Box>
                <Box
                  component='div'
                  className='pageHeader--button-single button-right'
                >
                  <CustomButton
                    buttonText='Save'
                    type='contained'
                    onClickHandler={formSubmitHandler}
                    disabled={
                      isPageLoading ||
                      (productAccess.travel === false &&
                        productAccess.expense === false) ||
                      !!doesFormHasError()
                    }
                  />
                </Box>
              </Box>
            </Box>
          </Box>
          <Box component='div' marginTop='8rem' flexDirection='column'>
            <Divider className={styles.dividerColor} />
            <Typography className={styles.hintText}>
              * Indicates a required field
            </Typography>
          </Box>
          <Box component='div' className={styles.formWrapper}>
            <Grid
              container
              className={clsx(styles.personal_info, styles.sectionWrapper)}
            >
              <Grid item sm={12} md={4}>
                <CustomLabelComponent label='Personal information' headerType />
              </Grid>
              <Grid item sm={12} md={8} lg={8} xl={6}>
                <Grid
                  container
                  className={clsx(
                    styles.formFieldsWrapper,
                    styles.firstRowMargin
                  )}
                >
                  {renderFields.personalInfo.map((p, i) => {
                    if (!p.visibility) {
                      return null
                    }
                    return (
                      <Grid
                        item
                        sm={12}
                        md={12}
                        lg={p.fullWidth ? 12 : 6}
                        xl={p.fullWidth ? 12 : 6}
                        key={i}
                        className={styles.margin}
                      >
                        <GetInputType
                          _p={p}
                          inputState={personalInfoInput}
                          customHandler={onPersonalInfoChange}
                          dropdownList={dropdownList}
                          dateFormat={dateFormat}
                          countryCode={countryCode}
                          errorArray={errorArray}
                          errorMsg={errorMsgs[p.name]}
                        />
                      </Grid>
                    )
                  })}
                </Grid>
              </Grid>
            </Grid>
            <Divider className={styles.dividerColor} />
            <Grid
              container
              className={clsx(styles.work_info, styles.sectionWrapper)}
            >
              <Grid item sm={12} md={4}>
                <CustomLabelComponent label='Work information' headerType />
              </Grid>
              <Grid item sm={12} md={8} lg={8} xl={6}>
                <Grid
                  container
                  className={clsx(
                    styles.formFieldsWrapper,
                    styles.firstRowMargin
                  )}
                >
                  {renderFields.workInfo.map((p, i) => {
                    if (!p.visibility) {
                      return null
                    }
                    return (
                      <Grid
                        item
                        sm={12}
                        md={12}
                        lg={p.fullWidth ? 12 : 6}
                        xl={p.fullWidth ? 12 : 6}
                        key={i}
                        className={styles.margin}
                      >
                        <GetInputType
                          _p={p}
                          inputState={employeeInfoInput}
                          customHandler={onEmployeeInfoChange}
                          dropdownList={dropdownList}
                          dateFormat={dateFormat}
                          countryCode={countryCode}
                          errorArray={errorArray}
                          errorMsg={errorMsgs[p.name]}
                        />
                      </Grid>
                    )
                  })}

                  {renderFields.userTagType.length ? (
                    <Fragment>
                      <Grid
                        container
                        className={clsx(
                          styles.formFieldsWrapper,
                          styles.firstRowMargin,
                          styles.margin
                        )}
                      >
                        <CustomLabelComponent
                          label='User Tag Types'
                          hasInfoIcon
                          tooltipText='test tooltip'
                        />
                      </Grid>
                      <Grid
                        container
                        className={clsx(
                          styles.formFieldsWrapper,
                          styles.firstRowMargin,
                          styles.margin
                        )}
                      >
                        {renderFields.userTagType.map((p, i) => {
                          return (
                            <Grid
                              item
                              sm={12}
                              md={12}
                              lg={p.fullWidth ? 12 : 6}
                              xl={p.fullWidth ? 12 : 6}
                              key={i}
                              className={styles.margin}
                            >
                              <GetInputType
                                _p={p}
                                inputState={userTagType}
                                customHandler={onUserTagTypeChange}
                              />
                            </Grid>
                          )
                        })}
                      </Grid>
                    </Fragment>
                  ) : null}
                </Grid>
              </Grid>
            </Grid>
            <CustomFields
              inputState={customFieldsInput}
              onChangeHandler={onCustomFieldsChange}
              handleCustomFieldsNavigation={handleCustomFieldsNavigation}
              isProfile={isProfile}
            />
            <Divider className={styles.dividerColor} />
            <Grid
              container
              className={clsx(styles.product_info, styles.sectionWrapper)}
            >
              <Grid item sm={12} md={4}>
                <CustomLabelComponent
                  label='Product access'
                  description={isProfile ? '' : 'Select at least one product'}
                  headerType
                  required
                />
              </Grid>
              <Grid item sm={12} md={8} lg={8} xl={6}>
                <Grid container className={styles.productContainerGap}>
                  {renderFields.products.map((p, i) => {
                    p.readOnly = isProfile
                    return (
                      <Grid
                        item
                        sm={12}
                        md={p.fullWidth ? 12 : 6}
                        lg={p.fullWidth ? 12 : 6}
                        xl={p.fullWidth ? 12 : 6}
                        key={i}
                        className={styles.containerWidth}
                      >
                        <GetInputType
                          _p={p}
                          inputState={productAccess}
                          customHandler={addProductAccessSelected}
                        />
                      </Grid>
                    )
                  })}
                  {errorArray.indexOf('products') !== -1 && (
                    <Grid item sm={12} md={12} lg={12}>
                      <Typography
                        variant='body2'
                        component='span'
                        className={styles.errorTextCls}
                      >
                        {errorMsgs.products}
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Divider className={styles.dividerColor} />
            {productAccess && productAccess.travel && (
              <Fragment>
                <TravelExpenseFields
                  fieldsInfo={renderFields.travelApprovers}
                  travelConstants={travelConstants}
                  type='travel'
                  addFields={() => dispatch(addTravelApproverField())}
                  onRoleChange={setTravelRole}
                  roleValue={travelRole}
                  approversInput={travelApproversInput}
                  onApproverChange={onTravelApproversInputChange}
                  fyiApprover={travelFYI}
                  onFYIChange={setTravelFYI}
                  showSecondaryApproverLabel={isTravelApproverLabelUpadted}
                  maxApprovers={maxTravelApprovers}
                  isProfile={isProfile}
                  editUserId={editUserId}
                  roles={renderFields.travelRoles}
                />
                <Divider className={styles.dividerColor} />
                <AdditionalInfo
                  membershipInfoInput={membershipInfoInput}
                  onMemInfoInputChange={onMemInfoInputChange}
                  handleRemoveMemFields={handleRemoveMemFields}
                  addMemInfoInput={addMemInfoInput}
                  travelPreferenceInfo={travelPreferenceInfo}
                  travelPreferenceOnChange={travelPreferenceOnChange}
                  secureTravelInputInfo={secureTravelInputInfo}
                  onSecureTraveelerInfoInputChange={
                    onSecureTraveelerInfoInputChange
                  }
                  passportInfoInput={passportInfoInput}
                  onPassportInfoInputChange={onPassportInfoInputChange}
                  errorArray={errorArray}
                  errorMsgs={errorMsgs}
                  dateFormat={dateFormat}
                  defaultExpand={isAdditionalInfoExpanded}
                  setAdditionalInfoFlag={setAdditionalInfoFlag}
                />
              </Fragment>
            )}

            <Divider className={styles.dividerColor} />

            {productAccess && productAccess.expense && (
              <TravelExpenseFields
                fieldsInfo={renderFields.expenseApprovers}
                travelConstants={expenseConstants}
                type='expense'
                addFields={() => dispatch(addExpenseApproverField())}
                onRoleChange={setExpenseRole}
                roleValue={expenseRole}
                approversInput={expenseApproversInput}
                onApproverChange={onExpenseApproversInputChange}
                fyiApprover={expenseFYI}
                onFYIChange={setExpenseFYI}
                showSecondaryApproverLabel={isExpenseApproverLabelUpadted}
                maxApprovers={maxExpenseApprovers}
                isProfile={isProfile}
                editUserId={editUserId}
                roles={renderFields.expenseRoles}
              />
            )}
          </Box>
        </Fragment>
      ) : (
        <VerificationError config={config} errMsg={hasVerificationError} />
      )}
    </Box>
  )
}
export default withStore(UserAddEdit)
