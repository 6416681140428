import React, { useEffect, useState, Fragment } from 'react'
import styles from '../UserList/UserList.module.scss'
import withStore from '../../../store/withStore'
import { useDispatch, useSelector } from 'react-redux'
import {
  addRecord,
  deleteRecord,
  editRecord,
  getUserPanalData,
  openDialog,
  closeDialog,
  moveRecord,
  userPanelShowLoader,
  userPanelStopLoader
} from '../../../store/userPanel/actions'
import { verifyAccessToken } from '../../../store'
import Header from './Header'
import Listview from './List'
import AddPanelDialog from './AddPanelDialog'
import DeletePanelModal from './DeletePanelModal'
import EditPanelDialog from './EditPanelDialog'
import MoveUserDialog from './MoveUserDialog'
import UUIToastService from '../../../utils/ToastService'
import { Box } from '@material-ui/core'
import SkeletonLoader from '../CustomFields/SkeletonLoader'
import VerificationError from '../Components/VerificationError'

const UserPanel = ({
  title,
  headingName,
  subHeading,
  tableName,
  config,
  module
}) => {
  const dispatch = useDispatch()
  const [dataList, setDataList] = useState('')
  const [addModal, setAddModal] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [editModal, setEditModal] = useState(false)
  const [moveModal, setMoveModal] = useState(false)
  const [selectValue, setSelectValue] = useState('')
  const [errorAdd, setErrorAdd] = useState(false)
  const [errorEdit, setErrorEdit] = useState(false)
  const [errorMove, setErrorMove] = useState(false)
  const [dropdownList, setDropdownList] = useState([])

  const { userPanelData, dialogData, loading } = useSelector(
    (state) => state.userPanel
  )
  const { isTokenVerified, hasVerificationError } = useSelector(
    (state) => state.common
  )
  // const { loading } = useSelector((state) => state.customFields)

  const customHandler = (name, value) => {
    const validatedValue = value.replace(/\s+/g, ' ').trim()
    setDataList(validatedValue)
  }
  const validation = {
    regexOnChange: /^[A-Za-z]+[A-Za-z0-9()&'\-_. ]*$/,
    regexOnBlur: null,
    maxLimit: '',
    minLimit: '',
    maxLength: 70,
    minLength: 0
  }
  useEffect(() => {
    const roleCanAccess = [
      'travel_itilite_admin',
      'travel_admin',
      'expense_itilite_admin',
      'expense_admin'
    ]
    dispatch(verifyAccessToken(roleCanAccess))
  }, [])

  useEffect(() => {
    dispatch(userPanelShowLoader())
    if (isTokenVerified) {
      dispatch(getUserPanalData(tableName, 5)).then(() =>
        dispatch(userPanelStopLoader())
      )
    }
  }, [isTokenVerified])

  useEffect(() => {
    const arrList = userPanelData.results.filter(
      (item) => item.name !== dialogData.data.name
    )
    setDropdownList(arrList)
  }, [userPanelData, dialogData])

  const addDialog = () => {
    setAddModal(true)
  }
  const closeDeActivateModal = () => {
    setAddModal(false)
    setErrorAdd(false)
    setDataList('')
  }

  const openDeleteModal = (data, replace) => {
    const headerText = 'Delete ' + data.name + '?'
    dispatch(
      openDialog(
        {
          showMoveButton: replace,
          headerText: headerText,
          module: module
        },
        '',
        data
      )
    )
    if (replace) {
      setMoveModal(true)
      setDeleteModal(false)
    } else {
      setDeleteModal(true)
      setMoveModal(false)
    }
  }

  const openEditDialog = (data) => {
    dispatch(
      openDialog(
        {
          headerText: `Edit ${data.name}?`,
          cancelLabel: 'Cancel',
          submitLabel: 'Save'
        },
        '',
        data
      )
    )
    setDataList(data.name)
    setEditModal(true)
  }

  const closeEditDialog = () => {
    setEditModal(false)
    setErrorEdit(false)
    setDataList('')
    dispatch(closeDialog())
  }

  const handleAddUserPanel = () => {
    if (dataList) {
      setErrorAdd(false)
      dispatch(userPanelShowLoader())
      dispatch(
        addRecord(tableName, {
          names: [dataList]
        })
      ).then((res) => {
        UUIToastService.sNotify(`${title} added successfully.`)
        closeDeActivateModal()
        dispatch(userPanelStopLoader())
        setDataList('')
      })
    } else {
      setErrorAdd(true)
    }
  }

  const handleDeletePanel = (data) => {
    if (!dialogData.labels.showMoveButton) {
      dispatch(userPanelShowLoader())
      dispatch(deleteRecord(tableName, { row_ids: [dialogData.data.id] })).then(
        (res) => {
          UUIToastService.sNotify(`${title} deleted successfully.`)
          dispatch(userPanelStopLoader())
          closeDeleteModal()
        }
      )
    }
  }

  const handleMovePanel = () => {
    if (selectValue) {
      setErrorMove(false)
      dispatch(userPanelShowLoader())
      dispatch(
        moveRecord(tableName, {
          table_name: tableName,
          from: dialogData.data.name,
          to: selectValue?.label
        })
      ).then((res) => {
        UUIToastService.sNotify(res.data.message)
        setMoveModal(false)
        dispatch(closeDialog())
        dispatch(userPanelStopLoader())
        setSelectValue('')
      })
    } else {
      setErrorMove(true)
    }
  }
  const handleEditPanel = (data) => {
    if (dataList) {
      setErrorEdit(false)
      dispatch(userPanelShowLoader())
      dispatch(
        editRecord(tableName, {
          rows: [{ id: dialogData.data.id, name: dataList }]
        })
      ).then((res) => {
        dispatch(userPanelStopLoader())
        UUIToastService.sNotify(`${title} updated successfully.`)
        closeEditDialog()
        setDataList('')
      })
    } else {
      setErrorEdit(true)
    }
  }

  const handleMoveDialog = (data) => {}

  const customMoveSelectHandler = (name, value) => {
    setSelectValue(value)
  }

  const closeMoveModal = () => {
    setMoveModal(false)
    setSelectValue('')
  }
  const closeDeleteModal = () => {
    setDeleteModal(false)
  }

  return (
    <Box component='div' className={styles.UUIUserManagementList}>
      {hasVerificationError === false ? (
        <Fragment>
          {loading ? (
            <SkeletonLoader />
          ) : (
            <Fragment>
              <Header
                name={title}
                headingName={headingName}
                subHeading={subHeading}
                HeaderButton={addDialog}
              />
              <Listview
                data={userPanelData}
                name={title}
                tableName={tableName}
                handleEditDialog={openEditDialog}
                handleDeleteDialog={openDeleteModal}
                closeDeActivateModal={closeDeActivateModal}
                handleMoveDialog={handleMoveDialog}
              />
            </Fragment>
          )}

          <AddPanelDialog
            name={title}
            isOpen={addModal}
            isError={errorAdd}
            customHandler={customHandler}
            closeHandler={closeDeActivateModal}
            submitHandler={handleAddUserPanel}
            isDisabledSubmitBtn={loading}
            validation={validation}
          />
          <DeletePanelModal
            name={title}
            isOpen={deleteModal}
            list={dropdownList}
            value={selectValue}
            data={dialogData.data}
            customHandler={customHandler}
            closeHandler={closeDeleteModal}
            submitHandler={handleDeletePanel}
            isDisabledSubmitBtn={loading}
            isError={errorMove}
            customSelectHandler={customMoveSelectHandler}
          />
          <MoveUserDialog
            name={title}
            tableName={tableName}
            list={dropdownList}
            isOpen={moveModal}
            data={dialogData.data}
            value={selectValue}
            customHandler={customHandler}
            closeHandler={closeMoveModal}
            submitHandler={handleMovePanel}
            isError={errorMove}
            isDisabledSubmitBtn={loading}
            customSelectHandler={customMoveSelectHandler}
          />
          <EditPanelDialog
            name={title}
            isOpen={editModal}
            data={dialogData.data}
            customHandler={customHandler}
            closeHandler={closeEditDialog}
            submitHandler={handleEditPanel}
            isError={errorEdit}
            isDisabledSubmitBtn={loading}
            validation={validation}
          />
        </Fragment>
      ) : (
        <VerificationError config={config} />
      )}
    </Box>
  )
}

export default withStore(UserPanel)
