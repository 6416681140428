import React from 'react'
import { UUIInput } from '../../../UIComponents/Input'
import { BaseWebDatePicker } from '../../../UIComponents/BaseWebDatePicker/BaseWebDatePicker'
import { CustomSelect } from '../../../UIComponents/Select/CustomSelect'
import { MuiPhone } from '../../../UIComponents/MuiPhone/MuiPhone'
import { CustomCheckbox } from '../../../UIComponents/CheckboxButton/CustomCheckbox'
import { CustomLabelComponent } from '../../../UIComponents/Label'
import { Box } from '@material-ui/core'
import { FileUpload } from '../../../UIComponents/FileUpload'
import AsyncCustomSelect from '../../../UIComponents/Select/AsyncCustomSelect'
export function GetInputType({
  _p,
  inputState = {},
  customHandler = () => {},
  dropdownList = {},
  dateFormat = 'dd MMM yyyy',
  countryCode,
  errorArray = [],
  errorMsg = '',
  disableText = ''
}) {
  switch (_p.type) {
    case 'input':
      return (
        <UUIInput
          label={_p.label}
          secondaryLabel={_p.secondaryLabel}
          fullWidth={_p.fullWidth}
          name={_p.name}
          id={_p.name}
          defaultValue={inputState[_p.name]}
          customHandler={customHandler}
          placeholder={_p.placeholder}
          validation={_p.validation}
          isError={errorArray.indexOf(_p.name) !== -1}
          errorMsg={errorMsg}
          required={_p.required}
          readOnly={_p.readOnly}
          hasInfoIcon={!!_p.tooltip}
          tooltipText={_p.tooltip}
          disableText={_p.disableText}
        />
      )
    case 'datepicker':
      return (
        <Box>
          <CustomLabelComponent label={_p.label} required={_p.required} />
          <BaseWebDatePicker
            label={_p.label}
            fullWidth={_p.fullWidth}
            hasIcon={_p.hasIcon}
            size={_p.size}
            name={_p.name}
            id={_p.name}
            dateValue={inputState[_p.name]}
            customHandler={customHandler}
            displayDateType={dateFormat}
            placeholder={dateFormat.toUpperCase()}
            maxDate={_p.maxDate}
            minDate={_p.minDate}
            isError={errorArray.indexOf(_p.name) !== -1}
            errorMsg={errorMsg}
            disabled={_p.readOnly}
            hasInfoIcon={!!_p.tooltip}
            tooltipText={_p.tooltip}
            required={_p.required}
            disableText={_p.disableText}
          />
        </Box>
      )

    case 'select':
      return (
        <CustomSelect
          label={_p.label}
          fullWidth={_p.fullWidth}
          list={dropdownList[_p.name]}
          valueKey='name'
          required={_p.required}
          name={_p.name}
          id={_p.name}
          value={inputState[_p.name]}
          customSelectHandler={customHandler}
          menuItemTitle={_p.placeholder}
          isError={errorArray.indexOf(_p.name) !== -1}
          errorMsg={errorMsg}
          readOnly={_p.readOnly}
          hasInfoIcon={!!_p.tooltip}
          tooltipText={_p.tooltip}
          disableText={_p.disableText}
        />
      )
    case 'phone':
      return (
        <Box>
          <CustomLabelComponent label={_p.label} required={_p.required} />
          <MuiPhone
            list={dropdownList[_p.name]}
            name={_p.name}
            id={_p.name}
            value={inputState[_p.name]}
            required={_p.required}
            selectedCountryOverride={inputState.country_code}
            customHandler={customHandler}
            validation={_p.validation}
            isError={errorArray.indexOf(_p.name) !== -1}
            errorMsg={errorMsg}
            readOnly={_p.readOnly}
            hasInfoIcon={!!_p.tooltip}
            tooltipText={_p.tooltip}
          />
        </Box>
      )
    case 'checkbox':
      return (
        <CustomCheckbox
          label={_p.label}
          isCheckbox
          customHandler={customHandler}
          checkboxFlag={inputState[_p.name]}
          disabled={_p.readOnly}
          hasInfoIcon={!!_p.tooltip}
          tooltipText={_p.tooltip}
          id={_p.name}
          disableText={_p.disableText}
        />
      )

    case 'file':
      return (
        <Box>
          <CustomLabelComponent
            label={_p.label}
            required={_p.required}
            description={_p.description}
            hasInfoIcon={!!_p.tooltip}
            tooltipText={_p.tooltip}
          />
          <FileUpload
            multiple={_p.multiple}
            title={_p.title}
            fileType={_p.fileType}
            name={_p.name}
            id={_p.name}
            customHandler={customHandler}
            value={inputState[_p.name]}
            maxFiles={_p.maxNoFiles}
            maxFileSize={_p.fileSize}
            isError={errorArray.indexOf(_p.name) !== -1}
            errorMsg={errorMsg}
            readOnly={_p.readOnly}
            hasInfoIcon={!!_p.tooltip}
            tooltipText={_p.tooltip}
            disableText={_p.disableText}
          />
        </Box>
      )
    case 'async_select':
      return (
        <AsyncCustomSelect
          id={_p.name}
          additional={{
            page: 1,
            tabelName: _p.tabelName,
            product: _p.product,
            placeholder: _p.placeholder,
            userId: _p.editUserId
          }}
          label={_p.label}
          secondaryLabel={_p.secondaryLabel}
          title={_p.title}
          value={inputState[_p.name]}
          name={_p.name}
          customSelectHandler={customHandler}
          inputType={_p.inputType}
          loadOption={_p.loadOption}
          isError={errorArray.indexOf(_p.name) !== -1}
          errorMsg={errorMsg}
          required={_p.required}
          readOnly={_p.readOnly}
          placeHolderCheck={
            inputState[_p.name] !== undefined &&
            inputState[_p.name].value === ''
          }
          hasInfoIcon={!!_p.tooltip}
          tooltipText={_p.tooltip}
          disableText={_p.disableText}
        />
      )
    default:
      return null
  }
}
