import React from 'react'
import { withStyles } from '@material-ui/core/styles'
// import sampleList from './sampleList.json'
import { Box, FormGroup, Grid, Typography } from '@material-ui/core'
import clsx from 'clsx'
import styles from './UserList.module.scss'
import Switch from '@material-ui/core/Switch'
import { CustomButton } from '../../../UIComponents/Button/CustomButton'
import { CustomDataTable } from '../../../UIComponents/CustomTable'
const AntSwitch = withStyles((theme) => ({
  root: {
    width: 32,
    height: 16,
    padding: 0,
    display: 'flex',
    overflow: 'inherit'
  },
  switchBase: {
    padding: 2,
    color: theme.palette.common.white,
    '&$checked': {
      color: theme.palette.common.white,
      transform: 'translateX(16px)',
      '& + $track': {
        opacity: 1,
        backgroundColor: '#38A169',
        borderColor: '#38A169'
      }
    }
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: 'none'
  },
  track: {
    border: `1px solid #748094`,
    backgroundColor: '#748094',
    borderRadius: 8,
    opacity: 1
  },
  checked: {}
}))(Switch)

export const StickyHeadTable = (props) => {
  const {
    data,
    handleChangeRowsPerPage = () => {},
    handleChangePage,
    page,
    rowsPerPage,
    handleActiveUser = () => {},
    handleSelectAllClick = () => {},
    selected,
    handleSingleCheckBoxClick = () => {},
    orderBy,
    order,
    onRequestSort = () => {},
    onSearchHandler = () => {},
    onEditHandler = () => {},
    isDataFetching = false,
    disableRowCheckbox = false
  } = props
  const getFirstLetterofName = (firstName, lastName) => {
    return firstName.charAt(0).toUpperCase() + lastName.charAt(0).toUpperCase()
  }

  const columns = [
    {
      id: 'first_name',
      label: 'Name',
      minWidth: 250,
      sortable: true,
      cell: (row) => (
        <Box component='div' display='flex' alignItems='center'>
          <Box component='div' width={50}>
            <p
              data-letters={getFirstLetterofName(row.first_name, row.last_name)}
              className={styles.letterImage}
            />
          </Box>
          <Box
            component='div'
            display='flex'
            flexDirection='column'
            width={200}
          >
            <Typography className={clsx(styles.rowText, styles.textTransform)}>
              {row.first_name + ' ' + row.last_name}
            </Typography>
            <Typography className={clsx(styles.rowText, styles.lightColor)}>
              {row.email}
            </Typography>
          </Box>
        </Box>
      )
    },
    {
      id: 'emp_info__department__name',
      label: 'Department',
      minWidth: 100,
      sortable: true,
      cell: (row) => (
        <Box component='div' width={100}>
          {row.employee_info && (
            <Typography className={clsx(styles.rowText, styles.textTransform)}>
              {row.employee_info.department}
            </Typography>
          )}
        </Box>
      )
    },
    {
      id: 'productusermapping__role',
      label: 'Product roles',
      minWidth: 170,
      sortable: true,
      cell: (row) => (
        <Box component='div'>
          {row.products.length > 0 ? (
            <React.Fragment>
              <Box component='div' display='flex'>
                <Typography
                  className={clsx(
                    styles.rowText,
                    styles.textTransform,
                    styles.lightColor
                  )}
                  style={{ whiteSpace: 'nowrap' }}
                >
                  {row.products[0].name + ' / ' + ' '}
                </Typography>
                <Typography
                  className={clsx(styles.rowText, styles.textTransform)}
                  style={{ whiteSpace: 'nowrap' }}
                >
                  {'\u00A0' + row.products[0].role}
                </Typography>
              </Box>
              {row.products[1] && (
                <Box component='div' display='flex'>
                  <Typography
                    className={clsx(
                      styles.rowText,
                      styles.textTransform,
                      styles.lightColor
                    )}
                    style={{ whiteSpace: 'nowrap' }}
                  >
                    {row.products[1].name + ' / '}
                  </Typography>
                  <Typography
                    className={clsx(styles.rowText, styles.textTransform)}
                    style={{ whiteSpace: 'nowrap' }}
                  >
                    {'\u00A0' + row.products[1].role}
                  </Typography>
                </Box>
              )}
            </React.Fragment>
          ) : (
            ''
          )}
        </Box>
      )
    },
    {
      id: 'status',
      label: 'Status',
      minWidth: 190,
      cell: (row) => (
        <FormGroup>
          <Typography
            component='div'
            className={clsx(styles.rowText, styles.textTransform)}
          >
            <Grid component='label' container alignItems='center' spacing={1}>
              <Grid item>
                <AntSwitch
                  checked={row.is_active}
                  onChange={(e) => handleActiveUser(e, row.id, page)}
                  name='checkedC'
                  id={row.id.toString()}
                />
              </Grid>
              <Grid item>{row.is_active ? 'Activated' : 'Deactivated'}</Grid>
            </Grid>
          </Typography>
        </FormGroup>
      )
    },
    {
      id: 'actions',
      label: 'Actions',
      minWidth: 170,
      align: 'left',
      cell: (row) => (
        <CustomButton
          buttonText='Edit'
          onClickHandler={() => onEditHandler(row.id)}
        />
      ),
      style: { paddingLeft: '40px' }
    }
  ]

  return (
    <CustomDataTable
      searchPlaceholder='Search by name, email & department'
      isCheckboxTable
      columns={columns}
      rows={data.results}
      count={data.count}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      handleChangePage={handleChangePage}
      page={page}
      rowsPerPage={rowsPerPage}
      handleSelectAllClick={handleSelectAllClick}
      selected={selected}
      handleSingleCheckBoxClick={handleSingleCheckBoxClick}
      orderBy={orderBy}
      order={order}
      searchHide={false}
      hasFilter
      onRequestSort={onRequestSort}
      onSearchHandler={onSearchHandler}
      isDataFetching={isDataFetching}
      disableRowCheckbox={disableRowCheckbox}
    />
  )
}
