import { VERIFY_ACCESS_TOKEN, SET_VERIFICATION_ERROR } from './types'
import api from '../../utils/Api'
import { common } from '../../utils/Urls'
import { UUILocalStorageService } from '../../utils/LocalStorageService'
import _env from '../../../env'
export const setTokenResponse = (payload) => {
  return {
    type: VERIFY_ACCESS_TOKEN,
    payload
  }
}

export const setVerificationError = (payload) => {
  return {
    type: SET_VERIFICATION_ERROR,
    payload: payload
  }
}

export const verifyAccessToken = (roleCanAccess = []) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(setVerificationError(false))
      api
        .post(common.verifyToken(), {
          client_id: +UUILocalStorageService.getClientId() || null,
          product: _env.isExpense() ? 'expense' : 'travel'
        })
        .then((res) => {
          if (res.data.status === true) {
            if (!res.data.company) {
              dispatch(
                setVerificationError('This company has not been migrate')
              )
              reject(res)
              return
            }
            if (_env.isProfilePage()) {
              UUILocalStorageService.setCompanyId(res.data.company)
              dispatch(setTokenResponse(res.data))
              dispatch(setVerificationError(false))
              if (_env.isExpense()) {
                UUILocalStorageService.setRole('submitter')
              } else {
                UUILocalStorageService.setRole('traveler')
              }
              resolve()
              return
            } else {
              for (
                let index = 0;
                index < res.data.applicable_role.length;
                index++
              ) {
                if (roleCanAccess.includes(res.data.applicable_role[index])) {
                  UUILocalStorageService.setCompanyId(res.data.company)
                  UUILocalStorageService.setRole(
                    res.data.applicable_role[index]
                  )
                  dispatch(setTokenResponse(res.data))
                  dispatch(setVerificationError(false))
                  resolve()
                  return
                }
              }
            }
          }
          dispatch(
            setVerificationError(
              `You don't have valid role or Permission to access this page`
            )
          )
          reject(res)
        })
        .catch((err) => {
          dispatch(
            setVerificationError(
              `You don't have valid role or Permission to access this page`
            )
          )
          reject(err)
        })
    })
  }
}
