import React, { useState, useEffect, useRef } from 'react'
import { AsyncPaginate, wrapMenuList } from 'react-select-async-paginate'
import { CustomLabelComponent } from '../Label/index.js'
import classes from './AysncCustomSelect.module.scss'
import clsx from 'clsx'
import { Typography, MenuItem } from '@material-ui/core'
import Tooltip from '@material-ui/core/Tooltip'

const CustomMenuList = (props) => {
  const [value, setValue] = useState(null)
  const scrollableDiv = useRef(null)
  useEffect(() => {
    if (
      props?.getValue &&
      props.getValue().length &&
      props.getValue()[0].value !== value
    ) {
      setValue(props.getValue()[0].value)
    }
  }, [props])

  useEffect(() => {
    const el = document.getElementById(
      `async-select-menu-item-${props.selectProps.id}-seleted-value-${value}`
    )
    if (el) {
      scrollableDiv.current.scrollTop = el.offsetTop
    }
  }, [value])
  return (
    <div>
      <div
        className={classes.menuBox}
        onScroll={props.selectProps.handleScrolledToBottom}
        ref={scrollableDiv}
      >
        {props.options.length ? (
          props.options.map((option, _i) => {
            return (
              <MenuItem
                key={_i}
                value={option.value}
                id={`async-select-menu-item-${props.selectProps.id}-seleted-value-${option.value}`}
                className={clsx(
                  classes.popOverText,
                  {
                    [classes.lightBackground]: value !== option.value
                  },
                  {
                    [classes.darkBackground]: value === option.value
                  }
                )}
                onClick={() => props.selectOption(option)}
              >
                <Typography variant='inherit'>{option.label}</Typography>
              </MenuItem>
            )
          })
        ) : (
          <MenuItem className={clsx(classes.dropdownLoader)}>
            <Typography>Loading...</Typography>
          </MenuItem>
        )}
      </div>
    </div>
  )
}

const AsyncCustomSelect = ({
  additional,
  label,
  secondaryLabel,
  description = null,
  title,
  required,
  value = '',
  name,
  customSelectHandler = () => {},
  inputType = false,
  loadOption,
  isError = false,
  errorMsg = '',
  readOnly = false,
  placeHolderCheck = false,
  hasInfoIcon = false,
  tooltipText = '',
  id = '',
  isMulti = false,
  disableText
}) => {
  const [localValue, onChange] = useState(value) // need for onchange async select , need to pass setstate
  const handleOnChange = (selectedOption) => {
    onChange(selectedOption)
    customSelectHandler(name, selectedOption)
  }

  useEffect(() => {
    onChange(value)
  }, [value])
  const MenuList = wrapMenuList(CustomMenuList)

  return (
    <div className={readOnly ? classes.readOnly : ''}>
      <CustomLabelComponent
        label={label}
        secondaryLabel={secondaryLabel}
        description={description}
        required={required}
        hasInfoIcon={hasInfoIcon}
        tooltipText={tooltipText}
      />
      <Tooltip
        placement='top-start'
        disableHoverListener={!readOnly}
        disableFocusListener={!readOnly}
        disableTouchListener={!readOnly}
        title={disableText}
      >
        <div className={classes.diableShowHide}>
          <AsyncPaginate
            id={id}
            value={localValue}
            loadOptions={loadOption}
            debounceTimeout={500}
            additional={additional}
            className={clsx(
              classes.selectContainer,
              {
                [classes.inputContainer]: inputType
              },
              { [classes.errorBorderCls]: isError },
              { [classes.placeholderColor]: placeHolderCheck },
              { [classes.multiContainer]: isMulti }
            )}
            name={name}
            onChange={handleOnChange}
            isDisabled={readOnly}
            isMulti={isMulti}
            components={{
              MenuList
            }}
          />
        </div>
      </Tooltip>
      {isError && (
        <Typography
          variant='body2'
          component='span'
          className={classes.errorTextCls}
        >
          {errorMsg}
        </Typography>
      )}
    </div>
  )
}

export default AsyncCustomSelect
