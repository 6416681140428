import React from 'react'

export const ChevronNext = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='8'
      height='16'
      fill='none'
      viewBox='0 0 8 16'
    >
      <path
        stroke='#9CA3AF'
        strokeLinecap='round'
        strokeLinejoin='round'
        d='M2 4l4 4-4 4'
      />
    </svg>
  )
}
