import {
  FETCH_USERPANEL_DATA,
  OPEN_DIALOG,
  CLOSE_DIALOG,
  SHOW_LOADER,
  STOP_LOADER
} from './types'
import api from '../../utils/Api'
import { userPanelUrl } from '../../utils/Urls'
import UUIToastService from '../../utils/ToastService'

export const userPanelShowLoader = () => {
  return {
    type: SHOW_LOADER
  }
}

export const userPanelStopLoader = () => {
  return {
    type: STOP_LOADER
  }
}

export const openDialog = (labels, child, data) => {
  return {
    type: OPEN_DIALOG,
    payload: { labels, child, data }
  }
}

export const closeDialog = () => {
  return {
    type: CLOSE_DIALOG
  }
}

export const getUserPanalOnPageChange = (tableName, length, page) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      api
        .get(userPanelUrl.getPaginationUnit(tableName, length, page))
        .then((res) => {
          dispatch({ type: FETCH_USERPANEL_DATA, payload: res.data })
          dispatch(userPanelStopLoader())
          resolve()
        })
        .catch(() => {
          dispatch(userPanelStopLoader())
        })
    })
  }
}
export const getUserPanalData = (tableName, length) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      api
        .get(userPanelUrl.getUnit(tableName, length))
        .then((res) => {
          dispatch({ type: FETCH_USERPANEL_DATA, payload: res.data })
          dispatch(userPanelStopLoader())
          resolve()
        })
        .catch(() => {
          dispatch(userPanelStopLoader())
        })
    })
  }
}

export const addRecord = (tableName, data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      api
        .post(userPanelUrl.addUnit(tableName), data)
        .then((res) => {
          dispatch(getUserPanalData(tableName, 5))
          dispatch(closeDialog())
          resolve(res)
        })
        .catch((err) => {
          dispatch(userPanelStopLoader())

          UUIToastService.warningNotify(
            err?.response?.data?.message || 'Opps, Something went wrong.'
          )
        })
    })
  }
}
export const deleteRecord = (tableName, id) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      api
        .post(userPanelUrl.deleteUnit(tableName), id)
        .then((res) => {
          dispatch(getUserPanalOnPageChange(tableName, 5, 1))
          dispatch(closeDialog())
          dispatch(userPanelStopLoader())
          resolve()
        })
        .catch((err) => {
          UUIToastService.warningNotify(
            err?.response?.data?.message || 'Opps, Something went wrong.'
          )
          dispatch(userPanelStopLoader())
        })
    })
  }
}

export const editRecord = (tableName, data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      api
        .post(userPanelUrl.editUnit(tableName), data)
        .then((res) => {
          dispatch(getUserPanalOnPageChange(tableName, 5, 1))
          dispatch(closeDialog())
          resolve()
        })
        .catch((err) => {
          dispatch(userPanelStopLoader())
          UUIToastService.warningNotify(
            err?.response?.data?.message || 'Opps, Something went wrong.'
          )
        })
    })
  }
}

export const moveRecord = (tableName, data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      api
        .post(userPanelUrl.moveUnit(tableName), data)
        .then((res) => {
          dispatch(getUserPanalData(tableName, 5))
          dispatch(closeDialog())
          resolve(res)
        })
        .catch((err) => {
          UUIToastService.warningNotify(
            err?.response?.data?.message || 'Opps, Something went wrong.'
          )
        })
    })
  }
}
