import React from 'react'

export const FilterIcon = (props) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={props.width}
      height={props.height}
      fill='none'
      viewBox='0 0 14 16'
    >
      <path
        fill='#718096'
        d='M8.583 15.333a.833.833 0 01-.5-.166L4.75 12.625a.833.833 0 01-.325-.667V9.233l-4-7.683A.833.833 0 011.167.333h11.666a.834.834 0 01.717.409.834.834 0 010 .833L9.383 9.25v5.25a.833.833 0 01-.458.75.834.834 0 01-.342.083zm-2.5-3.783l1.667 1.275V9.033c0-.127.028-.252.083-.366L11.417 2H2.533l3.442 6.667c.06.118.092.25.092.383l.016 2.5z'
      />
    </svg>
  )
}
