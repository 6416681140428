import {
  SHOW_LOADER,
  STOP_LOADER,
  SET_CUSTOM_FIELDS,
  REMOVE_CUSTOM_FIELD,
  UPDATE_CUSTOM_FIELD,
  SET_MODAL,
  RESET_EDIT_MODAL,
  SET_EDIT_MODAL
} from './types'

const initialState = {
  loading: false,
  data: [],
  isOpenModal: false,
  editLabel: {
    isEditMode: false,
    attribute_column: null,
    field_name: ''
  }
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_LOADER:
      return {
        ...state,
        loading: true
      }
    case STOP_LOADER:
      return {
        ...state,
        loading: false
      }

    case SET_MODAL: {
      return {
        ...state,
        isOpenModal: action.payload
      }
    }
    case RESET_EDIT_MODAL: {
      return {
        ...state,
        editLabel: {
          isEditMode: false,
          attribute_column: null,
          field_name: ''
        }
      }
    }

    case SET_EDIT_MODAL: {
      return {
        ...state,
        editLabel: {
          isEditMode: true,
          attribute_column: action.payload.attribute_column,
          field_name: action.payload.field_name
        }
      }
    }

    case SET_CUSTOM_FIELDS: {
      return {
        ...state,
        data: action.payload
      }
    }
    case REMOVE_CUSTOM_FIELD: {
      return {
        ...state,
        data: state.data.filter(
          (row) => row.attribute_column !== action.payload
        )
      }
    }

    case UPDATE_CUSTOM_FIELD: {
      return {
        ...state,
        data: state.data.map((row) => {
          if (row.attribute_column === action.payload.attribute_column) {
            row.field_name = action.payload.field_name
          }
          return row
        })
      }
    }
    default:
      return state
  }
}
export default reducer
