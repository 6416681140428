import React, { useEffect } from 'react'
import _env from '../../../../env'
import { Box } from '@material-ui/core'
import styles from './CustomFields.module.scss'
import PageHeader from './PageHeader'
import CustomFieldsTable from './CustomFieldsTable'
import SkeletonLoader from './SkeletonLoader'
import withStore from '../../../store/withStore'
import { useDispatch, useSelector } from 'react-redux'
import { fetchCustomFields, verifyAccessToken } from '../../../store'
import { showLoader, stopLoader } from '../../../store/customFields/actions'

const CustomFields = ({ config, isProfile }) => {
  _env.init(config, isProfile)
  const dispatch = useDispatch()
  const { isTokenVerified } = useSelector((state) => state.common)
  const { loading } = useSelector((state) => state.customFields)

  useEffect(() => {
    dispatch(showLoader())
    const roleCanAccess = [
      'travel_itilite_admin',
      'travel_admin',
      'expense_itilite_admin',
      'expense_admin'
    ]
    dispatch(verifyAccessToken(roleCanAccess))
  }, [])

  useEffect(() => {
    if (isTokenVerified) {
      dispatch(fetchCustomFields()).finally(() => {
        dispatch(stopLoader())
      })
    }
  }, [isTokenVerified])

  return (
    <Box component='div' className={styles.UUICustomFields}>
      <PageHeader />
      {loading ? <SkeletonLoader /> : <CustomFieldsTable />}
    </Box>
  )
}

export default withStore(CustomFields)
