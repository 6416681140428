import React from 'react'
import withStore from '../../../store/withStore'
import { useSelector } from 'react-redux'
import UserPanel from '../UserPanel'
import _env from '../../../../env'

const Entity = (props) => {
  const { userPanelData } = useSelector((state) => state.userPanel)
  const { config, isProfile } = props
  _env.init(config, isProfile)

  return (
    <React.Fragment>
      <UserPanel
        title='Entity'
        headingName='Entities'
        subHeading={`Add your organization's entity so that employees can be assigned to their entities`}
        tableName='entity'
        module='entity'
        data={userPanelData.results}
        config={config}
      />
    </React.Fragment>
  )
}

export default withStore(Entity)
