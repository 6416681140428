const env = {
  baseURL: 'https://userapi.iltech.in',
  paymentUrl: 'https://pay.iltech.in',
  _isExpense: false,
  _isTravel: false,
  _isProfilePage: false,

  setBaseUrl: function (_baseUrl) {
    this.baseURL = _baseUrl
  },
  setExpense: function (_val) {
    this._isExpense = _val
  },
  setTravel: function (_val) {
    this._isTravel = _val
  },
  setProfilePage: function (_val) {
    this._isProfilePage = _val
  },
  setPaymentUrl: function (_paymentUrl) {
    this.paymentUrl = _paymentUrl
  },
  init: function (config, isProfile) {
    this.setBaseUrl(config.env.baseURL)
    this.setExpense(config.env.isExpenseProduct)
    this.setTravel(config.env.isTravelProduct)
    this.setPaymentUrl(config.env.paymentUrl)

    this.setProfilePage(!!isProfile)
  },

  getBaseUrl: function () {
    return this.baseURL
  },
  isExpense: function () {
    return this._isExpense
  },
  isTravel: function () {
    return this._isTravel
  },
  isProfilePage: function () {
    return this._isProfilePage
  },
  getPaymentUrl: function () {
    return this.paymentUrl
  }
}

export default env
