import React from 'react'

export const NavigateNextIcon = (props) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={props.width}
      height={props.height}
      fill='none'
      viewBox='0 0 6 10'
    >
      <path
        stroke='#EC5D25'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='2'
        d='M5 1L1 5l4 4'
      />
    </svg>
  )
}
