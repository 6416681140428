import React, { createRef } from 'react'
import { CloudUploadIcon } from '../../utils/icons/CloudUploadIcon'
import Dropzone from 'react-dropzone'
import { Box, makeStyles, Tooltip, Typography } from '@material-ui/core'
import { CustomButton } from '../Button/CustomButton'
import styles from './FileUpload.module.scss'
const dropzoneRef = createRef()

const useStyles = makeStyles((theme) => ({
  customWidth: {
    maxWidth: 500
  }
}))

export const CustomDropzone = (props) => {
  const classes = useStyles()
  const {
    handleDrop = () => {},
    fileNames = [],
    removeSelectedFiles = () => {},
    accept = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
  } = props
  const openDialog = (e) => {
    // Note that the ref is set async,
    // so it might be null at some point
    e.preventDefault()
    e.stopPropagation()
    if (dropzoneRef.current) {
      dropzoneRef.current.open()
    }
  }

  return (
    <Dropzone onDrop={handleDrop} ref={dropzoneRef} accept={accept}>
      {({ getRootProps, getInputProps, acceptedFiles }) => (
        <Box
          {...getRootProps({
            className: styles.dragZoneContainer
          })}
        >
          <Box tabIndex='0' className={styles.MuiDropzoneArea}>
            <input {...getInputProps()} />
            <Box className={styles.MuiDropzoneAreaTextContainer}>
              <CloudUploadIcon />

              {fileNames.length === 0 && (
                <Typography className={styles.MuiDropzoneAreaText}>
                  Drag and drop your file here
                </Typography>
              )}
              {fileNames.length !== 0 && (
                <Tooltip
                  title={
                    <Typography className={styles.MuiToolTiptext}>
                      {fileNames[0]}
                    </Typography>
                  }
                  classes={{ tooltip: classes.customWidth }}
                  arrow
                >
                  <Typography className={styles.MuiDropzoneAreaText}>
                    {fileNames[0]}
                  </Typography>
                </Tooltip>
              )}

              <Box
                display='flex'
                justifyContent='center'
                alignItems='center'
                style={{ gap: '10px' }}
              >
                {fileNames.length === 0 && (
                  <React.Fragment>
                    <Typography className={styles.mildText}>or </Typography>

                    <CustomButton
                      buttonText='Select a file'
                      type='text'
                      onClickHandler={(e) => openDialog(e)}
                    />
                  </React.Fragment>
                )}
                {fileNames.length > 0 && (
                  <CustomButton
                    buttonText='Remove'
                    type='text'
                    onClickHandler={(e) => removeSelectedFiles(e)}
                  />
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </Dropzone>
  )
}
