import React from 'react'
import Flags from 'country-flag-icons/react/3x2'
import * as countryFlagIcons from 'country-flag-icons'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  flagIcon: {
    height: 19,
    width: 28,
    border: '1px solid gray'
  }
}))

const CountryCodeMenuItem = ({ name, code, dialCode }) => {
  const classes = useStyles()
  let icon = name
  if (countryFlagIcons.hasFlag(code)) {
    const FlagIcon = Flags[code]
    icon = <FlagIcon className={classes.flagIcon} />
  }

  return (
    <React.Fragment>
      <div
        style={{
          display: 'flex',
          fontFamily: 'Inter',
          fontSize: '14px',
          gap: '4px'
        }}
        data-country-code={name}
        data-dial-code={dialCode}
        className='flag'
      >
        {icon}
        <div style={{ display: 'flex', padding: '0px 2px' }} />
      </div>
    </React.Fragment>
  )
}

export default CountryCodeMenuItem
