import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Grid,
  Box,
  Typography,
  IconButton
} from '@material-ui/core'
import React from 'react'
import styles from './UserAddEdit.module.scss'
import clsx from 'clsx'
import { CustomLabelComponent } from '../../../UIComponents/Label'
import { ExpandMoreIcon } from '../../../utils/icons/ExpandMoreIcon'
import { ExpandLessIcon } from '../../../utils/icons/ExpandLessIcon'
import { GetInputType } from './helperFuntions'
import { CustomButton } from '../../../UIComponents/Button/CustomButton'
import { UUIInput } from '../../../UIComponents/Input'
import AddIcon from '../../../utils/icons/AddIcon'
import { useSelector } from 'react-redux'
import { CrossIcon } from '../../../utils/icons/CrossIcon'

export const AdditionalInfo = ({
  membershipInfoInput,
  onMemInfoInputChange,
  addMemInfoInput,
  travelPreferenceInfo,
  travelPreferenceOnChange = () => {},
  secureTravelInputInfo,
  onSecureTraveelerInfoInputChange = () => {},
  passportInfoInput,
  onPassportInfoInputChange = () => {},
  errorArray = [],
  errorMsgs = {},
  dateFormat,
  defaultExpand = false,
  handleRemoveMemFields = {},
  setAdditionalInfoFlag = () => {}
}) => {
  const handleChange = (panel) => (event, defaultExpand) => {
    setAdditionalInfoFlag(defaultExpand ? panel : false)
  }

  const { passportInfo, secureTravelInfo, travelPreference } = useSelector(
    (state) => state.userAddEdit.renderFields
  )
  const { maxMembershipInput } = useSelector((state) => state.userAddEdit)

  const dropdownList = useSelector((state) => state.userAddEdit.dropdownList)

  return (
    <Accordion
      expanded={defaultExpand === 'open'}
      onChange={handleChange('open')}
      className={styles.additionalInfoContainer}
    >
      <AccordionSummary
        expandIcon={
          defaultExpand ? (
            <ExpandLessIcon width='12px' height='7px' />
          ) : (
            <ExpandMoreIcon width='12px' height='7px' />
          )
        }
        aria-controls='panel1bh-content'
        id='panel1bh-header'
        className={clsx(styles.additionalInfoTitle, {
          [styles.expandIconAlign]: defaultExpand === 'open'
        })}
      >
        <Grid
          container
          className={clsx(styles.product_info, styles.sectionWrapper)}
        >
          <Grid item sm={12} md={4} className={styles.titleContainer}>
            <CustomLabelComponent
              label='Additional information'
              headerType
              description='Passport, Membership, Travel preferences...'
            />
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails className={styles.detailsContainer}>
        <Grid
          container
          className={clsx(styles.work_info, styles.sectionWrapper)}
        >
          <Grid item sm={12} md={4}>
            <CustomLabelComponent label='Passport information' headerType />
          </Grid>
          <Grid item sm={12} md={8} lg={8} xl={6}>
            <Grid
              container
              className={clsx(styles.formFieldsWrapper, styles.firstRowMargin)}
            >
              {passportInfo.map((p, i) => {
                if (!p.visibility) {
                  return null
                }
                return (
                  <Grid
                    item
                    sm={12}
                    md={12}
                    lg={p.type === 'datepicker' ? 2 : p.fullWidth ? 12 : 6}
                    xl={p.type === 'datepicker' ? 2 : p.fullWidth ? 12 : 6}
                    key={i}
                    className={clsx(styles.margin, {
                      [styles.minGridWidth]: p.type === 'datepicker'
                    })}
                  >
                    <GetInputType
                      _p={p}
                      inputState={passportInfoInput}
                      customHandler={onPassportInfoInputChange}
                      dropdownList={dropdownList}
                      errorArray={errorArray}
                      errorMsg={errorMsgs[p.name]}
                      dateFormat={dateFormat}
                    />
                  </Grid>
                )
              })}
            </Grid>
          </Grid>
        </Grid>
        <Divider className={styles.dividerColor} />
        <Grid
          container
          className={clsx(styles.personal_info, styles.sectionWrapper)}
        >
          <Grid item sm={12} md={4}>
            <CustomLabelComponent
              label='Membership information'
              headerType
              description={`You can add up to ${maxMembershipInput} membership details`}
            />
          </Grid>
          <Grid item sm={12} md={8} lg={8} xl={6}>
            <Grid container className={styles.formFieldsWrapper}>
              {membershipInfoInput.length === 0 && (
                <Box className={styles.emptyFieldContainer}>
                  <Typography className={styles.emptyFieldDescription}>
                    No membership details added yet
                  </Typography>
                  <Box
                    className={clsx(
                      styles.emptyFieldBtnContainer,
                      styles.rowGap
                    )}
                    display='flex'
                  >
                    <CustomButton
                      buttonText='Add airline'
                      type='text'
                      iconComponent={
                        <AddIcon width='10' height='10' color='#EC5D25' />
                      }
                      customClassName={styles.paddingTop}
                      onClickHandler={(e) => addMemInfoInput('flight')}
                    />
                    <CustomButton
                      buttonText='Add hotel'
                      type='text'
                      iconComponent={
                        <AddIcon width='10' height='10' color='#EC5D25' />
                      }
                      customClassName={styles.paddingTop}
                      onClickHandler={(e) => addMemInfoInput('hotel')}
                    />
                  </Box>
                </Box>
              )}
              {membershipInfoInput.length > 0 &&
                membershipInfoInput.map((val, index) => {
                  if (index >= maxMembershipInput) {
                    return null
                  }
                  return (
                    <React.Fragment key={index.toString()}>
                      <Grid item md={8} lg={6} xl={6}>
                        <UUIInput
                          label={`${
                            val.membership_type === 'flight'
                              ? 'Airline'
                              : 'Hotel'
                          } name`}
                          placeholder={`Enter ${
                            val.membership_type === 'flight'
                              ? 'airline'
                              : 'hotel'
                          } name`}
                          fullwidth={false}
                          defaultValue={val.name}
                          customHandler={(_name, _value) =>
                            onMemInfoInputChange(index, 'name', _value)
                          }
                        />
                      </Grid>
                      <Grid item md={8} lg={6} xl={6}>
                        <Box component='div' display='flex'>
                          <UUIInput
                            label='Membership number'
                            placeholder='Enter membership number'
                            fullwidth={false}
                            defaultValue={val.number}
                            customHandler={(_name, _value) =>
                              onMemInfoInputChange(index, 'number', _value)
                            }
                          />
                          <IconButton
                            style={{ marginTop: '30px' }}
                            disableRipple
                            onClick={() => handleRemoveMemFields(index)}
                            className={styles.backGrdColorOnHover}
                          >
                            <CrossIcon width='14' height='14' />
                          </IconButton>
                        </Box>
                      </Grid>
                    </React.Fragment>
                  )
                })}
              {membershipInfoInput.length < maxMembershipInput &&
                membershipInfoInput.length > 0 && (
                  <Grid item md={8} lg={8} xl={8}>
                    <Box
                      className={clsx(
                        styles.emptyFieldBtnContainer,
                        styles.rowGap
                      )}
                      display='flex'
                    >
                      <CustomButton
                        buttonText='Add airline'
                        type='text'
                        iconComponent={
                          <AddIcon width='10' height='10' color='#EC5D25' />
                        }
                        customClassName={styles.paddingTop}
                        onClickHandler={(e) => addMemInfoInput('flight')}
                      />
                      <CustomButton
                        buttonText='Add hotel'
                        type='text'
                        iconComponent={
                          <AddIcon width='10' height='10' color='#EC5D25' />
                        }
                        customClassName={styles.paddingTop}
                        onClickHandler={(e) => addMemInfoInput('hotel')}
                      />
                    </Box>
                  </Grid>
                )}
            </Grid>
          </Grid>
        </Grid>
        <Divider className={styles.dividerColor} />
        <Grid
          container
          className={clsx(styles.personal_info, styles.sectionWrapper)}
        >
          <Grid item sm={12} md={4}>
            <CustomLabelComponent label='Travel preferences' headerType />
          </Grid>
          <Grid item sm={12} md={8} lg={8} xl={6}>
            <Grid
              container
              className={clsx(styles.formFieldsWrapper, styles.firstRowMargin)}
            >
              {travelPreference.map((p, i) => {
                if (!p.visibility) {
                  return null
                }
                return (
                  <Grid
                    item
                    sm={12}
                    md={12}
                    lg={p.fullWidth ? 12 : 6}
                    xl={p.fullWidth ? 12 : 6}
                    key={i}
                    className={clsx(styles.margin)}
                  >
                    <GetInputType
                      _p={p}
                      inputState={travelPreferenceInfo}
                      customHandler={travelPreferenceOnChange}
                      dropdownList={dropdownList}
                      errorArray={errorArray}
                      errorMsg={errorMsgs[p.name]}
                    />
                  </Grid>
                )
              })}
            </Grid>
          </Grid>
        </Grid>
        <Divider className={styles.dividerColor} />
        <Grid
          container
          className={clsx(styles.personal_info, styles.sectionWrapper)}
        >
          <Grid item sm={12} md={4}>
            <CustomLabelComponent
              label='Secure traveler information'
              headerType
            />
          </Grid>
          <Grid item sm={12} md={8} lg={8} xl={6}>
            <Grid
              container
              className={clsx(styles.formFieldsWrapper, styles.firstRowMargin)}
            >
              {secureTravelInfo.map((p, i) => {
                if (!p.visibility) {
                  return null
                }
                return (
                  <Grid
                    item
                    sm={12}
                    md={12}
                    lg={p.fullWidth ? 12 : 6}
                    xl={p.fullWidth ? 12 : 6}
                    key={i}
                    className={clsx(styles.margin)}
                  >
                    <GetInputType
                      _p={p}
                      inputState={secureTravelInputInfo}
                      customHandler={onSecureTraveelerInfoInputChange}
                      errorArray={errorArray}
                      errorMsg={errorMsgs[p.name]}
                    />
                  </Grid>
                )
              })}
            </Grid>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  )
}
