import { combineReducers } from 'redux'
import usersReducer from './userAddEdit/reducer'
import userListReducer from './UserList/reducer'
import UserPanelReducer from './userPanel/reducer'
import commonReducer from './common/reducer'
import userBulkImportReducer from './UserBulkImport/reducer'
import customFieldsReducer from './customFields/reducer'

const rootReducer = combineReducers({
  userAddEdit: usersReducer,
  common: commonReducer,
  userList: userListReducer,
  userPanel: UserPanelReducer,
  userBulkImport: userBulkImportReducer,
  customFields: customFieldsReducer
})

export default rootReducer
