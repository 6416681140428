import React from 'react'

function AddIcon(props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={props.width}
      height={props.height}
      fill='none'
      viewBox='0 0 11 11'
    >
      <path
        fill={props.color}
        d='M5.5.003a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 01-2 0v-3h-3a1 1 0 010-2h3v-3a1 1 0 011-1z'
      />
    </svg>
  )
}

export default AddIcon
