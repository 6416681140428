export const ErrorMessages = {
  first_name: 'Please enter first name.',
  last_name: 'Please enter last name.',
  email: 'Please enter email.',
  contact_number: 'Please enter phone number.',
  dob: 'Please enter dob.',
  gender: 'Please enter gender.',
  emp_level: 'Please select employee level.',
  employee_id: 'Please select employee.',
  entity: 'Please select entity.',
  business_unit: 'Please select business unit.',
  department: 'Please select department.',
  designation: 'Please enter designation.',
  base_location: 'Please enter base location.',
  currency: 'Please enter currency.',
  flight_seat_preference: 'Please select flight seat preference.',
  meal_preference: 'Please enter meal preference.',
  trusted_traveller: 'Please select trusted traveller.',
  redress_number: 'Please enter redress number.',
  passport_issue_country: 'Please enter passport issue country.',
  passport_number: 'Please enter passport number.',
  nationality: 'Please enter nationality.',
  passport_issue_date: 'Please enter passport issue date.',
  passport_expiry_date: 'Please enter passport expiry date.',
  passport_photo: 'Please attach passport photo.',
  travel_docs: 'Please attach travel docs.',
  products: 'Please select atleast one product access.'
}
