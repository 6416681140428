import React, { Fragment } from 'react'
import { Box, Typography } from '@material-ui/core'
import './Userpanel.scss'
import { CustomButton } from '../../../UIComponents/Button/CustomButton'
import styles from '../UserList/UserList.module.scss'
import clsx from 'clsx'
import { useSelector } from 'react-redux'

export default function Header(props) {
  const { HeaderButton, name, headingName, subHeading } = props
  const { companyName, isILadmin } = useSelector((state) => state.common)

  return (
    <Fragment>
      <Box component='div' className={clsx(styles.pageHeader)}>
        <Box
          component='div'
          display='flex'
          justifyContent='space-between'
          alignItems='center'
        >
          <Box component='div'>
            <Typography
              variant='h3'
              className={clsx(styles.heading, styles.headingLarge)}
            >
              {isILadmin === 1 && `${companyName} - `} {headingName}
            </Typography>
          </Box>
          <Box
            component='div'
            className={styles.buttonWrapper}
            display='flex'
            justifyContent='space-between'
            alignItems='center'
          >
            <Box
              component='div'
              className='pageHeader--button-single button-right'
            >
              <CustomButton
                type='contained'
                buttonText={`+ Add ${name.toLowerCase()}`}
                onClickHandler={HeaderButton}
              />
            </Box>
          </Box>
        </Box>
        <Box
          component='div'
          display='flex'
          justifyContent='space-between'
          alignItems='center'
        />
        <Box component='div' className={styles.headingWrapper}>
          <Typography variant='body2' className={clsx(styles.description)}>
            {subHeading}
          </Typography>
        </Box>
      </Box>
    </Fragment>
  )
}
