import React, { Fragment, useEffect, useState } from 'react'
import { Box } from '@material-ui/core'
import './Userpanel.scss'
import { CustomDataTable } from '../../../UIComponents/CustomTable'
import { CustomButton } from '../../../UIComponents/Button/CustomButton'
import { useDispatch } from 'react-redux'
import { getUserPanalOnPageChange } from '../../../store'

export default function Listview({
  data,
  name,
  tableName,
  handleEditDialog,
  handleDeleteDialog
}) {
  const dispatch = useDispatch()
  const [page, setPage] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState(5)

  const handleChangePage = (e, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value)
    setPage(1)
  }

  useEffect(() => {
    dispatch(getUserPanalOnPageChange(tableName, rowsPerPage, page))
  }, [rowsPerPage, page])

  const columns = [
    {
      id: 'name',
      label: name,
      width: '45%',
      sortable: true,
      cell: (row) => <Box component='div'>{row.name}</Box>
    },

    {
      id: 'no_of_users',
      label: 'Number of users',
      width: '45%',
      cell: (row) => <Box component='div'>{row.no_of_users}</Box>
    },
    {
      id: 'actions',
      label: 'Actions',
      width: '10%',
      cell: (row) => (
        <Box display='flex'>
          <CustomButton
            buttonText='Edit'
            onClickHandler={() => handleEditDialog(row)}
          />
          {/* {row.no_of_users > 0 ? (
            <CustomButton
              buttonText='Delete'
              onClickHandler={() => handleDeleteDialog(row, true)}
            />
          ) : (
            <CustomButton
              buttonText='Delete'
              onClickHandler={() => handleDeleteDialog(row, false)}
            />
          )} */}
        </Box>
      ),
      style: { paddingLeft: '40px' }
    }
  ]

  return (
    <Fragment>
      <Box p={3}>
        <CustomDataTable
          hasFilter={false}
          isCheckboxTable={false}
          searchHide
          count={data.count}
          columns={columns}
          rows={data.results}
          page={page}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          rowsPerPage={rowsPerPage}
        />
      </Box>
    </Fragment>
  )
}
