import { UUILocalStorageService } from './LocalStorageService'
import api from './Api'
import { common } from './Urls'

export const UUILogout = (redirectUrl = null, callback) => {
  const _token = UUILocalStorageService.getRefreshToken()
  if (_token) {
    api
      .post(common.LOGOUT, { refresh: _token })
      .then((res) => {
        if (res.data.status === true) {
          if (typeof callback === 'function') {
            callback()
          }
        }
      })
      .finally(() => {
        if (redirectUrl) window.location.href = redirectUrl
      })
  } else {
    if (typeof callback === 'function') callback()
    if (redirectUrl) window.location.href = redirectUrl
  }
}
