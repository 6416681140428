import {
  FETCH_USERPANEL_DATA,
  SHOW_LOADER,
  STOP_LOADER,
  OPEN_DIALOG,
  CLOSE_DIALOG
} from './types'

const initialState = {
  dialogData: {
    open: false,
    labels: {
      headerText: '',
      cancelLabel: 'Cancel',
      submitLabel: '',
      moveLabel: 'Replace/Move Users',
      showMoveButton: false,
      submitHandler: () => {},
      width: '400px',
      module: 'department'
    },
    data: {
      id: '',
      name: '',
      code: '',
      no_of_users: '',
      company: '',
      created_at: '',
      updated_at: ''
    },
    children: 'Hi'
  },
  userPanelData: {
    count: 0,
    next: null,
    previous: null,
    results: []
  },
  loading: false
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_USERPANEL_DATA: {
      return {
        ...state,
        userPanelData: action.payload
      }
    }
    case OPEN_DIALOG: {
      return {
        ...state,
        dialogData: {
          open: false,
          labels: action.payload.labels,
          children: action.payload.child,
          data: action.payload.data
        }
      }
    }
    case CLOSE_DIALOG: {
      return {
        ...state,
        dialogData: {
          open: false,
          labels: {
            headerText: '',
            cancelLabel: 'Cancel',
            submitLabel: '',
            moveLabel: '',
            showMoveButton: false,
            submitHandler: () => {},
            width: '400px'
          },
          children: '',
          data: {
            id: '',
            name: '',
            code: '',
            no_of_users: '',
            company: '',
            created_at: '',
            updated_at: ''
          }
        }
      }
    }
    case SHOW_LOADER: {
      return {
        ...state,
        loading: true
      }
    }
    case STOP_LOADER: {
      return {
        ...state,
        loading: false
      }
    }
    default:
      return state
  }
}

export default reducer
