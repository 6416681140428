import {
  SET_USER_LIST_FIELDS,
  SET_ACTIVE_STATUS,
  SET_BULK_DEACTIVATED_USERS,
  SHOW_LOADER,
  STOP_LOADER
} from './types'

import api from '../../utils/Api'
import { userListUrl } from '../../utils/Urls'
import UUIToastService from '../../utils/ToastService'

export const setUserListFields = (payload) => {
  return {
    type: SET_USER_LIST_FIELDS,
    payload
  }
}

export const setUserActiveStatus = (payload) => {
  return {
    type: SET_ACTIVE_STATUS,
    payload
  }
}

export const setBulkDeactivatedUsers = (payload) => {
  return {
    type: SET_BULK_DEACTIVATED_USERS,
    payload
  }
}

export const showDataFetchingStatus = (payload) => {
  return {
    type: SHOW_LOADER,
    payload
  }
}

export const stopDataFetchingStatus = (payload) => {
  return {
    type: STOP_LOADER,
    payload
  }
}

export const fetchUsersList = (url) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      api
        .get(url + `&company_id=${getState().common.company}`)
        .then((res) => {
          dispatch(setUserListFields(res.data))
          dispatch(stopDataFetchingStatus())
          resolve()
        })
        .catch((err) => {
          reject(err)
          dispatch(stopDataFetchingStatus())
        })
        .finally(() => {
          dispatch(stopDataFetchingStatus())
        })
    })
  }
}

export const exportUsers = (payload) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      api
        .get(`${userListUrl.exportUsers()}`)
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}

export const deActivateUsers = (ids) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      api
        .post(`${userListUrl.bulkDeactivate()}`, {
          user_ids: ids
        })
        .then((res) => {
          dispatch(setBulkDeactivatedUsers(ids))
          UUIToastService.sNotify(
            ids.length + ' Users deactivated successfully'
          )
          resolve()
        })
        .catch((err) => {
          if (!err.response.data.status) {
            UUIToastService.warningNotify(err.response.data.message)
            reject(err)
            return
          }
          reject(err)
        })
    })
  }
}

export const updateActiveStatus = (activateVal, id) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      api
        .get(
          userListUrl.updateActiveStatus +
            'user_id=' +
            id +
            '&activate=' +
            activateVal
        )
        .then((res) => {
          dispatch(
            setUserActiveStatus({ is_active: activateVal !== 0, id: id })
          )
          UUIToastService.sNotify(res.data.message)
          resolve(res)
        })
        .catch((err) => {
          if (!err.response.data.status) {
            UUIToastService.warningNotify(err.response.data.message)
            reject(err)
            return
          }
          reject(err)
        })
    })
  }
}
