import React from 'react'

export const BoldInfoIcon = (props) => {
  const { color = '#6B7280' } = props
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={props.width}
      height={props.height}
      fill='none'
      viewBox='0 0 20 20'
    >
      <path
        fill={color}
        d='M11 5a1 1 0 11-2 0 1 1 0 012 0zM8 8a1 1 0 000 2h1v3H8a1 1 0 100 2h4a1 1 0 100-2h-1V9a1 1 0 00-1-1H8z'
      />
      <path
        fill={color}
        d='M0 10c0 5.522 4.477 10 10 10s10-4.478 10-10c0-5.523-4.477-10-10-10S0 4.477 0 10zm10-8a8 8 0 110 16 8 8 0 010-16z'
      />
    </svg>
  )
}
