/* eslint-disable dot-notation */
/* eslint-disable prettier/prettier */
import Axios from 'axios'
import { UUILocalStorageService } from './LocalStorageService'
import _env from '../../env'
import { common } from './Urls'

const resetToken = () => {
  // If refresh_token is expired remove the token and refresh the page.
  UUILocalStorageService.removeUUITokens()
  window.location.assign('/')
}

const api = Axios.create({
  baseURL: _env.getBaseUrl(), // here getBaseUrl function will return intinally defined value for baseUrl
  headers: {
    'Content-Type': 'application/json'
  }
})

api.interceptors.request.use(
  (config) => {
    const token = UUILocalStorageService.getAccessToken()
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token
      if (_env.isProfilePage()) {
        if (_env.isExpense()) {
          config.headers['role'] = 'submitter'
        } else if (_env.isTravel()) {
          config.headers['role'] = 'traveler'
        }
      } else {
        config.headers['role'] = UUILocalStorageService.getRole()
        config.headers['company-id'] = UUILocalStorageService.getCompanyId()
      }
    }
    config.baseURL = _env.getBaseUrl() // get updated value for baesUrl after getting config props from Travel and Expense product
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

api.interceptors.response.use(
  (res) => {
    return res
  },
  async (err) => {
    const originalConfig = err.config
    if (originalConfig.url !== common.refreshToken()) {
      // Access Token was expired
      if (err.response?.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true
        try {
          const rs = await Axios.post(
            `${_env.getBaseUrl()}/${common.refreshToken()}`,
            {
              refresh: UUILocalStorageService.getRefreshToken()
            }
          )
          const { access, refresh } = rs.data.token
          UUILocalStorageService.setAccessToken(access)
          UUILocalStorageService.setRefreshToken(refresh)
          return api({ ...originalConfig })
        } catch (_error) {
          resetToken()
          return Promise.reject(_error)
        }
      }
    }
    return Promise.reject(err)
  }
)

export default api
