import React from 'react'

export const CrossIcon = (props) => {
  const { color = '#6B7280' } = props
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={props.width}
      height={props.height}
      fill='none'
      viewBox='0 0 14 14'
    >
      <path
        fill={color}
        d='M.293.292a1 1 0 011.414 0L7 5.585 12.293.292a1 1 0 111.414 1.415L8.414 7l5.293 5.292a1 1 0 01-1.414 1.415L7 8.414l-5.293 5.293a1 1 0 01-1.414-1.415L5.586 7 .293 1.707a1 1 0 010-1.415z'
      />
    </svg>
  )
}
