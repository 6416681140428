import React from 'react'
import withStore from '../../../store/withStore'
import { useSelector } from 'react-redux'
import UserPanel from '../UserPanel'
import _env from '../../../../env'

const BusinessUnit = (props) => {
  const { userPanelData } = useSelector((state) => state.userPanel)
  const { config, isProfile } = props
  _env.init(config, isProfile)

  return (
    <React.Fragment>
      <UserPanel
        title='Business unit'
        headingName='Business units'
        subHeading={`Add your organization's business unit so that employees can be assigned to their business unit`}
        tableName='business_unit'
        module='business unit'
        data={userPanelData.results}
        config={config}
      />
    </React.Fragment>
  )
}

export default withStore(BusinessUnit)
