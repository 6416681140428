/* eslint-disable prefer-promise-reject-errors */
import {
  SHOW_LOADER,
  STOP_LOADER,
  SET_RENDER_FIELDS,
  SET_CUSTOM_FIELD,
  SET_TRAVEL_APPROVER_FIELD,
  SET_EXPENSE_APPROVER_FIELD,
  SET_EDITED_TRAVEL_APPROVER_FIELD,
  SET_EDITED_EXPENSE_APPROVER_FIELD,
  SET_COUNTRY_CODES,
  SET_STATIC_FIELDS_VISIBILITY,
  SET_PAYMENT_DETAILS
} from './types'

import api from '../../utils/Api'
import axios from 'axios'
import { userAddEditUrl, customFieldsUrls } from '../../utils/Urls'
import {
  formatCreateUserPayload,
  formatUpdateUserPayload
} from './payloadFormats'
import { approversOptions } from '../../UIComponents/Select/asyncLoadOptions'
import UUIToastService from '../../utils/ToastService'
import { UUILocalStorageService } from '../../utils/LocalStorageService'

export const showLoader = () => {
  return {
    type: SHOW_LOADER
  }
}

export const stopLoader = () => {
  return {
    type: STOP_LOADER
  }
}

export const setRenderFields = (payload) => {
  return {
    type: SET_RENDER_FIELDS,
    payload
  }
}

export const setCustomField = (payload) => {
  return {
    type: SET_CUSTOM_FIELD,
    payload
  }
}

export const setTravelApproverField = (payload) => {
  return {
    type: SET_TRAVEL_APPROVER_FIELD,
    payload
  }
}

export const setExpenseApproverField = (payload) => {
  return {
    type: SET_EXPENSE_APPROVER_FIELD,
    payload
  }
}

export const setEditedTravelApproverField = (payload) => {
  return {
    type: SET_EDITED_TRAVEL_APPROVER_FIELD,
    payload
  }
}

export const setEditedExpenseApproverField = (payload) => {
  return {
    type: SET_EDITED_EXPENSE_APPROVER_FIELD,
    payload
  }
}

export const setCountryCodes = (payload) => {
  return {
    type: SET_COUNTRY_CODES,
    payload
  }
}

export const setStaticFieldsVisibility = (payload) => {
  return {
    type: SET_STATIC_FIELDS_VISIBILITY,
    payload
  }
}

export const setPaymentDetails = (payload) => {
  return {
    type: SET_PAYMENT_DETAILS,
    payload
  }
}

export const fetchRenderFileds = () => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      api
        .get(userAddEditUrl.renderFields())
        .then((res) => {
          if (res.data.status === true) {
            dispatch(setRenderFields(res.data))
            resolve()
          } else {
            UUIToastService.warningNotify(
              res?.data?.message || 'Opps, Something went wrong.'
            )
            dispatch(stopLoader())
            reject()
          }
        })
        .catch((err) => {
          reject(err)
        })
        .finally(() => {})
    })
  }
}

export const fetchCountryCodes = () => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      api
        .get(userAddEditUrl.countryCode())
        .then((res) => {
          dispatch(setCountryCodes(res.data.country_code_details))
          resolve('success')
        })
        .catch((err) => {
          reject(err)
        })
        .finally(() => {})
    })
  }
}

export const addCustomFieldLabel = (payload) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      api
        .post(customFieldsUrls.customFields(), payload)
        .then((res) => {
          dispatch(setCustomField(res.data.result))
          resolve()
        })
        .catch((err) => {
          UUIToastService.warningNotify(
            err?.response?.data?.message || 'Opps, Something went wrong.'
          )
          reject(err)
        })
    })
  }
}

export const addTravelApproverField = () => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const _travelApprovers =
        getState().userAddEdit.renderFields.travelApprovers

      let sNo = _travelApprovers.length + 1
      let label = `Travel person ${sNo}`

      _travelApprovers.forEach((approver) => {
        if (approver.label === label) {
          sNo++
          label = `Travel person ${sNo}`
        }
      })

      api
        .post(userAddEditUrl.approverField(), {
          product: 'Travel',
          label: label
        })
        .then((res) => {
          dispatch(
            setTravelApproverField({
              id: sNo,
              label: label,
              name: `sequence-${res.data.sequence}`,
              required: false,
              type: 'async_select',
              fullWidth: false,
              visibility: true,
              sequence: res.data.sequence,
              placeholder: 'Select approver',
              product: 'travel',
              loadOption: approversOptions
            })
          )
          resolve()
        })
        .catch((err) => {
          if (err?.response?.data?.message) {
            UUIToastService.warningNotify(err.response.data.message)
          }
          reject(err)
        })
    })
  }
}

export const addExpenseApproverField = () => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const _expenseApprovers =
        getState().userAddEdit.renderFields.expenseApprovers

      let sNo = _expenseApprovers.length + 1
      let label = `Expense person ${sNo}`

      _expenseApprovers.forEach((approver) => {
        if (approver.label === label) {
          sNo++
          label = `Expense person ${sNo}`
        }
      })
      api
        .post(userAddEditUrl.approverField(), {
          product: 'Expense',
          label: label
        })
        .then((res) => {
          dispatch(
            setExpenseApproverField({
              id: sNo,
              label: label,
              name: `sequence-${res.data.sequence}`,
              required: false,
              type: 'async_select',
              fullWidth: false,
              visibility: true,
              sequence: res.data.sequence,
              placeholder: 'Select approver',
              product: 'expense',
              loadOption: approversOptions
            })
          )
          resolve()
        })
        .catch((err) => {
          if (err?.response?.data?.message) {
            UUIToastService.warningNotify(err.response.data.message)
          }
          reject(err)
        })
    })
  }
}

export const editApproverLabel = (payload) => {
  payload.approvers = payload.approvers.filter((approver) => approver.label)
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      api
        .put(userAddEditUrl.approverField(), payload)
        .then((res) => {
          dispatch(
            payload.product === 'travel'
              ? setEditedTravelApproverField(payload)
              : setEditedExpenseApproverField(payload)
          )
          resolve()
        })
        .catch((err) => {
          if (err?.response?.data?.message) {
            UUIToastService.warningNotify(err.response.data.message)
          }
          reject(err)
        })
    })
  }
}

export const createUser = (payload) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      api
        .post(userAddEditUrl.createUser(), formatCreateUserPayload(payload))
        .then((res) => {
          if (res.data.status === true) {
            UUIToastService.sNotify(res.data.message)
            resolve(res.data)
          } else {
            if (res.data.errors && typeof res.data.errors === 'object') {
              reject(res.data.errors)
              return
            } else if (!Object.keys(res.data.errors).length) {
              UUIToastService.warningNotify(res.data.message)
            }
            reject()
          }
        })
        .catch((err) => {
          const error = 'Opps, Something went error.'
          if (err?.response?.data) {
            if (
              err.response.data.errors &&
              typeof err.response.data.errors === 'object' &&
              Object.keys(err.response.data.errors).length
            ) {
              reject(err.response.data.errors)
              return
            } else if (err.response.data.message) {
              reject(err.response.data.message)
              return
            }
          }
          reject(error)
        })
    })
  }
}

export const uploadSingleDocumnet = (payload) => {
  return (dispatch) => {
    const itemTypes = {
      passport: 'passport',
      travel_docs: 'travel_docs'
    }
    return new Promise((resolve, reject) => {
      api
        .get(
          `${userAddEditUrl.preSignedUrl()}?action=upload&item_type=${
            payload.itemType
          }&file_name=${payload.fileName}`
        )
        .then((res) => {
          const resolveResponse = {
            file_type: itemTypes[payload.itemType],
            file_path: res.data.presigned_url.fields.key,
            user_file_name: payload.fileName,
            upload: 'new',
            returned_file_name: res.data.presigned_url.file_name
          }
          uploadFileToS3(res.data.presigned_url, payload.file)
            .then((res) => {
              resolve(resolveResponse)
            })
            .catch((err) => {
              reject(err)
            })
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}

// here preSignedPostData is the data returned from our backend upload doc API
const uploadFileToS3 = (presignedPostData, file) => {
  return new Promise((resolve, reject) => {
    // create a form obj
    const formData = new FormData()

    // append the fields in presignedPostData in formData
    Object.keys(presignedPostData.fields).forEach((key) => {
      formData.append(key, presignedPostData.fields[key])
    })

    // append the file
    formData.append('file', file)

    // post the data on the s3 url
    axios
      .post(presignedPostData.url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(function (response) {
        resolve(response)
      })
      .catch(function (error) {
        reject(error)
      })
  })
}

export const fetchUserDetails = (payload) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      api
        .get(userAddEditUrl.editUser(payload.userId))
        .then((res) => {
          if (res.data.status) {
            resolve(res.data.result)
          } else {
            reject(res)
          }
        })
        .catch((err) => {
          reject(err)
        })
        .finally(() => {})
    })
  }
}

export const updateUser = (payload, userId, inputChangeLog) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      api
        .put(
          userAddEditUrl.editUser(userId),
          formatUpdateUserPayload(payload, inputChangeLog)
        )
        .then((res) => {
          if (res.data.status === true) {
            UUIToastService.sNotify(res.data.message)
            resolve(res.data)
          } else {
            if (!Object.keys(res.data.errors).length) {
              UUIToastService.warningNotify(res.data.message)
            }
            reject(res.data.errors)
          }
        })
        .catch((err) => {
          const error = 'Opps, Something went error.'
          if (err?.response?.data) {
            if (
              err.response.data.errors &&
              typeof err.response.data.errors === 'object' &&
              Object.keys(err.response.data.errors).length
            ) {
              reject(err.response.data.errors)
              return
            } else if (err.response.data.message) {
              reject(err.response.data.message)
              return
            }
          }
          reject(error)
        })
    })
  }
}

export const downloadSingleDocumnet = (payload) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      api
        .get(
          `${userAddEditUrl.preSignedUrl()}?action=download&traveler_type=staff&item_type=${
            payload.itemType
          }&file_name=${payload.fileName}&user_id=${payload.userId}`
        )
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}

export const deletePaymentCard = (payload) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      dispatch(showLoader())
      axios
        .delete(
          userAddEditUrl.deletePaymentCard(payload.pmId, payload.vendorId),
          {
            headers: {
              Authorization: 'Bearer ' + UUILocalStorageService.getAccessToken()
            }
          }
        )
        .then((res) => {
          if (res.data.status) {
            resolve(res.data.result)
            const _paymentDetails = getState().userAddEdit.paymentDetails
            _paymentDetails.cards = _paymentDetails.cards.filter(
              (card) => card.id !== payload.pmId
            )
            dispatch(setPaymentDetails(_paymentDetails))
          } else {
            UUIToastService.warningNotify(
              res?.data?.message || 'Opps, Something went wrong.'
            )
            reject(res)
          }
        })
        .catch((err) => {
          UUIToastService.warningNotify(
            err?.response?.data?.message || 'Opps, Something went wrong.'
          )
          reject(err)
        })
        .finally(() => {
          dispatch(stopLoader())
        })
    })
  }
}

export const setDefaultPaymentCard = (payload) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      dispatch(showLoader())
      axios
        .put(userAddEditUrl.setDefaultCard(), payload, {
          headers: {
            Authorization: 'Bearer ' + UUILocalStorageService.getAccessToken()
          }
        })
        .then((res) => {
          if (res.data.status) {
            resolve(res.data.result)
            const _paymentDetails = getState().userAddEdit.paymentDetails
            _paymentDetails.cards = _paymentDetails.cards.map((card) => {
              if (card.id === payload.method_id) {
                card.metadata.default_card = '1'
              } else {
                card.metadata.default_card = '0'
              }
              return card
            })
            dispatch(setPaymentDetails(_paymentDetails))
          } else {
            UUIToastService.warningNotify(
              res?.data?.message || 'Opps, Something went wrong.'
            )
            reject(res)
          }
        })
        .catch((err) => {
          UUIToastService.warningNotify(
            err?.response?.data?.message || 'Opps, Something went wrong.'
          )
          reject(err)
        })
        .finally(() => {
          dispatch(stopLoader())
        })
    })
  }
}
