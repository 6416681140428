export const SHOW_LOADER = 'SHOW_LOADER'
export const STOP_LOADER = 'STOP_LOADER'
export const SET_RENDER_FIELDS = 'SET_RENDER_FIELDS'
export const SET_CUSTOM_FIELD = 'SET_CUSTOM_FIELD'
export const SET_TRAVEL_APPROVER_FIELD = 'SET_TRAVEL_APPROVER_FIELD'
export const SET_EXPENSE_APPROVER_FIELD = 'SET_EXPENSE_APPROVER_FIELD'
export const SET_EDITED_TRAVEL_APPROVER_FIELD =
  'SET_EDITED_TRAVEL_APPROVER_FIELD'
export const SET_EDITED_EXPENSE_APPROVER_FIELD =
  'SET_EDITED_EXPENSE_APPROVER_FIELD'
export const SET_COUNTRY_CODES = 'SET_COUNTRY_CODES'
export const SET_STATIC_FIELDS_VISIBILITY = 'SET_STATIC_FIELDS_VISIBILITY'
export const SET_PAYMENT_DETAILS = 'SET_PAYMENT_DETAILS'
