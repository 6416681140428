const env = {
  baseURL: '',
  paymentUrl: '',
  serviceBaseURL: 'https://stageservice.iltech.in',
  cardsapiURL:'',
  _isExpense: false,
  _isTravel: false,
  _isProfilePage: false,

  setBaseUrl: function (_baseUrl) {
    this.baseURL = _baseUrl
  },
  setExpense: function (_val) {
    this._isExpense = _val
  },
  setTravel: function (_val) {
    this._isTravel = _val
  },
  setProfilePage: function (_val) {
    this._isProfilePage = _val
  },
  setPaymentUrl: function (_paymentUrl) {
    this.paymentUrl = _paymentUrl
  },
  setServiceBaseUrl: function (_serviceBaseURL) {
    this.serviceBaseURL = _serviceBaseURL
  },
  setCardsapiURL: function (_cardsapiURL) {
    this.cardsapiURL = _cardsapiURL
  },
  init: function (config, isProfile) {
    this.setBaseUrl(config.env.baseURL)
    this.setExpense(config.env.isExpenseProduct)
    this.setTravel(config.env.isTravelProduct)
    this.setPaymentUrl(config.env.paymentUrl)
    this.setServiceBaseUrl(config.env.serviceBaseURL)
    this.setCardsapiURL(config.env.cardsapiURL)
    this.setProfilePage(!!isProfile)
  },

  getBaseUrl: function () {
    return this.baseURL
  },
  isExpense: function () {
    return this._isExpense
  },
  isTravel: function () {
    return this._isTravel
  },
  isProfilePage: function () {
    return this._isProfilePage
  },
  getPaymentUrl: function () {
    return this.paymentUrl
  },
  getServiceBaseUrl: function () {
    return this.serviceBaseURL
  },
  getCardsapiURL: function () {
    return this.cardsapiURL
  }
}

export default env
