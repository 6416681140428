import React from 'react'
import withStore from '../../../store/withStore'
import { useSelector } from 'react-redux'
import UserPanel from '../UserPanel'
import _env from '../../../../env'

const EmployeeLevel = (props) => {
  const { userPanelData } = useSelector((state) => state.userPanel)
  const { config, isProfile } = props
  _env.init(config, isProfile)
  return (
    <React.Fragment>
      <UserPanel
        title='Employee level'
        headingName='Employee level'
        subHeading={`Add your organization's different employee levels so that employees can be assigned to their levels`}
        tableName='employee_level'
        module='employee level'
        data={userPanelData.results}
        config={config}
      />
    </React.Fragment>
  )
}

export default withStore(EmployeeLevel)
