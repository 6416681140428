import * as React from 'react'
import { withStyle, BaseProvider, LightTheme } from 'baseui'
import styles from './BaseWebDatePicker.module.scss'
import { Datepicker, formatDate } from 'baseui/datepicker'
import { Box, Typography } from '@material-ui/core'
import CalendarIcon from '../../utils/icons/CalendarIcon'
import { StyledInput, StyledRoot, StyledStartEnhancer } from 'baseui/input'
import { Provider as StyletronProvider } from 'styletron-react'
import { Client as Styletron } from 'styletron-engine-atomic'
import moment from 'moment'
import Tooltip from '@material-ui/core/Tooltip'

const engine = new Styletron()

export const BaseWebDatePicker = (props) => {
  const {
    dateValue = '',
    displayDateType = 'dd MMM yyyy',
    placeholder = 'DD MMM YYYY',
    formatType = 'dd MM yyyy',
    customHandler,
    disabled = false,
    hasIcon = false,
    size = 'normal',
    canReFormat = false,
    name = '',
    maxDate,
    minDate,
    isError = false,
    errorMsg = '',
    id = '',
    required = false,
    disableText
  } = props

  const RootWithStyle = withStyle(StyledRoot, (rootProps) => {
    const { $disabled, $isFocused } = rootProps
    const borderColor = isError
      ? 'red'
      : $isFocused
      ? '#6B7280'
      : $disabled
      ? '#D1D5DB'
      : '#D1D5DB'
    const boxShadow = $isFocused ? '0px 0px 0px 4px #FFEDD5' : 'none'
    const inputSize =
      size === 'small' ? (size === 'small' ? '150px' : '150px') : '316px'
    return {
      width: inputSize,
      height: '40px',
      maxWidth: '100%',
      borderBottomLeftRadius: '6px',
      borderTopLeftRadius: '6px',
      borderTopRightRadius: '6px',
      borderBottomRightRadius: '6px',
      paddingLeft: '0px',
      boxShadow: boxShadow,
      backgroundColor: 'white',
      borderLeftColor: `${borderColor}`,
      borderRightColor: `${borderColor}`,
      borderTopColor: `${borderColor}`,
      borderBottomColor: `${borderColor}`,
      borderLeftWidth: '1px',
      borderTopWidth: '1px',
      borderBottomWidth: '1px',
      borderRightWidth: '1px',
      opacity: 1,
      transform: 'none',
      margin: '0px',
      justifyContent: 'unset',
      ':hover': {
        borderColor: '#6B7280'
      }
    }
  })

  const InputWithStyle = withStyle(StyledInput, (inputProps) => {
    const inputSize =
      size === 'small' ? (size === 'normal' ? '146px' : '146px') : '316px'
    return {
      width: inputSize,
      backgroundColor: disabled ? '#F2F2F2' : 'white',
      fontFamily: 'Inter ,sans-serif',
      height: '40px',
      textAlign: 'inherit'
      // paddingLeft: '0px'
    }
  })

  const InputStartEnhancerWithStyle = withStyle(
    StyledStartEnhancer,
    (props) => {
      return {
        backgroundColor: 'white',
        position: 'relative',
        margin: '0px'
      }
    }
  )

  const arrowBtnOverrides = ({ $theme }) => {
    return {
      color: 'grey',
      ':focus': {
        backgroundColor: 'white',
        boxShadow: '0px 0px 0px 4px #FFEDD5',
        border: '1px solid #EC5D25',
        borderLeftRadius: '6px',
        borderRightRadius: '6px',
        borderTopRadius: '6px',
        borderBottomRadius: '6px'
      }
    }
  }

  const [value, setValue] = React.useState(
    dateValue ? [new Date(dateValue)] : []
  ) // we need it , since date datatype should convert to array
  const [formatString, setFormatString] = React.useState(displayDateType)
  const [isCalendarOpen, setCalendarDisplayFlag] = React.useState(false)
  // const [autoFocusCalendar, setAutoFocusCalendar] = React.useState(false)

  React.useEffect(() => {
    // update local state whenever root lvl state get changed
    setValue(dateValue ? [new Date(dateValue)] : [])
  }, [dateValue])

  const formatFocusDateValue = (e) => {
    setFormatString(formatType)
    if (value.length > 0) {
      var val = formatDate(value[0], formatType)
      setValue([new Date(val)])
    }
    setCalendarDisplayFlag(true)
  }

  const dateOnChangeHandler = (date) => {
    setValue(date ? [new Date(date)] : [])
    setFormatString(displayDateType)
    if (date) {
      customHandler(name, formatDate(date, displayDateType))
    } else {
      customHandler(name, '')
    }
    if (canReFormat) {
      setFormatString(displayDateType)
      setCalendarDisplayFlag(false)
      // setAutoFocusCalendar(false)
      // customHandler(date);
    }
  }

  const handleKeyDownStroke = (e) => {
    if (!isCalendarOpen && e.keyCode === 40) {
      setCalendarDisplayFlag(true)
    } else if (e.key === 'ArrowDown') {
      e.preventDefault()
      // setAutoFocusCalendar(true)
    } else if (e.keyCode === 9) {
      // setAutoFocusCalendar(false)
    } else if (e.keyCode === 13) {
      setCalendarDisplayFlag(false)
      // setAutoFocusCalendar(false)
    }
  }

  const handleDatepickerClose = (e) => {
    e.preventDefault()
    let isError = false
    let _errorMsg = ''
    let inpvalue = e.target.value
    if (!inpvalue && !required) return
    const isCorrectValue = moment(
      inpvalue,
      displayDateType.toUpperCase(),
      true
    ).isValid()
    if (!isCorrectValue) {
      isError = true
      inpvalue = ''
      _errorMsg = 'Please enter the valid date of birth'
      customHandler(name, inpvalue, isError, _errorMsg)
    } else {
      customHandler(name, inpvalue, isError, _errorMsg)
    }
  }

  const inputprops = canReFormat && {
    canReFormatonFocus: formatFocusDateValue,
    onKeyDown: handleKeyDownStroke,
    onBlur: handleDatepickerClose
  }

  const popoverProps = canReFormat && {
    isOpen: isCalendarOpen,
    onEsc: handleDatepickerClose,
    onClickOutside: handleDatepickerClose
  }

  React.useEffect(() => {})

  return (
    <Box
      className={styles.datePickerParentContainer}
      style={{
        width:
          size === 'small' ? (size === 'normal' ? '146px' : '146px') : '316px'
      }}
      id={id}
    >
      <StyletronProvider value={engine}>
        <BaseProvider theme={LightTheme}>
          <Tooltip
            placement='top-start'
            disableHoverListener={!disabled}
            disableFocusListener={!disabled}
            disableTouchListener={!disabled}
            title={disableText}
          >
            <span>
              <Datepicker
                value={value}
                // autoFocusCalendar={autoFocusCalendar}
                onChange={({ date }) => dateOnChangeHandler(date)}
                formatString={formatString}
                placeholder={placeholder}
                disabled={disabled}
                error={isError}
                maxDate={new Date(maxDate)}
                minDate={new Date(minDate)}
                overrides={{
                  Input: {
                    props: {
                      startEnhancer: hasIcon && (
                        <CalendarIcon width='22' height='22' />
                      ),
                      inputprops,
                      onBlur: (e) => handleDatepickerClose(e),
                      overrides: {
                        Root: {
                          component: RootWithStyle
                        },
                        Input: {
                          component: InputWithStyle
                        },
                        StartEnhancer: {
                          component: InputStartEnhancerWithStyle
                        }
                      }
                    }
                  },
                  Popover: {
                    props: {
                      popoverProps,
                      overrides: {
                        Body: {
                          style: ({ $theme }) => ({
                            transform: 'none',
                            zIndex: '9999'
                          })
                        }
                      }
                    }
                  },
                  MonthYearSelectStatefulMenu: {
                    props: {
                      overrides: {
                        Option: {
                          props: {
                            overrides: {
                              ListItem: {
                                style: ({ $theme, $isHighlighted }) => ({
                                  fontFamily: 'Inter ,sans-serif',
                                  ':hover': {
                                    boxShadow: '0px 0px 0px 4px #FFEDD5',
                                    border: '1px solid #EC5D25',
                                    borderLeftRadius: '6px',
                                    borderRightRadius: '6px',
                                    borderTopRadius: '6px',
                                    borderBottomRadius: '6px'
                                  },
                                  ':focus': {
                                    boxShadow: '0px 0px 0px 4px #FFEDD5',
                                    border: '1px solid #EC5D25',
                                    borderLeftRadius: '6px',
                                    borderRightRadius: '6px',
                                    borderTopRadius: '6px',
                                    borderBottomRadius: '6px'
                                  },
                                  boxShadow: $isHighlighted
                                    ? '0px 0px 0px 4px #FFEDD5'
                                    : 'none',
                                  borderLeftRadius: $isHighlighted
                                    ? '6px'
                                    : '0px',
                                  borderRightRadius: $isHighlighted
                                    ? '6px'
                                    : '0px',
                                  borderTopRadius: $isHighlighted
                                    ? '6px'
                                    : '0px',
                                  borderBottomRadius: $isHighlighted
                                    ? '6px'
                                    : '0px',
                                  border: $isHighlighted
                                    ? '1px solid #EC5D25'
                                    : 'none'
                                })
                              }
                            }
                          }
                        },
                        List: {
                          style: ({ $theme }) => ({
                            ':focus': {
                              boxShadow: 'none',
                              outline: 'none',
                              // border : '1px solid #EC5D25',
                              borderLeftRadius: '6px',
                              borderRightRadius: '6px',
                              borderTopRadius: '6px',
                              borderBottomRadius: '6px'
                            }
                          })
                        }
                      }
                    }
                  },
                  Root: {
                    style: ($theme) => ({
                      width: '316px',
                      maxWidth: '100%',
                      borderLeftRadius: '6px',
                      borderRightRadius: '6px',
                      borderTopRadius: '6px',
                      borderBottomRadius: '6px',
                      border: '1px solid #D1D5DB'
                    })
                  },
                  CalendarHeader: {
                    style: ({ $theme }) => ({
                      fontFamily: 'Inter ,sans-serif',
                      backgroundColor: 'white',
                      width: '316px',
                      maxWidth: '100%',
                      display: 'flex',
                      justifyContent: 'space-evenly',
                      paddingLeft: '0px',
                      paddingRight: '0px'
                    })
                  },
                  MonthHeader: {
                    style: ({ $theme }) => ({
                      fontFamily: 'Inter ,sans-serif',
                      backgroundColor: 'white',
                      width: '316px',
                      maxWidth: '100%'
                    })
                  },
                  WeekdayHeader: {
                    style: ({ $theme }) => ({
                      fontFamily: 'Inter ,sans-serif',
                      backgroundColor: 'white',
                      fontSize: '14px',
                      width: '40px'
                    })
                  },
                  MonthYearSelectIconContainer: {
                    style: ({ $theme }) => ({
                      color: 'lightgray',
                      marginLeft: '0px'
                    })
                  },
                  CalendarContainer: {
                    style: ({ $theme }) => ({
                      fontFamily: 'Inter ,sans-serif'
                    })
                  },
                  MonthYearSelectButton: {
                    style: ({ $theme }) => ({
                      fontFamily: 'Inter ,sans-serif',
                      fontSize: '16px',
                      ':focus': {
                        backgroundColor: 'white',
                        outline: 'none',
                        boxShadow: '0px 0px 0px 4px #FFEDD5',
                        border: '1px solid #EC5D25',
                        borderLeftRadius: '6px',
                        borderRightRadius: '6px',
                        borderTopRadius: '6px',
                        borderBottomRadius: '6px'
                      }
                    })
                  },
                  MonthYearSelectPopover: {
                    props: {
                      overrides: {
                        Body: {
                          style: ({ $theme }) => ({
                            zIndex: 9999
                          })
                        }
                      }
                    }
                  },
                  PrevButton: {
                    // component : prevIconComponent,
                    style: arrowBtnOverrides
                  },
                  PrevButtonIcon: {
                    props: {
                      overrides: {
                        Svg: {
                          style: ({ $theme }) => ({
                            width: '30px',
                            height: '30px'
                          })
                        }
                      }
                    }
                  },
                  NextButton: {
                    // component : nextIconComponent,
                    style: arrowBtnOverrides
                  },
                  NextButtonIcon: {
                    props: {
                      overrides: {
                        Svg: {
                          style: ({ $theme }) => ({
                            width: '27px',
                            height: '27px'
                          })
                        }
                      }
                    }
                  },
                  Day: {
                    style: ({
                      $theme,
                      $selected,
                      $isHovered,
                      $isHighlighted
                    }) => ({
                      width: '40px',
                      height: '40px',
                      fontSize: '14px',
                      fontFamily: 'Inter ,sans-serif',
                      // color: $selected
                      //   ? $theme.colors.white
                      //   : $theme.colors.calendarForeground,
                      ':after': {
                        backgroundColor: $selected
                          ? $isHovered || $isHighlighted
                            ? '#EC5D25'
                            : '#EC5D25'
                          : $isHovered || $isHighlighted
                          ? 'transparent'
                          : 'transparent',
                        boxShadow: $selected
                          ? $isHovered || $isHighlighted
                            ? '0px 0px 0px 4px #FFEDD5'
                            : 'none'
                          : $isHovered || $isHighlighted
                          ? '0px 0px 0px 4px #FFEDD5'
                          : 'none',
                        // backgroundColor: 'none',
                        height: '40px',
                        top: '4px',
                        borderLeftColor: $selected
                          ? $isHovered || $isHighlighted
                            ? '#EC5D25'
                            : '#EC5D25'
                          : $isHovered || $isHighlighted
                          ? '#EC5D25'
                          : 'transparent',
                        borderRightColor: $selected
                          ? $isHovered || $isHighlighted
                            ? '#EC5D25'
                            : '#EC5D25'
                          : $isHovered || $isHighlighted
                          ? '#EC5D25'
                          : 'transparent',
                        borderTopColor: $selected
                          ? $isHovered || $isHighlighted
                            ? '#EC5D25'
                            : '#EC5D25'
                          : $isHovered || $isHighlighted
                          ? '#EC5D25'
                          : 'transparent',
                        borderBottomColor: $selected
                          ? $isHovered || $isHighlighted
                            ? '#EC5D25'
                            : '#EC5D25'
                          : $isHovered || $isHighlighted
                          ? '#EC5D25'
                          : 'transparent'
                      }
                    })
                  }
                }}
              />
            </span>
          </Tooltip>
        </BaseProvider>
      </StyletronProvider>
      {isError && (
        <Typography
          variant='body2'
          component='span'
          className={styles.errorTextCls}
        >
          {errorMsg}
        </Typography>
      )}
    </Box>
  )
}
