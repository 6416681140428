import * as React from 'react'
import classes from './CustomModal.module.scss'
import { CrossIcon } from '../../utils/icons/CrossIcon'
import { CustomButton } from '../Button/CustomButton'
import Dialog from '@material-ui/core/Dialog'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogActions from '@material-ui/core/DialogActions'
import clsx from 'clsx'
import { Box, IconButton, makeStyles } from '@material-ui/core'

export const CustomModal = (props) => {
  const {
    isOpen = false,
    closeHandler = () => {},
    headerText,
    cancelLabel = '',
    submitLabel = '',
    submitHandler,
    modalBodyStyle = {},
    footerStyle = {},
    hideCloseIcon = false,
    width = '444px',
    isDisabledSubmitBtn = false
  } = props

  const useStyles = makeStyles({
    paper: {
      '& .MuiDialog-container .MuiDialog-paper': {
        width: width,
        maxWidth: width
      }
    }
  })
  const localClasses = useStyles()
  return (
    <React.Fragment>
      <Box>
        <Dialog
          maxWidth={false}
          aria-labelledby='customized-dialog-title'
          open={isOpen}
          style={{ borderRadius: '12px' }}
          className={clsx(classes.modalContainer, localClasses.paper)}
        >
          {headerText !== '' && (
            <MuiDialogTitle className={classes.headerText}>
              {headerText}
              {closeHandler ? (
                <IconButton
                  className={clsx(classes.btn, {
                    [classes.hideCloseIcon]: hideCloseIcon
                  })}
                  onClick={closeHandler}
                  aria-label='close'
                  disableRipple
                >
                  <CrossIcon width='14px' height='14px' />
                </IconButton>
              ) : null}
            </MuiDialogTitle>
          )}
          <MuiDialogContent style={modalBodyStyle}>
            {props.children}
          </MuiDialogContent>
          <MuiDialogActions
            className={classes.footerContainer}
            style={footerStyle}
          >
            <Box className={classes.footer}>
              {/* {showMoveButton && (
                <CustomButton
                  onClickHandler={openMoveHandler}
                  buttonText='Replace/Move Users'
                  type='outlined'
                />
              )} */}
              {cancelLabel !== '' && (
                <CustomButton
                  onClickHandler={closeHandler}
                  buttonText={cancelLabel}
                  type='outlined'
                />
              )}

              {submitLabel !== '' && (
                <CustomButton
                  onClickHandler={submitHandler}
                  buttonText={submitLabel}
                  type='contained'
                  disabled={isDisabledSubmitBtn}
                />
              )}
            </Box>
          </MuiDialogActions>
        </Dialog>
      </Box>
    </React.Fragment>
  )
}
