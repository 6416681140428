import React from 'react'
import { Box, IconButton, Typography } from '@material-ui/core'
import clsx from 'clsx'
import styles from './UserBulkUpload.module.scss'
import { CustomDataTable } from '../../../UIComponents/CustomTable'
import { DownloadIcon } from '../../../utils/icons/DownloadIcon'
import { DraftIcon } from '../../../utils/icons/DraftIcon'
import { CheckCircleIcon } from '../../../utils/icons/CheckCircleIcon'
import { Progressicon } from '../../../utils/icons/ProgressIcon'
import { ErrorIcon } from '../../../utils/icons/ErrorIcon'
import moment from 'moment'

export const BulkActionHistoryTable = (props) => {
  const {
    data,
    handleChangeRowsPerPage = () => {},
    handleChangePage,
    page,
    rowsPerPage = 5,
    orderBy,
    order,
    onRequestSort = () => {},
    onSearchHandler = () => {},
    handleDownloadRecords = () => {},
    handleNavigateToImportUserPage = () => {}
  } = props

  const getStatusIcon = (status) => {
    switch (status) {
      case 'draft':
        return <DraftIcon />
      case 'completed':
        return <CheckCircleIcon width='16' height='16' color='#059669' />
      case 'in progress':
        return <Progressicon />
      case 'failed':
        return <ErrorIcon />
      default:
        return <DraftIcon />
    }
  }

  const getLocaleTime = (date, format) => {
    return moment
      .utc(date)
      .local()
      .format(format + ', h:mm A')
      .split(',')
  }

  const columns = [
    {
      id: 'id',
      label: 'Id',
      minWidth: 100,
      sortable: true,
      cell: (row) => (
        <Box component='div'>
          {row.history_info.upload_id && (
            <Typography className={clsx(styles.rowText, styles.textTransform)}>
              {row.history_info.upload_id}
            </Typography>
          )}
        </Box>
      )
    },
    {
      id: 'action_type',
      label: 'Type',
      minWidth: 100,
      sortable: true,
      cell: (row) => (
        <Box component='div'>
          {row.history_info && (
            <Typography className={clsx(styles.rowText, styles.textTransform)}>
              {row.history_info.type}
            </Typography>
          )}
        </Box>
      )
    },
    {
      id: 'updated_at',
      label: 'Last updated on',
      minWidth: 170,
      sortable: true,
      cell: (row) => (
        <Box component='div' display='flex' style={{ gap: '8px' }}>
          {row.history_info && (
            <React.Fragment>
              <Typography
                className={clsx(styles.rowText, styles.textTransform)}
              >
                {
                  getLocaleTime(
                    row.history_info.date_of_upload +
                      ' ' +
                      row.history_info.time_of_upload,
                    row.history_info.date_format
                  )[0]
                }
              </Typography>
              <Typography
                className={clsx(
                  styles.rowText,
                  styles.textTransform,
                  styles.lightColor
                )}
              >
                {
                  getLocaleTime(
                    row.history_info.date_of_upload +
                      ' ' +
                      row.history_info.time_of_upload,
                    row.history_info.date_format
                  )[1]
                }
              </Typography>
            </React.Fragment>
          )}
        </Box>
      )
    },
    {
      id: 'uploader_id__first_name',
      label: 'Action done by',
      minWidth: 190,
      sortable: true,
      cell: (row) => (
        <Box component='div'>
          {row.history_info && (
            <Typography className={clsx(styles.rowText, styles.textTransform)}>
              {row.history_info.uploader_first_name}{' '}
              {row.history_info.uploader_last_name}
            </Typography>
          )}
        </Box>
      )
    },
    {
      id: 'summary',
      label: 'Summary',
      minWidth: 170,
      sortable: false,
      cell: (row) => (
        <Box
          component='div'
          display='flex'
          flexDirection='column'
          style={{ gap: '10px' }}
        >
          {row.history_info && (
            <React.Fragment>
              {+row.history_info.total_users > 0 && (
                <Box
                  component='div'
                  display='flex'
                  justifyContent='flex-start'
                  alignItems='center'
                  style={{ gap: '8px' }}
                >
                  <IconButton
                    onClick={(e) => handleDownloadRecords(e, 'total', row.id)}
                  >
                    <DownloadIcon width='11' height='14' />
                  </IconButton>
                  <Typography className={clsx(styles.rowText)}>
                    {row.history_info.total_users}{' '}
                    <span>
                      {' '}
                      {+row.history_info.total_users === 1
                        ? ' total record'
                        : ' total records '}
                    </span>
                  </Typography>
                </Box>
              )}
              {+row.history_info.valid_users > 0 && (
                <Box
                  component='div'
                  display='flex'
                  justifyContent='flex-start'
                  alignItems='center'
                  style={{ gap: '8px' }}
                >
                  <IconButton
                    onClick={(e) => handleDownloadRecords(e, 'success', row.id)}
                  >
                    <DownloadIcon width='11' height='14' />
                  </IconButton>
                  <Typography className={clsx(styles.rowText)}>
                    {row.history_info.valid_users}{' '}
                    <span>
                      {' '}
                      {+row.history_info.valid_users === 1
                        ? '  new user can be added '
                        : '  new users can be added '}
                    </span>
                  </Typography>
                </Box>
              )}
              {+row.history_info.exist_users > 0 && (
                <Box
                  component='div'
                  display='flex'
                  justifyContent='flex-start'
                  alignItems='center'
                  style={{ gap: '8px' }}
                >
                  <IconButton
                    onClick={(e) => handleDownloadRecords(e, 'exist', row.id)}
                  >
                    <DownloadIcon width='11' height='14' />
                  </IconButton>
                  <Typography className={clsx(styles.rowText)}>
                    {row.history_info.exist_users}{' '}
                    <span>
                      {' '}
                      {+row.history_info.exist_users === 1
                        ? '  existing user can be updated '
                        : '  existing users can be updated  '}
                    </span>
                  </Typography>
                </Box>
              )}

              {+row.history_info.invalid_users > 0 && (
                <Box
                  component='div'
                  display='flex'
                  justifyContent='flex-start'
                  alignItems='center'
                  style={{ gap: '8px' }}
                >
                  <IconButton
                    onClick={(e) => handleDownloadRecords(e, 'failure', row.id)}
                  >
                    <DownloadIcon width='11' height='14' />
                  </IconButton>
                  <Typography className={clsx(styles.rowText)}>
                    {row.history_info.invalid_users + ' '}
                    <span>
                      {' '}
                      {+row.history_info.invalid_users === 1
                        ? '  invalid record'
                        : '  invalid records'}
                    </span>
                  </Typography>
                </Box>
              )}
            </React.Fragment>
          )}
        </Box>
      )
    },
    {
      id: 'status_id__name',
      label: 'Status',
      minWidth: 170,
      sortable: true,
      cell: (row) => (
        <Box
          component='div'
          display='flex'
          alignItems='center'
          style={{ gap: '5px' }}
        >
          {row.history_info && (
            <React.Fragment>
              {getStatusIcon(row.history_info.status.toLowerCase())}
              <Typography
                className={clsx(
                  styles.rowText,
                  styles.textTransform,
                  styles.lightColor
                )}
              >
                {row.history_info.status}
              </Typography>
            </React.Fragment>
          )}
        </Box>
      )
    }
  ]

  return (
    <CustomDataTable
      searchPlaceholder='Search'
      isCheckboxTable={false}
      columns={columns}
      rows={data.results}
      count={data.count}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      handleChangePage={handleChangePage}
      page={page}
      rowsPerPage={rowsPerPage}
      orderBy={orderBy}
      order={order}
      onRequestSort={onRequestSort}
      onSearchHandler={onSearchHandler}
      handleRowClick={handleNavigateToImportUserPage}
      isRowClickable
    />
  )
}
