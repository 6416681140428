import { UUILocalStorageService } from '../../utils/LocalStorageService'
import _env from '../../../env'

export const formatCreateUserPayload = (payload) => {
  const res = {
    company_id: +UUILocalStorageService.getCompanyId(),
    first_name: payload.personalInfoInput.first_name,
    last_name: payload.personalInfoInput.last_name,
    email: payload.personalInfoInput.email,
    user_personal_info: {
      contact_number: payload.personalInfoInput.contact_number
        ? payload.personalInfoInput.contact_number.replaceAll(' ', '')
        : null,
      country_code: payload.personalInfoInput.country_code || null,
      contact_country_code:
        payload.personalInfoInput.contact_country_code || null,
      dob: payload.personalInfoInput.dob || null,
      gender: payload.personalInfoInput.gender || null
    },
    employee_info: {
      emp_level_id: payload?.employeeInfoInput?.emp_level?.value
        ? payload.employeeInfoInput.emp_level.value
        : null,
      employee_id: payload.employeeInfoInput.employee_id || null,
      entity_id: payload?.employeeInfoInput?.entity?.value
        ? payload.employeeInfoInput.entity.value
        : null,
      business_unit_id: payload?.employeeInfoInput?.business_unit?.value
        ? payload.employeeInfoInput.business_unit.value
        : null,
      department_id: payload?.employeeInfoInput?.department?.value
        ? payload.employeeInfoInput.department.value
        : null,
      designation: payload.employeeInfoInput.designation || null,
      base_location: payload.employeeInfoInput.base_location || null,
      currency: payload?.employeeInfoInput?.currency?.value
        ? payload.employeeInfoInput.currency.value
        : null
    },
    custom_attribute: payload.customFieldsInput || null,
    product: (() => {
      const product = {}
      if (payload.productAccess.travel) {
        product.travel = payload.travelRole || null
      }
      if (payload.productAccess.expense) {
        product.expense = payload.expenseRole || null
      }
      return product
    })(),
    approvers: {}
  }

  const userTags = []
  for (const key in payload.userTagType) {
    if (
      Object.hasOwnProperty.call(payload.userTagType, key) &&
      payload.userTagType[key]
    ) {
      userTags.push(payload.userTagType[key])
    }
  }
  if (userTags.length) {
    res.user_tags = userTags
  }

  // Travel specific information
  if (payload.productAccess.travel) {
    // Travel approvers
    res.approvers.travel = []
    for (const key in payload.travelApproversInput) {
      if (
        Object.hasOwnProperty.call(payload.travelApproversInput, key) &&
        payload.travelApproversInput[key] &&
        !!payload.travelApproversInput[key].value
      ) {
        res.approvers.travel.push({
          email: payload.travelApproversInput[key].value,
          sequence: +key.split('-')[1]
        })
      }
    }

    // FYI approver
    if (payload.travelFYI && payload.travelFYI.value) {
      res.approvers.travel.push({
        email: payload.travelFYI.value,
        sequence: 'fyi'
      })
    }

    // Additional information
    res.user_travel_info = {
      passport_issue_country: payload?.passportInfoInput?.passport_issue_country
        ?.value
        ? payload.passportInfoInput.passport_issue_country.value
        : null,
      nationality: payload?.passportInfoInput?.nationality?.value
        ? payload.passportInfoInput.nationality.value
        : null,
      passport_number: payload.passportInfoInput.passport_number || null,
      passport_issue_date:
        payload.passportInfoInput.passport_issue_date || null,
      passport_expiry_date:
        payload.passportInfoInput.passport_expiry_date || null,
      flight_seat_preference:
        payload.travelPreferenceInfo.flight_seat_preference || null,
      meal_preference: payload.travelPreferenceInfo.meal_preference || null,
      trusted_traveller:
        payload.secureTravelInputInfo.trusted_traveller || null,

      redress_number: payload.secureTravelInputInfo.redress_number || null
    }

    if (
      payload?.membershipInfoInput?.length &&
      payload.membershipInfoInput.length > 0
    ) {
      res.membership_info = payload.membershipInfoInput.map((membership) => {
        const res = {
          membership_type: membership.membership_type,
          name: membership.name,
          number: membership.number
        }

        return res
      })
    } else {
      res.membership_info = null
    }

    if (payload?.uploadedFiles?.length && payload.uploadedFiles.length > 0) {
      res.files = payload.uploadedFiles.map((_file) => {
        return {
          file_type: _file.file_type,
          file_path: _file.file_path,
          user_file_name: _file.user_file_name,
          upload: _file.upload
        }
      })
    } else {
      res.files = []
    }
  }

  // Expense specific information
  if (payload.productAccess.expense) {
    // Expense approvers
    res.approvers.expense = []
    for (const key in payload.expenseApproversInput) {
      if (
        Object.hasOwnProperty.call(payload.expenseApproversInput, key) &&
        payload.expenseApproversInput[key] &&
        payload.expenseApproversInput[key].value
      ) {
        res.approvers.expense.push({
          email: payload.expenseApproversInput[key].value,
          sequence: +key.split('-')[1]
        })
      }
    }

    // FYI approver
    if (payload.expenseFYI && payload.expenseFYI.value) {
      res.approvers.expense.push({
        email: payload.expenseFYI.value,
        sequence: 'fyi'
      })
    }
  }

  return res
}

export const formatUpdateUserPayload = (payload, inputChangeLog) => {
  const res = {
    fields: {
      user_personal_info: {},
      employee_info: {}
    }
  }

  if (!_env.isProfilePage()) {
    res.fields.product = (() => {
      if (payload.productAccess.travel && payload.productAccess.expense) {
        return 'expense+travel'
      } else if (payload.productAccess.travel) {
        return 'travel'
      } else {
        return 'expense'
      }
    })()

    res.fields.roles = (() => {
      const roles = {}
      if (payload.productAccess.travel) {
        roles.travel = payload.travelRole
      }
      if (payload.productAccess.expense) {
        roles.expense = payload.expenseRole
      }
      return roles
    })()

    res.fields.custom_attributes = payload.customFieldsInput
    res.fields.approvers = {}
  }

  if (inputChangeLog.userTagType) {
    const userTags = []
    for (const key in payload.userTagType) {
      if (
        Object.hasOwnProperty.call(payload.userTagType, key) &&
        payload.userTagType[key]
      ) {
        userTags.push(payload.userTagType[key])
      }
    }
    res.fields.user_tags = userTags
  }

  if (inputChangeLog.first_name) {
    res.fields.first_name = payload.personalInfoInput.first_name
  }

  if (inputChangeLog.last_name) {
    res.fields.last_name = payload.personalInfoInput.last_name
  }

  if (inputChangeLog.contact_number) {
    res.fields.user_personal_info.contact_number = payload.personalInfoInput
      .contact_number
      ? payload.personalInfoInput.contact_number.replaceAll(' ', '')
      : null
    res.fields.user_personal_info.country_code =
      payload.personalInfoInput.country_code || null
  }

  if (inputChangeLog.dob) {
    res.fields.user_personal_info.dob = payload.personalInfoInput.dob || null
  }

  if (inputChangeLog.gender) {
    res.fields.user_personal_info.gender =
      payload.personalInfoInput.gender || null
  }

  if (inputChangeLog.emp_level) {
    res.fields.employee_info.emp_level_id = payload?.employeeInfoInput
      ?.emp_level?.value
      ? payload.employeeInfoInput.emp_level.value
      : null
  }

  if (inputChangeLog.employee_id) {
    res.fields.employee_info.employee_id =
      payload.employeeInfoInput.employee_id || null
  }

  if (inputChangeLog.entity) {
    res.fields.employee_info.entity_id = payload?.employeeInfoInput?.entity
      ?.value
      ? payload.employeeInfoInput.entity.value
      : null
  }

  if (inputChangeLog.business_unit) {
    res.fields.employee_info.business_unit_id = payload?.employeeInfoInput
      ?.business_unit?.value
      ? payload.employeeInfoInput.business_unit.value
      : null
  }

  if (inputChangeLog.department) {
    res.fields.employee_info.department_id = payload?.employeeInfoInput
      ?.department?.value
      ? payload.employeeInfoInput.department.value
      : null
  }

  if (inputChangeLog.designation) {
    res.fields.employee_info.designation =
      payload.employeeInfoInput.designation || null
  }

  if (inputChangeLog.base_location) {
    res.fields.employee_info.base_location =
      payload.employeeInfoInput.base_location || null
  }

  if (inputChangeLog.currency) {
    res.fields.employee_info.currency = payload?.employeeInfoInput?.currency
      ?.value
      ? payload.employeeInfoInput.currency.value
      : null
  }

  if (payload.productAccess.travel) {
    if (!_env.isProfilePage()) {
      // Travel approvers
      res.fields.approvers.travel = []
      for (const key in payload.travelApproversInput) {
        if (
          Object.hasOwnProperty.call(payload.travelApproversInput, key) &&
          payload.travelApproversInput[key] &&
          !!payload.travelApproversInput[key].value
        ) {
          res.fields.approvers.travel.push({
            email: payload.travelApproversInput[key].value,
            sequence: +key.split('-')[1]
          })
        }
      }

      // FYI approver
      if (payload.travelFYI && payload.travelFYI.value) {
        res.fields.approvers.travel.push({
          email: payload.travelFYI.value,
          sequence: 'fyi'
        })
      }
    }

    if (payload.uploadedFiles && payload.uploadedFiles.length > 0) {
      res.fields.files = payload.uploadedFiles
        .filter((_file) => !_file.isExistingFile)
        .map((_file) => {
          return {
            file_type: _file.file_type,
            file_path: _file.file_path,
            user_file_name: _file.user_file_name,
            upload: _file.upload
          }
        })
    }

    if (payload.filesForDelete.length > 0) {
      res.fields.filesToDelete = payload.filesForDelete
    }

    if (payload.membershipInfoInput && payload.membershipInfoInput.length > 0) {
      res.fields.membership_info = payload.membershipInfoInput
        .filter((membership) => !!membership.isEditMode)
        .map((membership) => {
          const res = {
            membership_type: membership.membership_type,
            name: membership.name,
            number: membership.number,
            id: membership.id
          }
          return res
        })
    }

    res.fields.user_travel_info = {}

    if (inputChangeLog.passport_issue_country) {
      res.fields.user_travel_info.passport_issue_country = payload
        ?.passportInfoInput?.passport_issue_country?.value
        ? payload.passportInfoInput.passport_issue_country.value
        : null
    }

    if (inputChangeLog.nationality) {
      res.fields.user_travel_info.nationality = payload?.passportInfoInput
        ?.nationality?.value
        ? payload.passportInfoInput.nationality.value
        : null
    }

    if (inputChangeLog.passport_number) {
      res.fields.user_travel_info.passport_number =
        payload.passportInfoInput.passport_number || null
    }

    if (inputChangeLog.passport_issue_date) {
      res.fields.user_travel_info.passport_issue_date =
        payload.passportInfoInput.passport_issue_date || null
    }

    if (inputChangeLog.passport_expiry_date) {
      res.fields.user_travel_info.passport_expiry_date =
        payload.passportInfoInput.passport_expiry_date || null
    }

    if (inputChangeLog.flight_seat_preference) {
      res.fields.user_travel_info.flight_seat_preference =
        payload.travelPreferenceInfo.flight_seat_preference || null
    }

    if (inputChangeLog.meal_preference) {
      res.fields.user_travel_info.meal_preference =
        payload.travelPreferenceInfo.meal_preference || null
    }

    if (inputChangeLog.trusted_traveller) {
      res.fields.user_travel_info.trusted_traveller =
        payload.secureTravelInputInfo.trusted_traveller || null
    }

    if (inputChangeLog.redress_number) {
      res.fields.user_travel_info.redress_number =
        payload.secureTravelInputInfo.redress_number || null
    }
  }

  // Expense specific information
  if (payload.productAccess.expense) {
    // Expense approvers
    if (!_env.isProfilePage()) {
      res.fields.approvers.expense = []
      for (const key in payload.expenseApproversInput) {
        if (
          Object.hasOwnProperty.call(payload.expenseApproversInput, key) &&
          payload.expenseApproversInput[key] &&
          payload.expenseApproversInput[key].value
        ) {
          res.fields.approvers.expense.push({
            email: payload.expenseApproversInput[key].value,
            sequence: +key.split('-')[1]
          })
        }
      }

      // FYI approver
      if (payload.expenseFYI && payload.expenseFYI.value) {
        res.fields.approvers.expense.push({
          email: payload.expenseFYI.value,
          sequence: 'fyi'
        })
      }
    }
  }

  return res
}
