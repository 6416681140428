import moment from 'moment'

const dob = {
  maxDate: moment().subtract(18, 'years'),
  minDate: moment().subtract(300, 'years')
}
const passportStart = {
  maxDate: moment(),
  minDate: moment().subtract(100, 'years')
}
const passportEnd = {
  maxDate: moment().add(18, 'years'),
  minDate: moment()
}

export { dob, passportStart, passportEnd }
