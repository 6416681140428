import { InputAdornment, OutlinedInput } from '@material-ui/core'
import React from 'react'
import clsx from 'clsx'
import classes from './Search.module.scss'
import { SearchIcon } from '../../utils/icons/SearchIcon'

export const Searchbar = (props) => {
  const { placeholder = '', onChangeHandler = () => {} } = props
  return (
    <OutlinedInput
      placeholder={placeholder}
      onChange={onChangeHandler}
      className={clsx(classes.inputbase, classes.inputSelectorCls)}
      startAdornment={
        <InputAdornment position='start'>
          <SearchIcon width='20' height='20' />
        </InputAdornment>
      }
    />
  )
}
