import React from 'react'

export const FileError = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='120'
      height='120'
      fill='none'
      viewBox='0 0 120 120'
    >
      <path
        fill='#E5E7EB'
        d='M32.5 60a27.5 27.5 0 110 55 27.5 27.5 0 010-55zm28.145-50a11.25 11.25 0 017.95 3.295l14.06 14.05 14.04 14.06a11.26 11.26 0 013.29 7.95V98.75A11.25 11.25 0 0188.735 110H55.95a32.451 32.451 0 005.385-7.495l27.4-.005a3.75 3.75 0 003.75-3.75l-.005-48.735H71.25A11.25 11.25 0 0160.025 39.54L60 38.77l-.005-21.27H31.25a3.75 3.75 0 00-3.75 3.75v34.135a32.255 32.255 0 00-7.5 2.11V21.25A11.25 11.25 0 0131.25 10h29.395zm-40.18 64.83l-.35.29-.285.35a2.5 2.5 0 000 2.84l.29.35 8.85 8.845-8.84 8.83-.285.35a2.5 2.5 0 000 2.84l.285.35.35.285a2.5 2.5 0 002.84 0l.35-.285 8.83-8.835 8.85 8.845.345.29a2.5 2.5 0 002.84 0l.35-.29.285-.35a2.5 2.5 0 000-2.84l-.29-.35-8.845-8.84 8.86-8.85.29-.345a2.5 2.5 0 000-2.845l-.29-.345-.345-.29a2.5 2.5 0 00-2.845 0l-.345.29-8.86 8.85-8.85-8.85-.34-.29a2.5 2.5 0 00-2.845 0zM67.5 22.8l.005 15.975a3.75 3.75 0 003.24 3.715l.51.035 15.955-.005L67.5 22.8z'
      />
    </svg>
  )
}
