import React, { useState, useEffect } from 'react'
import clsx from 'clsx'
import Checkbox from '@material-ui/core/Checkbox'
import styles from './CustomCheckbox.module.scss'
import { OutlinedInput } from '@material-ui/core'
import Tooltip from '@material-ui/core/Tooltip'

export const CustomCheckbox = (props) => {
  const {
    isCheckbox = true,
    customHandler,
    disabled = false,
    checkboxFlag,
    id = '',
    disableText
  } = props
  const [isChecked, setIsChecked] = useState(!!checkboxFlag)

  useEffect(() => {
    // update local state whenever root lvl state/props get changed
    setIsChecked(!!checkboxFlag)
  }, [checkboxFlag])

  const handleCheckBoxHandler = (e) => {
    e.stopPropagation()
    if (disabled) return
    // setIsChecked(!isChecked)
    customHandler(e)
  }

  const handleParentClick = (e) => {
    e.stopPropagation()
    if (disabled) return
    if (isCheckbox) {
      // setIsChecked(!isChecked)
      const _e = {
        target: {
          name: id,
          value: props.label.toLowerCase()
        }
      }
      customHandler(_e)
    }
  }

  return (
    <React.Fragment>
      <Tooltip
        disableHoverListener={!disabled}
        disableFocusListener={!disabled}
        disableTouchListener={!disabled}
        placement='top-start'
        title={disableText}
      >
        <OutlinedInput
          onClick={handleParentClick}
          id={id}
          value={props.label}
          className={clsx(
            styles.largeInput,
            { [styles.inputSelectorCls]: !isChecked },
            {
              [styles.borderColorCls]: isChecked,
              [styles.readOnly]: disabled
            }
          )}
          startAdornment={
            isCheckbox && (
              <Checkbox
                className={styles.root}
                disableRipple
                checkedIcon={
                  <span className={clsx(styles.icon, styles.checkedIcon)} />
                }
                icon={<span className={styles.icon} />}
                inputProps={{ 'aria-label': 'decorative checkbox' }}
                onChange={handleCheckBoxHandler}
                // disabled={disabled}
                checked={isChecked}
                value={props.label.toLowerCase()}
                name={id}
              />
            )
          }
          readOnly
          disabled={disabled}
        />
      </Tooltip>
    </React.Fragment>
  )
}
