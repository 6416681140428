import { toast } from 'react-toastify'
import React from 'react'
import 'react-toastify/dist/ReactToastify.css'
import { Cross } from '../icons/CrossIcon'


toast.configure()
const IUIToastMessageService = (function () {
  let undoClicked = false
  function _notify(msg) {
    toast.dismiss()
    return toast.info(msg, {
      autoClose: true,
      theme: 'colored',
      style: { background: '#DD6B20' }
    })
  }

  function _warning(msg) {
    toast.dismiss()
    return toast.warning(msg, {
      autoClose: true,
      theme: 'colored',
      style: {
        background: '#DD6B20'
      },
      icon: false,
      closeButton: (props) => (
        <div {...props} style={{ paddingTop: '15px', paddingRight: '12px' }}>
          <Cross width='10px' height='10px' color='#fff' />{' '}
        </div>
      )
    })
  }

  function _successnotify(msg) {
    toast.dismiss()
    return toast.success(msg, {
      autoClose: true,
      theme: 'colored',
      style: { background: '#38A169' },
      icon: false,
      closeButton: (props) => (
        <div {...props} style={{ paddingTop: '15px', paddingRight: '12px' }}>
          <Cross width='10px' height='10px' color='#fff' />{' '}
        </div>
      )
    })
  }

  function _errornotify(msg) {
    toast.dismiss()
    return toast.error(msg, {
      autoClose: true,
      theme: 'colored',
      icon: false,
      closeButton: (props) => (
        <div {...props} style={{ paddingTop: '15px', paddingRight: '12px' }}>
          <Cross width='10px' height='10px' color='#fff' />{' '}
        </div>
      )
    })
  }

  function _undoNotify(msg, onCloseCallback, autoClose = 5000) {
    return toast.success(msg, {
      autoClose,
      theme: 'colored',
      style: { background: '#38A169' },
      icon: false,
      closeButton: (props) => (
        <div {...props} style={{ paddingTop: '15px', paddingRight: '20px' }}>
          <Cross width='10px' height='10px' color='#fff' />{' '}
        </div>
      ),
      onClose: () => {
        if (typeof onCloseCallback === 'function') {
          onCloseCallback(undoClicked)
        }
      }
    })
  }

  return {
    notify: _notify,
    sNotify: _successnotify,
    errNotify: _errornotify,
    warningNotify: _warning,
    undoNotify: _undoNotify,
    setUndoClicked: (value) => {
      undoClicked = value
    }
  }
})()

export default IUIToastMessageService
