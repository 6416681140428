import IUILocalStorageService from './LocalStorageServices'
import api from '../configs/axiosConfig'
import { utilUrls } from '../urls/apiUrls'

const IUILogoutService = (redirectUrl = null, callback) => {
  const _token = IUILocalStorageService.getRefreshToken()
  if (_token) {
    api
      .post(utilUrls.common.LOGOUT, { refresh: _token })
      .then((res) => {
        if (res.data.status === true) {
          if (typeof callback === 'function') {
            callback()
          }
        }
      })
      .finally(() => {
        if (redirectUrl) window.location.href = redirectUrl
      })
  } else {
    if (typeof callback === 'function') callback()
    if (redirectUrl) window.location.href = redirectUrl
  }
}
export default IUILogoutService
