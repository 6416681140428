import React from 'react'
import Button from '@material-ui/core/Button'
import clsx from 'clsx'
import classes from './CustomButton.module.scss'
import { Box } from '@material-ui/core'

export const CustomButton = (props) => {
  const {
    buttonText,
    iconComponent = null, // <AddIcon width = "14" height = "14" color = "white"/ > ,
    disableRipple = true,
    type = 'text',
    onClickHandler,
    customClassName,
    count = '',
    disabled = false,
    target = '_blank',
    href = '',
    isLink = false
  } = props

  return (
    <React.Fragment>
      {type === 'text' && (
        <Button
          target={isLink ? target.toString() : undefined}
          href={isLink ? href.toString() : undefined}
          startIcon={iconComponent && iconComponent}
          className={clsx(classes.textBtn, classes.muiBtn, customClassName)}
          disableRipple={disableRipple}
          onClick={onClickHandler}
          disabled={disabled}
        >
          {buttonText}
        </Button>
      )}
      {type === 'outlined' && (
        <Button
          startIcon={iconComponent && iconComponent}
          className={clsx(classes.muiBtn, classes.outlinedBtn)}
          disableRipple={disableRipple}
          variant={type}
          onClick={onClickHandler}
          disabled={disabled}
        >
          {buttonText}
        </Button>
      )}
      {type === 'contained' && (
        <Button
          startIcon={iconComponent && iconComponent}
          className={clsx(classes.containedBtn, classes.muiBtn)}
          disableRipple={disableRipple}
          variant={type}
          onClick={onClickHandler}
          disabled={disabled}
        >
          {buttonText}
        </Button>
      )}
      {type === 'customContained' && (
        <Button
          startIcon={iconComponent && iconComponent}
          className={clsx(classes.containedBtn, classes.muiBtn)}
          disableRipple={disableRipple}
          variant='contained'
          onClick={onClickHandler}
          disabled={disabled}
        >
          {buttonText}
          {count !== '' && (
            <Box component='div' className={classes.customMiniBox}>
              {count}
            </Box>
          )}
        </Button>
      )}
    </React.Fragment>
  )
}
