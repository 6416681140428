import React from 'react'

export const ErrorIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='16'
      height='16'
      fill='none'
      viewBox='0 0 16 16'
    >
      <path
        fill='#E11D48'
        d='M8 1C4.136 1 1 4.136 1 8s3.136 7 7 7 7-3.136 7-7-3.136-7-7-7zm0 2a1 1 0 011 1v4a1 1 0 11-2 0V4a1 1 0 011-1zm1 8a1 1 0 11-2 0 1 1 0 012 0z'
      />
    </svg>
  )
}
