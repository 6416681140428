import React from 'react'

export const ExpandMoreIcon = (props) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={props.width}
      height={props.height}
      fill='none'
      viewBox='0 0 12 7'
    >
      <path
        fill='#6B7280'
        d='M.293.294a1 1 0 011.414 0L6 4.587 10.293.294a1 1 0 111.414 1.414l-5 5a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414z'
      />
    </svg>
  )
}
