import {
  SET_BULK_ACTION_FIELDS,
  SET_IMPORT_USER_INFO,
  SHOW_LOADER,
  STOP_LOADER
} from './types'

const initialState = {
  isProcessDone: false,
  userBulkImportInfo: {},
  bulkActionFields: {
    count: 0,
    next: null,
    previous: null,
    results: []
  },
  loading: false
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_LOADER: {
      return {
        ...state,
        loading: true
      }
    }
    case STOP_LOADER: {
      return {
        ...state,
        loading: false
      }
    }
    case SET_IMPORT_USER_INFO: {
      return {
        ...state,
        isProcessDone: true,
        userBulkImportInfo: action.payload.data
      }
    }
    case SET_BULK_ACTION_FIELDS: {
      const bulkActionHistoryInfo = action.payload
      return {
        ...state,
        bulkActionFields: bulkActionHistoryInfo
      }
    }

    default:
      return state
  }
}

export default reducer
