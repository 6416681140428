import { Box, Grid, Divider, Typography } from '@material-ui/core'
import React, { useState } from 'react'
import { CustomButton } from '../../../UIComponents/Button/CustomButton'
import AddIcon from '../../../utils/icons/AddIcon'
import { UUIInput } from '../../../UIComponents/Input'
import AsyncCustomSelect from '../../../UIComponents/Select/AsyncCustomSelect'
import styles from './UserAddEdit.module.scss'
import clsx from 'clsx'
import { GetInputType } from './helperFuntions'
import { editApproverLabel } from '../../../store/index'
import { PencilIcon } from '../../../utils/icons/PencilIcon'
import { CustomRadioComponent } from '../../../UIComponents/Radio/CustomRadioComponent'
import { CustomLabelComponent } from '../../../UIComponents/Label'
import { CustomModal } from '../../../UIComponents/Modal'
import { useDispatch, useSelector } from 'react-redux'
import { BoldInfoIcon } from '../../../utils/icons/BoldInfoIcon'
import { approversOptions } from '../../../UIComponents/Select/asyncLoadOptions'
import UserRole from './components/UserRole'
import PaymentCards from './components/PaymentCards'
export const TravelExpenseFields = (props) => {
  const {
    fieldsInfo,
    addFields,
    travelConstants,
    type,
    onRoleChange = () => {},
    roleValue = '',
    approversInput,
    onApproverChange = () => {},
    fyiApprover,
    onFYIChange = () => {},
    maxApprovers,
    isProfile = false,
    editUserId = null,
    roles = []
  } = props
  const [editedLabel, setEditedLabelValue] = useState({
    product: '',
    approvers: []
  })
  const [isOpenEditAproverModal, setOpenEditAproverModalFlag] = useState(false)
  const [isDisabledSubmitBtn, setDisabledSubmitBtn] = useState(true)
  const [errMsg, setErrMsg] = useState({})

  const dispatch = useDispatch()
  const paymentDetails = useSelector(
    (state) => state.userAddEdit.paymentDetails
  )

  const openEditAproverModal = () => {
    setOpenEditAproverModalFlag(true)
  }

  const closeEditAproverModal = () => {
    setOpenEditAproverModalFlag(false)
    setDisabledSubmitBtn(true)
  }

  const handleOnChangeEditApproverFields = (_name, _value, indexAt) => {
    var sequence = +_name.split('_')[1]
    var product = _name.split('_')[0]
    var copyOldLabel = { ...editedLabel }
    copyOldLabel.product = product
    copyOldLabel.approvers.length > 0 &&
      copyOldLabel.approvers.find((o, i) => {
        if (+o.sequence === sequence) {
          copyOldLabel.approvers[i].label = _value
          return true // stop searching
        }
      })
    setDisabledSubmitBtn(false)
    const found = copyOldLabel.approvers.some((el) => el.sequence === sequence)
    if (!found)
      copyOldLabel.approvers.push({
        label: _value,
        sequence: sequence,
        indexAt
      })
    setEditedLabelValue(copyOldLabel)
    setErrMsg({
      ...errMsg,
      [indexAt]: ''
    })
  }

  const handleEditAproverLabel = (e) => {
    let hasError = false
    const _errMsg = { ...errMsg }

    for (let i = 0; i < editedLabel.approvers.length; i++) {
      let rgx = null
      if (type === 'travel') {
        rgx = /^(travel person)( )[0-9]*$/i
      } else {
        rgx = /^(expense person)( )[0-9]*$/i
      }
      if (
        editedLabel.approvers[i].label.match(rgx) &&
        editedLabel.approvers[i].label.toLowerCase() !==
          `${type.toLowerCase()} person ${editedLabel.approvers[i].indexAt + 1}`
      ) {
        _errMsg[editedLabel.approvers[i].indexAt] = `Can't update '${
          type === 'travel' ? 'Travel' : 'Expense'
        } person ${editedLabel.approvers[i].indexAt + 1}' to '${
          editedLabel.approvers[i].label
        }'`

        if (!hasError) {
          hasError = true
          const el = document.getElementById(
            `${editedLabel.product}_${editedLabel.approvers[i].sequence}`
          )
          if (el) {
            el.scrollIntoView({ behavior: 'smooth', block: 'center' })
          }
        }
      }
    }

    if (hasError) {
      setErrMsg(_errMsg)
      return
    }

    setErrMsg({})
    const payload = {
      product: editedLabel.product
    }
    payload.approvers = editedLabel.approvers.map((row) => {
      return {
        label: row.label,
        sequence: row.sequence
      }
    })
    dispatch(editApproverLabel(payload)).then(() => {
      setEditedLabelValue({
        product: editedLabel.product,
        approvers: []
      })
      setOpenEditAproverModalFlag(false)
      setDisabledSubmitBtn(true)
    })
  }

  return (
    <React.Fragment>
      <Grid
        container
        className={(styles.sectionWrapper, styles.headingPadding)}
      >
        <Typography
          variant='h3'
          className={clsx(styles.heading, styles.headingMedium)}
        >
          {travelConstants.heading}
        </Typography>
      </Grid>
      <Divider className={styles.dividerColor} />
      <Grid
        container
        className={clsx(styles.product_info, styles.sectionWrapper)}
      >
        <Grid item sm={12} md={4}>
          <CustomLabelComponent
            label={travelConstants.roleHeading}
            headerType
            description={travelConstants.roleDescription}
          />
        </Grid>
        <Grid item sm={12} md={8} lg={8} xl={6}>
          <Grid container className={styles.formFieldsWrapper}>
            {isProfile ? (
              <UserRole list={roles} value={roleValue} />
            ) : (
              <CustomRadioComponent
                list={roles}
                value={roleValue}
                customHandler={onRoleChange}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
      {isProfile && type === 'expense' && fieldsInfo.length === 0 ? null : (
        <React.Fragment>
          <Divider className={styles.dividerColor} />
          <Grid
            container
            className={clsx(styles.product_info, styles.sectionWrapper)}
          >
            <Grid item sm={12} md={4}>
              <CustomLabelComponent
                label={travelConstants.approverHeading}
                headerType
                description={
                  isProfile
                    ? ''
                    : travelConstants.approverDescription.replace(
                        ':maxApprovers',
                        maxApprovers
                      )
                }
              />
              {!isProfile && fieldsInfo.length > 0 && (
                <CustomButton
                  buttonText='Edit approver labels'
                  type='text'
                  iconComponent={
                    <PencilIcon width='13.59' height='13.59' color='#EC5D25' />
                  }
                  onClickHandler={openEditAproverModal}
                />
              )}
            </Grid>
            <Grid item sm={12} md={8} lg={8} xl={6}>
              <Grid
                container
                className={clsx(
                  styles.formFieldsWrapper,
                  styles.firstChildMargin
                )}
              >
                {fieldsInfo.map((p, i) => {
                  p.readOnly = isProfile
                  p.editUserId = editUserId
                  if (i >= maxApprovers) {
                    return null
                  }
                  return (
                    <Grid
                      key={i.toString()}
                      item
                      md={8}
                      lg={8}
                      xl={8}
                      className={styles.margin}
                    >
                      <GetInputType
                        _p={p}
                        inputState={approversInput}
                        customHandler={onApproverChange}
                      />
                    </Grid>
                  )
                })}
                {!isProfile && fieldsInfo.length < maxApprovers ? (
                  <Grid item md={8} lg={8} xl={8} className={styles.margin}>
                    <CustomButton
                      buttonText={
                        fieldsInfo.length
                          ? 'Add another approver'
                          : 'Add an approver'
                      }
                      type='text'
                      iconComponent={
                        <AddIcon width='10' height='10' color='#EC5D25' />
                      }
                      onClickHandler={addFields}
                    />
                  </Grid>
                ) : null}
                {type === 'travel' && (
                  <React.Fragment>
                    {fieldsInfo.length || !isProfile ? (
                      <React.Fragment>
                        <Grid item sm={6} md={6} className={styles.margin}>
                          <Box component='div' className={styles.dashedLine} />
                        </Grid>
                        <Grid item md={3} lg={6} xl={3} />
                      </React.Fragment>
                    ) : null}

                    <Grid item md={6} lg={6} xl={6} className={styles.margin}>
                      <AsyncCustomSelect
                        additional={{
                          page: 1,
                          product: type,
                          userId: editUserId,
                          placeholder: 'Select approver'
                        }}
                        placeHolderCheck={
                          fyiApprover !== undefined && fyiApprover.value === ''
                        }
                        label={travelConstants.FYILabel}
                        value={fyiApprover}
                        customSelectHandler={(_name, _val) => onFYIChange(_val)}
                        loadOption={approversOptions}
                        description={travelConstants.FYIDescription}
                        readOnly={isProfile}
                        disableText='Please get in touch with your administrator to change this value.'
                      />
                    </Grid>
                  </React.Fragment>
                )}
              </Grid>
            </Grid>
          </Grid>
        </React.Fragment>
      )}

      <CustomModal
        isOpen={isOpenEditAproverModal}
        closeHandler={closeEditAproverModal}
        headerText='Edit approver field'
        cancelLabel='Cancel'
        submitLabel='Save'
        submitHandler={handleEditAproverLabel}
        isDisabledSubmitBtn={isDisabledSubmitBtn}
        width='420px'
        modalBodyStyle={{
          maxHeight: '600px',
          overflow: 'auto'
        }}
      >
        <React.Fragment>
          <Grid container style={{ marginTop: '5px', gap: '30px' }}>
            <Grid item sm={6} md={12}>
              <Box
                display='flex'
                className={styles.editApproverDescription}
                style={{
                  gap: '12px',
                  border: '1px solid #e5e7eb',
                  borderRadius: '6px',
                  padding: '16px'
                }}
              >
                <BoldInfoIcon width='35' height='35' />
                <CustomLabelComponent
                  label='These label changes will be applied across the company for all
            the users'
                  style={{ fontWeight: 'normal' }}
                />
              </Box>
            </Grid>
            {fieldsInfo.map((p, i) => {
              return (
                <Grid
                  key={i.toString()}
                  item
                  md={12}
                  lg={12}
                  xl={12}
                  className={styles.fieldMargin}
                >
                  <UUIInput
                    defaultValue={p.label}
                    label={`${
                      type === 'travel' ? 'Travel' : 'Expense'
                    } person ${i + 1}`}
                    customClassName={styles.inputWidth}
                    id={type + '_' + p.sequence}
                    name={type + '_' + p.sequence}
                    customHandler={(_name, _value) =>
                      handleOnChangeEditApproverFields(_name, _value, i)
                    }
                    errorMsg={errMsg[i]}
                    isError={!!errMsg[i]}
                  />
                </Grid>
              )
            })}
          </Grid>
        </React.Fragment>
      </CustomModal>
      {type === 'travel' && isProfile && paymentDetails && (
        <PaymentCards paymentDetails={paymentDetails} />
      )}
    </React.Fragment>
  )
}
