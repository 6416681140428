import React from 'react'
import styles from './CustomFields.module.scss'
import {
  Box,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table
} from '@material-ui/core'
import { CustomButton } from '../../../UIComponents/Button/CustomButton'
import { useSelector, useDispatch } from 'react-redux'
import { deleteCustomField, setEditModal, toggleModal } from '../../../store'
import { showLoader, stopLoader } from '../../../store/customFields/actions'

export default function CustomFieldsTable() {
  const { data } = useSelector((state) => state.customFields)
  const dispatch = useDispatch()

  const handleDelete = (attributeColumn) => {
    dispatch(showLoader())
    dispatch(deleteCustomField(attributeColumn)).finally(() => {
      dispatch(stopLoader())
    })
  }

  const handleEdit = (field) => {
    dispatch(setEditModal(field))
    dispatch(toggleModal(true))
  }
  return (
    <Box className={styles.tablewrapper}>
      <TableContainer>
        <Table aria-label='simple table' style={{ height: 'auto !important' }}>
          <TableHead className={styles.tableHead}>
            <TableRow>
              <TableCell align='left' width='40%'>
                #
              </TableCell>
              <TableCell width='40%'>Name</TableCell>
              <TableCell width='20%' style={{ paddingLeft: '3%' }}>
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.length === 0 ? (
              <TableRow>
                <TableCell />
                <TableCell>
                  <Box className={styles.emptyData}>No data available</Box>
                </TableCell>
                <TableCell />
              </TableRow>
            ) : (
              data.map((row, i) => (
                <TableRow key={row.id.toString()}>
                  <TableCell align='left'>{i + 1}</TableCell>
                  <TableCell>{row.field_name}</TableCell>
                  <TableCell className={styles.actionButtons}>
                    <CustomButton
                      buttonText='Edit'
                      onClickHandler={() => handleEdit(row)}
                    />
                    {/* <CustomButton
                    buttonText='Delete'
                    onClickHandler={() => handleDelete(row.attribute_column)}
                  /> */}
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}
