import {
  SHOW_LOADER,
  STOP_LOADER,
  SET_RENDER_FIELDS,
  SET_CUSTOM_FIELD,
  SET_TRAVEL_APPROVER_FIELD,
  SET_EXPENSE_APPROVER_FIELD,
  SET_EDITED_TRAVEL_APPROVER_FIELD,
  SET_EDITED_EXPENSE_APPROVER_FIELD,
  SET_COUNTRY_CODES,
  SET_STATIC_FIELDS_VISIBILITY,
  SET_PAYMENT_DETAILS
} from './types'

import {
  personalInfo,
  products,
  workInfo,
  passportInfo,
  travelPreference,
  secureTravelInfo,
  dropdownList,
  travelConstants,
  expenseConstants
} from '../../modules/UserManagement/UserAddEdit/constants'

import { approversOptions } from '../../UIComponents/Select/asyncLoadOptions'

const initialState = {
  loading: false,
  isRendered: false,
  renderFields: {
    personalInfo: personalInfo,
    workInfo: workInfo,
    products: products,
    userTagType: [],
    custom_attributes: [],
    travelApprovers: [],
    expenseApprovers: [],
    passportInfo: passportInfo,
    travelPreference: travelPreference,
    secureTravelInfo: secureTravelInfo,
    disableText: '',
    travelRoles: travelConstants.roles,
    expenseRoles: expenseConstants.roles
  },
  dropdownList: dropdownList,
  maxTravelApprovers: 10,
  maxExpenseApprovers: 10,
  maxCustomAttributes: 10,
  maxMembershipInput: 15,
  dateFormat: 'dd MMM yyyy',
  countryCode: 'IN',
  defaultCurrency: 'INR',
  optionalFields: null,
  fieldsVisibility: null,
  paymentDetails: null
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_LOADER:
      return {
        ...state,
        loading: true
      }
    case STOP_LOADER:
      return {
        ...state,
        loading: false
      }
    case SET_RENDER_FIELDS: {
      const renderFields = { ...state.renderFields }

      const {
        user_personal_info: userPersonalInfo,
        employee_info: employeeInfo,
        user_travel_info: userTravelInfo
      } = action.payload.visibility

      const {
        user_personal_info: userPersonalInfoOptionalFields,
        employee_info: employeeInfoOptionalFields,
        user_travel_info: userTravelInfoOptionalFields
      } = action.payload.optional

      renderFields.personalInfo = setFieldsDisplayAtt(
        renderFields.personalInfo,
        userPersonalInfo,
        userPersonalInfoOptionalFields
      )

      renderFields.workInfo = setFieldsDisplayAtt(
        renderFields.workInfo,
        employeeInfo,
        employeeInfoOptionalFields
      )

      renderFields.passportInfo = setFieldsDisplayAtt(
        renderFields.passportInfo,
        userTravelInfo,
        userTravelInfoOptionalFields
      )

      renderFields.travelPreference = setFieldsDisplayAtt(
        renderFields.travelPreference,
        userTravelInfo,
        userTravelInfoOptionalFields
      )

      renderFields.secureTravelInfo = setFieldsDisplayAtt(
        renderFields.secureTravelInfo,
        userTravelInfo,
        userTravelInfoOptionalFields
      )

      renderFields.products = renderFields.products.filter((product) => {
        return action.payload.products.includes(product.name)
      })

      renderFields.custom_attributes = action.payload.custom_attributes

      if (action?.payload?.approvers?.travel?.length) {
        renderFields.travelApprovers = action.payload.approvers.travel.map(
          (approver, i) => {
            return {
              id: i + 1,
              label: approver.label || `Person ${i + 1}`,
              name: `sequence-${approver.sequence}`,
              required: false,
              type: 'async_select',
              fullWidth: false,
              visibility: true,
              sequence: approver.sequence,
              placeholder: 'Select approver',
              product: 'travel',
              loadOption: approversOptions,
              disableText:
                'Please get in touch with your administrator to change this value.'
            }
          }
        )
      }

      if (action?.payload?.approvers?.expense?.length) {
        renderFields.expenseApprovers = action.payload.approvers.expense.map(
          (approver, i) => {
            return {
              id: i + 1,
              label: approver.label || `Person ${i + 1}`,
              name: `sequence-${approver.sequence}`,
              required: false,
              type: 'async_select',
              fullWidth: false,
              visibility: true,
              sequence: approver.sequence,
              placeholder: 'Select approver',
              product: 'expense',
              loadOption: approversOptions,
              disableText:
                'Please get in touch with your administrator to change this value.'
            }
          }
        )
      }

      if (action?.payload?.travel_roles?.length) {
        renderFields.travelRoles = action.payload.travel_roles.map((role) => {
          return {
            id: role.id,
            label: role.label,
            description: role.description,
            name: role.value,
            type: 'radio',
            fullWidth: false,
            visibility: true,
            disableText:
              'Please get in touch with your administrator to change this value.'
          }
        })
      }

      if (action?.payload?.expense_roles?.length) {
        renderFields.expenseRoles = action.payload.expense_roles.map((role) => {
          return {
            id: role.id,
            label: role.label,
            description: role.description,
            name: role.value,
            type: 'radio',
            fullWidth: false,
            visibility: true,
            disableText:
              'Please get in touch with your administrator to change this value.'
          }
        })
      }

      const dropdownList = { ...state.dropdownList }
      dropdownList.flight_seat_preference =
        action?.payload?.flight_seat_preference?.map((preference) => {
          return {
            id: preference,
            name: preference
          }
        })
      dropdownList.meal_preference = action?.payload?.meal_preference?.map(
        (preference) => {
          return {
            id: preference,
            name: preference
          }
        }
      )
      renderFields.userTagType = action?.payload?.user_tag_type
        ? action.payload.user_tag_type.map((tag, i) => {
            return {
              id: tag.id,
              label: tag.tag_name,
              name: tag.tag_name,
              type: 'checkbox'
            }
          })
        : []
      return {
        ...state,
        renderFields,
        dropdownList,
        maxTravelApprovers: action.payload.max_travel_approvers,
        maxExpenseApprovers: action.payload.max_expense_approvers,
        maxCustomAttributes: action.payload.max_custom_attributes,
        dateFormat: action.payload.date_format.replaceAll('m', 'M'),
        countryCode: action.payload.country_code,
        defaultCurrency: action.payload.currency,
        optionalFields: action.payload.optional,
        fieldsVisibility: action.payload.visibility,
        isRendered: true
      }
    }

    case SET_STATIC_FIELDS_VISIBILITY: {
      const _personalInfo = state.renderFields.personalInfo.map((input) => {
        if (
          action?.payload &&
          Object.hasOwnProperty.call(action.payload, input.name) &&
          action?.payload?.[input.name]
        ) {
          if (
            Object.hasOwnProperty.call(action.payload[input.name], 'readOnly')
          ) {
            input.readOnly = action.payload[input.name].readOnly
          }

          if (
            Object.hasOwnProperty.call(action.payload[input.name], 'visibility')
          ) {
            input.visibility = action.payload[input.name].visibility
          }

          if (
            Object.hasOwnProperty.call(action.payload[input.name], 'required')
          ) {
            input.required = action.payload[input.name].required
          }
        }
        return input
      })
      return {
        ...state,
        renderFields: {
          ...state.renderFields,
          personalInfo: _personalInfo
        }
      }
    }

    case SET_CUSTOM_FIELD: {
      return {
        ...state,
        renderFields: {
          ...state.renderFields,
          custom_attributes: [
            ...state.renderFields.custom_attributes,
            action.payload
          ]
        }
      }
    }

    case SET_TRAVEL_APPROVER_FIELD: {
      return {
        ...state,
        renderFields: {
          ...state.renderFields,
          travelApprovers: [
            ...state.renderFields.travelApprovers,
            action.payload
          ]
        }
      }
    }

    case SET_EXPENSE_APPROVER_FIELD: {
      return {
        ...state,
        renderFields: {
          ...state.renderFields,
          expenseApprovers: [
            ...state.renderFields.expenseApprovers,
            action.payload
          ]
        }
      }
    }

    case SET_EDITED_TRAVEL_APPROVER_FIELD: {
      const renderFields = { ...state.renderFields }

      const _travelApprovers = renderFields.travelApprovers.map(
        (approver, i) => {
          const row = action.payload.approvers.find(
            (row) => +row.sequence === +approver.sequence
          )
          if (row) {
            approver.label = row.label || `Travel person ${i + 1}`
            approver.secondaryLabel = `Travel person ${i + 1}`
          } else {
            approver.secondaryLabel = ''
          }
          return approver
        }
      )

      return {
        ...state,
        renderFields: {
          ...state.renderFields,
          travelApprovers: _travelApprovers
        }
      }
    }

    case SET_EDITED_EXPENSE_APPROVER_FIELD: {
      const renderFields = { ...state.renderFields }

      const _expenseApprovers = renderFields.expenseApprovers.map(
        (approver, i) => {
          const row = action.payload.approvers.find(
            (row) => +row.sequence === +approver.sequence
          )
          if (row) {
            approver.label = row.label || `Expense person ${i + 1}`
            approver.secondaryLabel = `Expense person ${i + 1}`
          } else {
            approver.secondaryLabel = ''
          }
          return approver
        }
      )

      return {
        ...state,
        renderFields: {
          ...state.renderFields,
          expenseApprovers: _expenseApprovers
        }
      }
    }

    case SET_COUNTRY_CODES: {
      const contactNumber = action.payload.map((code) => {
        return {
          id: code.id,
          name: code.country_name,
          dialCode: code.dial_code,
          code: code.code
        }
      })
      const passportIssueCountry = action.payload.map((code) => {
        return {
          id: code.code,
          name: code.country_name
        }
      })
      const nationality = action.payload.map((code) => {
        return {
          id: code.code,
          name: code.country_name
        }
      })
      return {
        ...state,
        dropdownList: {
          ...state.dropdownList,
          contact_number: contactNumber,
          passport_issue_country: passportIssueCountry,
          nationality
        }
      }
    }

    case SET_PAYMENT_DETAILS: {
      return {
        ...state,
        paymentDetails: action.payload
      }
    }

    default:
      return state
  }
}

const setFieldsDisplayAtt = (objToLoop, visibilityObj, optionalObj) => {
  return objToLoop.map((p) => {
    let key = p.name
    if (p.name === 'passport_photo' || p.name === 'travel_docs') {
      key = 'file_name'
    }
    if (visibilityObj && Object.hasOwnProperty.call(visibilityObj, key)) {
      p.visibility = !!visibilityObj[key]
      if (visibilityObj[key] === 2) {
        p.readOnly = true
      } else {
        p.readOnly = false
      }
    }
    if (optionalObj && Object.hasOwnProperty.call(optionalObj, key)) {
      p.required = !optionalObj[key]
    }
    if (p.readOnly) {
      p.required = false
    }
    return p
  })
}

export default reducer
