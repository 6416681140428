import React from 'react'
import { CustomLabelComponent } from '../../../../UIComponents/Label'

const UserRole = ({ list = [], value = '' }) => {
  const role = list.find((role) => role.name === value)
  return (
    <div>
      {role && (
        <CustomLabelComponent
          label={role.label}
          headerType
          description={role.description}
        />
      )}
    </div>
  )
}

export default UserRole
