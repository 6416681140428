import React from 'react'
import {
  commonLoadOptions,
  currencyLoadOptions,
  countryOptions
} from '../../../UIComponents/Select/asyncLoadOptions'

import { dob, passportEnd, passportStart } from '../../../utils/dateValidation'

const personalInfo = [
  {
    id: 1,
    label: 'First and middle name',
    name: 'first_name',
    required: true,
    type: 'input',
    fullWidth: false,
    visibility: true,
    readOnly: false,
    placeholder: 'Enter first and middle name',
    validation: {
      regexOnChange: /^[a-zA-Z ]*$/,
      // for accented char: /^[A-Za-zÀ-ÿĀ-žḂ-ỳ\-&' ]*$/
      regexOnBlur: null,
      maxLimit: '',
      minLimit: '',
      maxLength: 100,
      minLength: 0
    },
    disableText:
      'Please get in touch with your administrator to change this value.'
  },
  {
    id: 2,
    label: 'Last name',
    name: 'last_name',
    required: true,
    type: 'input',
    fullWidth: false,
    visibility: true,
    readOnly: false,
    placeholder: 'Enter last name',
    validation: {
      regexOnChange: /^[a-zA-Z ]*$/,
      regexOnBlur: null,
      maxLimit: '',
      minLimit: '',
      maxLength: 100,
      minLength: 0
    },
    disableText:
      'Please get in touch with your administrator to change this value.'
  },
  {
    id: 3,
    label: 'Email address',
    name: 'email',
    required: true,
    type: 'input',
    fullWidth: false,
    visibility: true,
    readOnly: false,
    placeholder: 'Enter email address',
    validation: {
      regexOnChange: null,
      regexOnBlur:
        /^(([^<>()\\.,;:\s@"]+(\.[^<>()\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      maxLimit: '',
      minLimit: '',
      maxLength: 100,
      minLength: 0
    },
    disableText:
      'Please get in touch with your administrator to change this value.'
  },
  {
    id: 4,
    label: 'Phone number',
    name: 'contact_number',
    required: true,
    type: 'phone',
    fullWidth: false,
    visibility: true,
    readOnly: false,
    validation: {
      regexOnChange: /^[\d]/,
      regexOnBlur: null,
      maxLimit: '',
      minLimit: '',
      maxLength: 100,
      minLength: 0
    },
    disableText:
      'Please get in touch with your administrator to change this value.'
  },
  {
    id: 5,
    label: 'Date of birth',
    name: 'dob',
    required: false,
    type: 'datepicker',
    fullWidth: false,
    visibility: true,
    readOnly: false,
    hasIcon: false,
    size: 'normal',
    maxDate: dob.maxDate,
    minDate: dob.minDate,
    disableText:
      'Please get in touch with your administrator to change this value.'
  },
  {
    id: 6,
    empty: true
  },
  {
    id: 7,
    label: 'Gender',
    name: 'gender',
    required: false,
    type: 'select',
    fullWidth: false,
    visibility: true,
    readOnly: false,
    placeholder: 'Select gender',
    tooltip: 'This information will be used for flight booking purpose',

    disableText:
      'Please get in touch with your administrator to change this value.'
  },
  {
    id: 8,
    empty: true
  }
]
const workInfo = [
  {
    id: 1,
    label: 'Employee level',
    name: 'emp_level',
    required: false,
    type: 'async_select',
    fullWidth: false,
    visibility: true,
    readOnly: false,
    title: 'Select employee level',
    inputType: false,
    placeholder: 'Select employee level',
    loadOption: commonLoadOptions,
    tabelName: 'employee_level',
    tooltip:
      'This information will be used in creation of policies and approval rules',
    disableText:
      'Please get in touch with your administrator to change this value.'
  },
  {
    id: 2,
    label: 'Employee ID',
    name: 'employee_id',
    required: false,
    type: 'input',
    fullWidth: false,
    visibility: true,
    readOnly: false,
    placeholder: 'Enter employee ID',
    validation: {
      regexOnChange: /^[A-Za-z0-9]*$/,
      regexOnBlur: null,
      maxLimit: '',
      minLimit: '',
      maxLength: 100,
      minLength: 0
    },
    disableText:
      'Please get in touch with your administrator to change this value.'
  },
  {
    id: 3,
    label: 'Entity',
    name: 'entity',
    required: false,
    type: 'async_select',
    fullWidth: false,
    visibility: true,
    readOnly: false,
    title: '',
    inputType: false,
    placeholder: 'Select entity',
    loadOption: commonLoadOptions,
    tabelName: 'entity',
    tooltip:
      'This information will be used in creation of policies and approval rules',
    disableText:
      'Please get in touch with your administrator to change this value.'
  },
  {
    id: 4,
    label: 'Business unit',
    name: 'business_unit',
    required: false,
    type: 'async_select',
    fullWidth: false,
    visibility: true,
    readOnly: false,
    title: 'Select unit',
    inputType: false,
    placeholder: 'Select unit',
    tabelName: 'business_unit',
    loadOption: commonLoadOptions,
    disableText:
      'Please get in touch with your administrator to change this value.'
  },
  {
    id: 5,
    label: 'Department',
    name: 'department',
    required: false,
    type: 'async_select',
    fullWidth: false,
    visibility: true,
    readOnly: false,
    title: 'Select department',
    inputType: false,
    placeholder: 'Select department',
    tabelName: 'department',
    loadOption: commonLoadOptions,
    disableText:
      'Please get in touch with your administrator to change this value.'
  },
  {
    id: 6,
    label: 'Designation',
    name: 'designation',
    required: false,
    type: 'input',
    fullWidth: false,
    visibility: true,
    readOnly: false,
    placeholder: 'Enter designation',
    validation: {
      regexOnChange: /^[A-Za-z0-9.&'()\- ]*$/,
      regexOnBlur: /^[a-zA-Z][a-zA-Z0-9.&'()\- ]*$/,
      maxLimit: '',
      minLimit: '',
      maxLength: 100,
      minLength: 0
    },
    disableText:
      'Please get in touch with your administrator to change this value.'
  },
  {
    id: 7,
    label: 'Base location',
    name: 'base_location',
    required: false,
    type: 'input',
    fullWidth: true,
    placeholder: 'Enter city',
    visibility: true,
    readOnly: false,
    validation: {
      regexOnChange: /^[A-Za-z0-9 -.&'()]*$/,
      regexOnBlur: null,
      maxLimit: '',
      minLimit: '',
      maxLength: 500,
      minLength: 0
    },
    disableText:
      'Please get in touch with your administrator to change this value.'
  },
  {
    id: 7,
    label: 'Default currency',
    name: 'currency',
    required: false,
    type: 'async_select',
    fullWidth: false,
    visibility: true,
    readOnly: false,
    // title: 'Select currency',
    inputType: false,
    // placeholder: 'Select currency',
    tabelName: 'default_currency',
    loadOption: currencyLoadOptions,
    tooltip: 'This only changes the currency for this user',
    disableText:
      'Please get in touch with your administrator to change this value.'
  },
  {
    id: 8,
    empty: true
  }
]
const products = [
  {
    id: 1,
    label: 'Travel',
    name: 'travel',
    type: 'checkbox',
    disableText:
      'Please get in touch with your administrator to change this value.'
  },
  {
    id: 2,
    label: 'Expense',
    name: 'expense',
    type: 'checkbox',
    disableText:
      'Please get in touch with your administrator to change this value.'
  }
]
const dropdownList = {
  gender: [
    {
      id: 1,
      name: 'Male'
    },
    {
      id: 2,
      name: 'Female'
    },
    {
      id: 3,
      name: 'Others'
    }
  ],
  flight_seat_preference: [],
  meal_preference: [],
  contact_number: [],
  passport_issue_country: [],
  nationality: []
}

const travelConstants = {
  heading: 'Travel specific information',
  roleHeading: 'Travel role',
  roleDescription:
    'Roles allow users to only access relevant features and functionalities',
  approverHeading: 'Travel approvers',
  approverDescription: 'You can add upto :maxApprovers approvers',
  FYILabel: 'FYI approver',
  FYIDescription: 'Send travel approval alerts to this email',
  roles: [
    {
      id: 1,
      label: 'Traveler',
      description: 'Can create trips for themselves and guests',
      name: 'traveler',
      type: 'radio',
      fullWidth: false,
      visibility: true,
      disableText:
        'Please get in touch with your administrator to change this value.'
    },
    {
      id: 2,
      label: 'Admin',
      description:
        'Can administer the product, manage user access, billing details, company settings etc.',
      name: 'travel_admin',
      type: 'radio',
      fullWidth: false,
      visibility: true,
      disableText:
        'Please get in touch with your administrator to change this value.'
    },
    {
      id: 3,
      label: 'Finance admin',
      description:
        'Can administer the product, manage user access, billing details, company settings etc.',
      name: 'travel_finance_admin',
      type: 'radio',
      fullWidth: false,
      visibility: true,
      disableText:
        'Please get in touch with your administrator to change this value.'
    }
  ],
  approvers: [
    {
      id: 1,
      label: 'Person 1',
      name: 'travel_approver',
      required: false,
      type: 'input',
      fullWidth: false,
      visibility: true,
      readOnly: false,
      placeholder: 'Select Approver',
      validation: {
        regexOnChange: null,
        regexOnBlur:
          /^(([^<>()\\.,;:\s@"]+(\.[^<>()\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        maxLimit: '',
        minLimit: '',
        maxLength: 100,
        minLength: 0
      },
      disableText:
        'Please get in touch with your administrator to change this value.'
    },
    {
      id: 2,
      label: 'Person 2',
      name: 'travel_approver',
      required: false,
      type: 'input',
      fullWidth: false,
      visibility: true,
      readOnly: false,
      placeholder: 'Select Approver',
      validation: {
        regexOnChange: null,
        regexOnBlur:
          /^(([^<>()\\.,;:\s@"]+(\.[^<>()\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        maxLimit: '',
        minLimit: '',
        maxLength: 100,
        minLength: 0
      },
      disableText:
        'Please get in touch with your administrator to change this value.'
    }
  ]
}

const expenseConstants = {
  heading: 'Expense specific information',
  roleHeading: 'Expense role',
  roleDescription:
    'Roles allow users to only access relevant features and functionalities',
  approverHeading: 'Expense approvers',
  approverDescription: 'You can add upto :maxApprovers approvers',
  FYILabel: 'FYI approver',
  FYIDescription: 'Send expense approval alerts to this email',
  roles: [
    {
      id: 1,
      label: 'Submitter',
      description: 'Can submit expenses',
      name: 'submitter',
      type: 'radio',
      fullWidth: false,
      visibility: true,
      disableText:
        'Please get in touch with your administrator to change this value.'
    },
    {
      id: 2,
      label: 'Admin',
      description:
        'Can administer the product, manage user access, billing details, company settings etc.',
      name: 'expense_admin',
      type: 'radio',
      fullWidth: false,
      visibility: true,
      disableText:
        'Please get in touch with your administrator to change this value.'
    },
    {
      id: 3,
      label: 'Finance admin',
      description:
        'Can administer the product, manage user access, billing details, company settings etc.',
      name: 'expense_finance_admin',
      type: 'radio',
      fullWidth: false,
      visibility: true,
      disableText:
        'Please get in touch with your administrator to change this value.'
    }
  ],
  approvers: [
    {
      id: 1,
      label: 'Person 1',
      name: 'expense_approver',
      required: false,
      type: 'input',
      fullWidth: false,
      visibility: true,
      readOnly: false,
      placeholder: 'Select Approver',
      validation: {
        regexOnChange: null,
        regexOnBlur:
          /^(([^<>()\\.,;:\s@"]+(\.[^<>()\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        maxLimit: '',
        minLimit: '',
        maxLength: 100,
        minLength: 0
      },
      disableText:
        'Please get in touch with your administrator to change this value.'
    },
    {
      id: 2,
      label: 'Person 2',
      name: 'expense_approver',
      required: false,
      type: 'input',
      fullWidth: false,
      visibility: true,
      readOnly: false,
      placeholder: 'Select Approver',
      validation: {
        regexOnChange: null,
        regexOnBlur:
          /^(([^<>()\\.,;:\s@"]+(\.[^<a>()\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        maxLimit: '',
        minLimit: '',
        maxLength: 100,
        minLength: 0
      },
      disableText:
        'Please get in touch with your administrator to change this value.'
    }
  ]
}

const passportInfo = [
  {
    id: 1,
    label: 'Passport issuing country',
    name: 'passport_issue_country',
    required: false,
    type: 'async_select',
    fullWidth: false,
    visibility: true,
    readOnly: false,
    placeholder: 'Select country',
    tabelName: 'country_name',
    loadOption: countryOptions,
    disableText:
      'Please get in touch with your administrator to change this value.'
  },
  {
    id: 2,
    label: 'Passport number',
    name: 'passport_number',
    required: false,
    type: 'input',
    fullWidth: false,
    visibility: true,
    readOnly: false,
    placeholder: 'Enter passport number',
    validation: {
      regexOnChange: /^[A-Za-z0-9]*$/,
      regexOnBlur: null,
      maxLimit: '',
      minLimit: '',
      maxLength: 15,
      minLength: 7
    },
    disableText:
      'Please get in touch with your administrator to change this value.'
  },
  {
    id: 3,
    label: 'Nationality',
    name: 'nationality',
    required: false,
    type: 'async_select',
    fullWidth: true,
    visibility: true,
    readOnly: false,
    placeholder: 'Select nationality',
    tabelName: 'nationality',
    loadOption: countryOptions,
    disableText:
      'Please get in touch with your administrator to change this value.'
  },
  {
    id: 4,
    label: 'Date of issue',
    name: 'passport_issue_date',
    required: false,
    type: 'datepicker',
    hasIcon: false,
    fullWidth: false,
    visibility: true,
    readOnly: false,
    size: 'small',
    maxDate: passportStart.maxDate,
    minDate: passportStart.minDate,
    disableText:
      'Please get in touch with your administrator to change this value.'
  },
  {
    id: 5,
    label: 'Date of expiry',
    name: 'passport_expiry_date',
    required: false,
    type: 'datepicker',
    hasIcon: false,
    fullWidth: false,
    visibility: true,
    readOnly: false,
    size: 'small',
    maxDate: passportEnd.maxDate,
    minDate: passportEnd.minDate,
    disableText:
      'Please get in touch with your administrator to change this value.'
  },
  {
    id: 6,
    label: 'Photo of passport',
    description: '',
    name: 'passport_photo',
    required: false,
    type: 'file',
    multiple: true,
    fileType: 'application/pdf,image/png,image/jpeg,image/jpg',
    fileSize: 5, // 5 mb
    title: 'Select Photo',
    fullWidth: true,
    visibility: true,
    readOnly: false,
    maxNoFiles: 2,
    disableText:
      'Please get in touch with your administrator to change this value.'
  },
  {
    id: 7,
    label: 'Travel documents',
    description: 'You can add upto 5 documents',
    name: 'travel_docs',
    required: false,
    multiple: true,
    fileType: 'application/pdf,image/png,image/jpeg,image/jpg',
    fileSize: 5, // 5 mb
    title: 'Select document',
    type: 'file',
    fullWidth: true,
    visibility: true,
    readOnly: false,
    maxNoFiles: 5,
    tooltip:
      'This is for quick access of important travel documents during your trip like Visa, Identification, Vaccine certificates etc.',
    disableText:
      'Please get in touch with your administrator to change this value.'
  }
]

const travelPreference = [
  {
    id: 1,
    label: 'Flight seat preference',
    name: 'flight_seat_preference',
    required: false,
    type: 'select',
    fullWidth: false,
    visibility: true,
    readOnly: false,
    placeholder: 'Select seat preference',
    disableText:
      'Please get in touch with your administrator to change this value.'
  },
  {
    id: 1,
    label: 'Meals preference',
    name: 'meal_preference',
    required: false,
    type: 'select',
    fullWidth: false,
    visibility: true,
    readOnly: false,
    placeholder: 'Select meals preference',
    disableText:
      'Please get in touch with your administrator to change this value.'
  }
]

const secureTravelInfo = [
  {
    id: 1,
    label: 'Known traveler number',
    placeholder: 'Enter known traveler number',
    name: 'trusted_traveller',
    required: false,
    type: 'input',
    hasIcon: true,
    fullWidth: false,
    visibility: true,
    readOnly: false,
    tooltip: (
      <React.Fragment>
        The Known Traveler Number is a unique number issued by the U.S.
        Government to identify customers who participate in a known traveler
        program (e.g.: Global Entry, SENTRI, NEXUS, TSA PreCheck application
        process). For more information about the Known Traveler Number,{'  '}
        <a
          href='https://www.tsa.gov/travel/frequently-asked-questions'
          target='_blank'
          rel='noreferrer'
        >
          visit TSA's PreCheck website
        </a>
      </React.Fragment>
    ),
    disableText:
      'Please get in touch with your administrator to change this value.'
  },
  {
    id: 1,
    label: 'Redress number',
    placeholder: 'Enter redress number',
    name: 'redress_number',
    required: false,
    type: 'input',
    hasIcon: true,
    fullWidth: false,
    visibility: true,
    readOnly: false,
    tooltip: (
      <React.Fragment>
        <p>
          The Redress Number is a unique number issued by the DHS (Department of
          Homeland Security) that is used to facilitate passenger clearance for
          persons who have the same name as someone on the TSA (Transportation
          Security Administration) watch list. For more information about the
          Secure Flight program,{'  '}
          <a
            href='https://www.tsa.gov/travel/passenger-support/travel-redress-program'
            target='_blank'
            rel='noreferrer'
          >
            visit TSA's Secure Flight website
          </a>
        </p>
      </React.Fragment>
    ),
    disableText:
      'Please get in touch with your administrator to change this value.'
  }
]

export {
  personalInfo,
  workInfo,
  dropdownList,
  products,
  travelConstants,
  expenseConstants,
  passportInfo,
  travelPreference,
  secureTravelInfo
}
