import { UUILocalStorageService } from './LocalStorageService'
import _env from '../../env'

const userAddEditUrl = {
  countryCode: () => 'api/v1/user-management/country-code-details/',
  renderFields: () =>
    `api/v1/user-management/users/fields?company=${UUILocalStorageService.getCompanyId()}`,
  approverField: () =>
    `api/v1/user-management/company/${UUILocalStorageService.getCompanyId()}/approver-labels`,
  currencyCode: () =>
    `api/v1/user-management/country-details?info=currency_code&company-id=${UUILocalStorageService.getCompanyId()}&length=20`,
  countryPaginated: () =>
    `api/v1/user-management/country-details?company-id=${UUILocalStorageService.getCompanyId()}&length=20`,
  dropdownList: () =>
    `api/v1/user-management/company/${UUILocalStorageService.getCompanyId()}/table`,
  createUser: () => 'api/v1/user-management/users',
  approversSearch: () =>
    `api/v1/user-management/company/${UUILocalStorageService.getCompanyId()}/company-approvers`,
  preSignedUrl: () =>
    `api/v1/user-management/company/${UUILocalStorageService.getCompanyId()}/presigned-url`,
  editUser: (userId) => `api/v1/user-management/users/${userId}`,
  deletePaymentCard: (pmId, vendorId) =>
    `${_env.getPaymentUrl()}/api/v1/card/delete/${pmId}/${vendorId}/1`,
  setDefaultCard: (pmId, vendorId) =>
    `${_env.getPaymentUrl()}/api/v1/card/default`
}

const userListUrl = {
  getUser: `api/v1/user-management/users`,
  updateActiveStatus: 'api/v1/user-management/users/activate?',
  bulkDeactivate: () =>
    `api/v1/user-management/company/${UUILocalStorageService.getCompanyId()}/bulk-users/activate-or-deactivate?action=deactivate`,
  exportUsers: () =>
    `api/v1/user-management/company/${UUILocalStorageService.getCompanyId()}/bulk-users/download/users`
}

const userBulkImport = {
  generateTemplate: () =>
    `api/v1/user-management/company/${UUILocalStorageService.getCompanyId()}/bulk-users/generate-template`,
  uploadUrl: () =>
    `api/v1/user-management/company/${UUILocalStorageService.getCompanyId()}/presigned-url`,
  validateUploadedFile: () =>
    `api/v1/user-management/company/${UUILocalStorageService.getCompanyId()}/bulk-users/validate`,
  executionResult: () => `api/v1/user-management/bulk-execution-result`,
  downloadUploadedRecords: () =>
    `api/v1/user-management/company/${UUILocalStorageService.getCompanyId()}/bulk-users/download/reports`,
  bulkActionHistory: () =>
    `api/v1/user-management/company/${UUILocalStorageService.getCompanyId()}/bulk-action-history`,
  importRecords: () =>
    `api/v1/user-management/company/${UUILocalStorageService.getCompanyId()}/bulk-users`
}

const common = {
  verifyToken: () => 'account/auth/token/verify/',
  refreshToken: () => 'account/auth/token/refresh/',
  LOGOUT: `account/auth/logout/`
}

const customFieldsUrls = {
  customFields: () =>
    `api/v1/user-management/company/${UUILocalStorageService.getCompanyId()}/custom-attribute-labels`
}

const userPanelUrl = {
  addUnit: (tableName) =>
    `api/v1/user-management/company/${UUILocalStorageService.getCompanyId()}/table?action=add&table=${tableName}`,
  editUnit: (tableName) =>
    `api/v1/user-management/company/${UUILocalStorageService.getCompanyId()}/table?action=edit&table=${tableName}`,
  deleteUnit: (tableName) =>
    `api/v1/user-management/company/${UUILocalStorageService.getCompanyId()}/table?action=delete&table=${tableName}`,
  getUnit: (tableName, length) =>
    `api/v1/user-management/company/${UUILocalStorageService.getCompanyId()}/table?table_name=${tableName}&length=${length}`,
  getPaginationUnit: (tableName, length, page) =>
    `api/v1/user-management/company/${UUILocalStorageService.getCompanyId()}/table?table_name=${tableName}&length=${length}&page=${page}`,
  moveUnit: () =>
    `api/v1/user-management/company/${UUILocalStorageService.getCompanyId()}/transfer-users`
}
export {
  userAddEditUrl,
  common,
  userListUrl,
  customFieldsUrls,
  userBulkImport,
  userPanelUrl
}
