import React from 'react'
import clsx from 'clsx'
import Checkbox from '@material-ui/core/Checkbox'
import styles from './CustomCheckbox.module.scss'

export const PrimaryCheckbox = (props) => {
  const {
    handleCheckBoxHandler = () => {},
    disabled = false,
    checkboxFlag = false,
    id = ''
  } = props

  return (
    <React.Fragment>
      <Checkbox
        className={styles.root}
        disableRipple
        checkedIcon={<span className={clsx(styles.icon, styles.checkedIcon)} />}
        icon={<span className={styles.icon} />}
        inputProps={{ 'aria-label': 'decorative checkbox' }}
        onChange={handleCheckBoxHandler}
        disabled={disabled}
        checked={checkboxFlag}
        id={id}
      />
    </React.Fragment>
  )
}
