import { toast } from 'react-toastify'
import React from 'react'
import 'react-toastify/dist/ReactToastify.css'
import { CrossIcon } from './icons/CrossIcon'
// import { CrossIcon } from './icons/CrossIcon'
// import { WarningInfoIcon } from './icons/WarningInfoIcon'

toast.configure()
const UUIToastService = (function () {
  function _notify(msg) {
    toast.dismiss()
    return toast.info(msg, {
      autoClose: true,
      theme: 'colored',
      style: { background: '#DD6B20' }
    })
  }

  function _warning(msg) {
    toast.dismiss()
    return toast.warning(msg, {
      autoClose: true,
      theme: 'colored',
      style: {
        background: '#DD6B20'
        // color: '#374151'
        // display: 'flex',
        // padding: '15px',
        // border: '1px solid #FDE047',
        // fontWeight: '400px',
        // fontSize: '16px',
        // fontFamily: 'Inter',
        // fontStyle: 'normal'
      },
      icon: false,
      // progressStyle: { backgroundColor: '#ffe972' },
      // bodyStyle: { alignItems: 'unset' },
      closeButton: (props) => (
        <div {...props} style={{ paddingTop: '15px', paddingRight: '12px' }}>
          <CrossIcon width='10px' height='10px' color='#fff' />{' '}
        </div>
      )
    })
  }

  function _successnotify(msg) {
    toast.dismiss()
    return toast.success(msg, {
      autoClose: true,
      theme: 'colored',
      style: { background: '#38A169' },
      icon: false,
      closeButton: (props) => (
        <div {...props} style={{ paddingTop: '15px', paddingRight: '12px' }}>
          <CrossIcon width='10px' height='10px' color='#fff' />{' '}
        </div>
      )
    })
  }

  function _errornotify(msg) {
    toast.dismiss()
    return toast.error(msg, {
      autoClose: true,
      theme: 'colored',
      icon: false,
      closeButton: (props) => (
        <div {...props} style={{ paddingTop: '15px', paddingRight: '12px' }}>
          <CrossIcon width='10px' height='10px' color='#fff' />{' '}
        </div>
      )
    })
  }

  return {
    notify: _notify,
    sNotify: _successnotify,
    errNotify: _errornotify,
    warningNotify: _warning
  }
})()

export default UUIToastService
