import React from 'react'
import { Provider } from 'react-redux'
import store from './store'

function withStore(Component) {
  function WrappedComponent(props) {
    return (
      <Provider store={store}>
        <Component {...props} />
      </Provider>
    )
  }
  return WrappedComponent
}

export default withStore
