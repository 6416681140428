import React from 'react'
import { CustomModal } from '../../../UIComponents/Modal'
import { useSelector } from 'react-redux'
import { Box } from '@material-ui/core'
import { UUIInput } from '../../../UIComponents/Input'

export default function EditPanelDialog({
  isOpen,
  closeHandler,
  customHandler,
  submitHandler,
  isError,
  name,
  isDisabledSubmitBtn,
  validation
}) {
  const { dialogData } = useSelector((state) => state.userPanel)

  // const closeModal = () => {
  //   dispatch(closeDialog())
  // }

  return (
    <CustomModal
      isDisabledSubmitBtn={isDisabledSubmitBtn}
      isOpen={isOpen}
      closeHandler={closeHandler}
      headerText={`Edit ${name.toLowerCase()}`}
      cancelLabel={dialogData.labels?.cancelLabel}
      submitLabel={dialogData.labels?.submitLabel}
      submitHandler={submitHandler}
      width='365px'
      hideCloseIcon={false}
      modalBodyStyle={{
        maxHeight: '400px',
        overflow: 'auto',
        margin: '0px'
      }}
      footerStyle={{
        justifyContent: 'end'
      }}
    >
      <Box
        component='div'
        display='flex'
        justifyContent='center'
        alignItems='center'
        flexDirection='column'
        padding='8px 24px'
      >
        <UUIInput
          label={`${name} name`}
          // secondaryLabel={_p.secondaryLabel}
          // fullWidth={_p.fullWidth}
          name='name'
          defaultValue={dialogData.data.name}
          customHandler={customHandler}
          placeholder=''
          isError={isError}
          validation={validation}
          errorMsg={`Please enter ${name.toLowerCase()} name.`}
          required
        />
      </Box>
    </CustomModal>
  )
}
