import React from 'react'
import styles from './index.module.scss'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { useHistory } from 'react-router-dom'

const VerificationError = ({
  config,
  errMsg = "You don't have valid role or Permission to access this page"
}) => {
  const history = useHistory()
  return (
    <div className={styles.root}>
      <Card className={styles.minWidth}>
        <CardContent>
          <Typography gutterBottom variant='h4' className={styles.cardHeader}>
            Permission Error!
          </Typography>
          <Typography variant='body2' component='p'>
            {errMsg}
          </Typography>
        </CardContent>
        <CardActions>
          <Button
            size='small'
            onClick={() => history.push(config.routes.fallbackUrl)}
          >
            Back to Homepage
          </Button>
        </CardActions>
      </Card>
    </div>
  )
}

export default VerificationError
