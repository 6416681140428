import { IconButton, Typography } from '@material-ui/core'
import React, { useEffect, useRef, useState } from 'react'
import styles from './FileUpload.module.scss'
import clsx from 'clsx'
import { CrossIcon } from '../../utils/icons/CrossIcon'
import Tooltip from '@material-ui/core/Tooltip'

export const FileUpload = ({
  multiple = false,
  title = 'Upload File',
  fileType = '',
  customHandler = () => {},
  name,
  maxFiles = 1,
  maxFileSize = 5, // 5 mb
  value,
  isError = false,
  errorMsg = '',
  readOnly = false,
  hasInfoIcon = false,
  tooltipText = '',
  id = '',
  disableText
}) => {
  const maxNumFiles = multiple ? maxFiles : 1
  const ref = useRef()
  const [files, setFiles] = useState(value)
  const [localErrMsg, setLocalErr] = useState('')
  const fileTypeArr = fileType.split(',')

  const handleRemoveFile = (e, removeIndex) => {
    e.stopPropagation()
    e.preventDefault()
    if (readOnly) return
    ref.current.value = ''
    const _files = files.filter((file, i) => i !== removeIndex)
    customHandler(name, [..._files], {
      isDeleted: true
    })
  }

  const handleOnChange = (event) => {
    if (readOnly) return
    if (event.target.files.length + files.length > maxNumFiles) {
      setLocalErr(`You can only select upto ${maxNumFiles} files`)
      return
    }
    for (let i = 0; i < event.target.files.length; i++) {
      if (!fileTypeArr.includes(event.target.files[i].type)) {
        setLocalErr(
          `'${event.target.files[i].name}'- file type ${event.target.files[i].type} is not Allowed.`
        )
        return
      }
      if (event.target.files[i].size > maxFileSize * 1000000) {
        setLocalErr(
          `'${event.target.files[i].name}'- file should be less than ${maxFileSize} MB.`
        )
        return
      }
    }
    // update the root lvl state
    customHandler(name, [...Array.from(event.target.files), ...files], {
      isUpload: true
    })
    setLocalErr('')
  }

  const handleOpenFile = (file) => {
    customHandler(name, [...files], {
      openFile: true,
      file
    })
  }
  useEffect(() => {
    // whenever root lvl state/props get change , update the local state
    setFiles(value)
  }, [value])

  const formatLongFileName = (name) => {
    if (name.length > 25) {
      name = name.slice(0, 24) + '...'
    }
    return name
  }
  return (
    <React.Fragment>
      {files.length < maxNumFiles && (
        <label
          id={id}
          htmlFor={`${name}-file-upload`}
          className={clsx(styles.fileUploadContainer, {
            [styles.readOnly]: readOnly
          })}
        >
          {title}
        </label>
      )}
      {files.map((file, i) => {
        return (
          <div key={String(i + 1)}>
            <div
              className={clsx(styles.fileUploadContainer, {
                [styles.inputSize]: true
              })}
              onClick={() => handleOpenFile(file)}
            >
              <a>{formatLongFileName(file.name)}</a>

              {!readOnly && (
                <IconButton
                  onClick={(e) => handleRemoveFile(e, i)}
                  className={styles.iconContainer}
                >
                  <CrossIcon width='10' height='10' />
                </IconButton>
              )}
            </div>
            {isError && typeof errorMsg === 'object' && (
              <Typography
                variant='body2'
                component='div'
                className={styles.errorTextCls}
              >
                {errorMsg[file.name]}
              </Typography>
            )}
          </div>
        )
      })}
      <Tooltip
        placement='top-start'
        disableHoverListener={!readOnly}
        disableFocusListener={!readOnly}
        disableTouchListener={!readOnly}
        title={disableText}
      >
        <input
          id={`${name}-file-upload`}
          type='file'
          accept={fileType}
          style={{ display: 'none' }}
          multiple={multiple}
          ref={ref}
          onChange={handleOnChange}
          name={name}
          disabled={readOnly}
        />
      </Tooltip>
      {(isError || localErrMsg) && (
        <Typography
          variant='body2'
          component='div'
          className={styles.errorTextCls}
        >
          {typeof errorMsg === 'string' && errorMsg ? errorMsg : localErrMsg}
        </Typography>
      )}
    </React.Fragment>
  )
}
