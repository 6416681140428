import {
  Backdrop,
  Box,
  CircularProgress,
  Divider,
  Grid,
  Typography
} from '@material-ui/core'
import React, { useState } from 'react'
import { CustomButton } from '../../../UIComponents/Button/CustomButton'
import { CustomModal } from '../../../UIComponents/Modal'
import styles from './UserList.module.scss'
import clsx from 'clsx'
import { CustomDropzone } from '../../../UIComponents/FileUpload/Dropzone'
import {
  executionJob,
  generateTemplate,
  uploadBulkUsersFile,
  validateUploadedFile
} from '../../../store/UserBulkImport/actions'
import { useDispatch } from 'react-redux'
import { ErrorIcon } from '../../../utils/icons/ErrorIcon'

export const BulkUploadModal = (props) => {
  const { isOpenImportUsersModal, closeImportUsersModal, closeImportModal } =
    props
  const dispatch = useDispatch()
  const [fileNames, setFileNames] = useState([])
  const [isFileUploading, setIsFileUploading] = useState(false)
  const [isFileDownloading, setIsFileDownloading] = useState(false)
  const [files, setFiles] = useState([])
  const [isError, setErrorStatus] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const handleFileNameSelectedOnClose = () => {
    setFileNames([])
    setFiles([])
    setErrorMessage('')
    setErrorStatus(false)
    closeImportUsersModal()
  }

  const handleFileNameUploadedOnClose = (jobId) => {
    setFileNames([])
    setFiles([])
    setErrorMessage('')
    setErrorStatus(false)
    closeImportModal(jobId)
  }

  const handleDrop = (acceptedFiles) => {
    setFileNames(acceptedFiles.map((file) => file.name))
    setFiles(acceptedFiles)
  }

  const downloadTemplate = () => {
    setIsFileDownloading(true)
    dispatch(generateTemplate()).then((res) => {
      setIsFileDownloading(false)
      window.open(res.data.presigned_url, '_self').focus()
    })
  }

  const removeSelectedFiles = (e) => {
    if (e.stopPropagation) e.stopPropagation()
    setErrorMessage('')
    setErrorStatus(false)
    setFileNames([])
  }

  const handleBulkUploading = async () => {
    setIsFileUploading(true)
    const data = []
    const docs = []
    // var validatedRes = {}
    files.forEach((doc) => {
      docs.push({
        fileName: doc.name,
        file: doc
      })
    })
    for (let i = 0; i < docs.length; i++) {
      const res = await dispatch(uploadBulkUsersFile(docs[i]))
      await dispatch(validateUploadedFile(res.user_file_name))
        .then((validatedRes) => {
          if (validatedRes.data.status) {
            dispatch(
              executionJob(validatedRes.data.job_id, 'is_validation_completed')
            )
              .then((response) => {
                data.push(res)
                setFiles([...data])
                setIsFileUploading(false)
                if (validatedRes.data.status)
                  handleFileNameUploadedOnClose(+validatedRes.data.job_id)
              })
              .catch((reject) => {
                if (reject) {
                  if (!reject.response.data.status) {
                    setErrorStatus(true)
                    setErrorMessage(reject.response.data.message)
                    data.push(res)
                    setFiles([...data])
                    setIsFileUploading(false)
                  }
                }
              })
          } else {
            setErrorStatus(true)
            setErrorMessage(validatedRes.data.message)
            data.push(res)
            setFiles([...data])
            setIsFileUploading(false)
          }
        })
        .catch((validatedRej) => {
          if (validatedRej) {
            if (!validatedRej.response.data.status) {
              setErrorStatus(true)
              setErrorMessage(validatedRej.response.data.message)
              data.push(res)
              setFiles([...data])
              setIsFileUploading(false)
            }
          }
        })
    }
  }
  return (
    <React.Fragment>
      <Backdrop
        open={isFileUploading || isFileDownloading}
        className={styles.backdrop}
      >
        <CircularProgress color='inherit' />
      </Backdrop>
      <CustomModal
        isOpen={isOpenImportUsersModal}
        closeHandler={handleFileNameSelectedOnClose}
        cancelLabel='Cancel'
        submitLabel={isFileUploading ? 'Uploading file' : 'Upload file'}
        submitHandler={handleBulkUploading}
        width='640px'
        headerText='Import users using XLSX file'
        footerStyle={{
          justifyContent: 'center'
        }}
        isDisabledSubmitBtn={
          fileNames.length === 0 || isFileUploading || isError
        }
      >
        <Grid container className={styles.importModalBodyContainer}>
          <Grid
            item
            md={12}
            lg={12}
            xl={12}
            sm={12}
            xs={12}
            className={styles.downloadTemplateContainer}
          >
            <Box
              display='flex'
              flexDirection='row'
              justifyContent='space-between'
              className={styles.downloadTemplateDescContainer}
            >
              <Box className={styles.labelContainer}>
                <Typography className={styles.downlTempHeader}>
                  1. Download the template
                </Typography>
                <Typography className={styles.downlTempDescription}>
                  Use this template with predefined columns and add users to
                  your team
                </Typography>
              </Box>
              <Box>
                <CustomButton
                  type='text'
                  buttonText='Download template'
                  onClickHandler={downloadTemplate}
                  isLink
                />
              </Box>
            </Box>
          </Grid>
          <Divider className={clsx(styles.dividerColor, styles.w100)} />
          <Grid
            item
            md={12}
            lg={12}
            xl={12}
            sm={12}
            xs={12}
            className={styles.downloadTemplateContainer}
          >
            <Box
              display='flex'
              flexDirection='column'
              className={clsx(
                styles.downloadTemplateDescContainer,
                styles.colGap
              )}
            >
              <Box className={styles.labelContainer}>
                <Typography className={styles.downlTempHeader}>
                  2. Upload XLSX
                </Typography>
                <Typography className={styles.downlTempDescription}>
                  Once you’ve filled out the file, upload it here
                </Typography>
              </Box>
              <CustomDropzone
                handleDrop={handleDrop}
                fileNames={fileNames}
                removeSelectedFiles={removeSelectedFiles}
              />
              {isError && (
                <Box display='flex' alignItems='center' style={{ gap: '10px' }}>
                  <ErrorIcon />
                  <Typography className={styles.errorText}>
                    {errorMessage}
                  </Typography>
                </Box>
              )}
            </Box>
          </Grid>
        </Grid>
      </CustomModal>
    </React.Fragment>
  )
}
