import { Backdrop, Box, CircularProgress, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import styles from './UserList.module.scss'
import { CustomButton } from '../../../UIComponents/Button/CustomButton'
import { CustomSelectButton } from '../../../UIComponents/Button/CustomSelectButton'
import { ExportIcon } from '../../../utils/icons/ExportIcon'
import AddIcon from '../../../utils/icons/AddIcon'
import { StickyHeadTable } from './Table'

import { useDispatch, useSelector } from 'react-redux'
import withStore from '../../../store/withStore'
import {
  deActivateUsers,
  exportUsers,
  fetchUsersList,
  showDataFetchingStatus,
  stopDataFetchingStatus,
  updateActiveStatus,
  verifyAccessToken
} from '../../../store'
import { userListUrl } from '../../../utils/Urls'
import { CustomModal } from '../../../UIComponents/Modal'
import { useHistory } from 'react-router-dom'
import { BulkUploadModal } from './BulkUploadModal'
import _env from '../../../../env'
import UUIToastService from '../../../utils/ToastService'
import VerificationError from '../Components/VerificationError'
import { downloadExecutionJob } from '../../../store/UserBulkImport/actions'
import SkeletonLoader from '../CustomFields/SkeletonLoader'

const UserList = ({ config, isProfile }) => {
  _env.init(config, isProfile)
  const history = useHistory()
  const { userListInfo, isDataFetching, deactivatedUsers } = useSelector(
    (state) => state.userList
  )
  const [page, setPage] = React.useState(1)
  const [rowsPerPage, setRowsPerPage] = useState(5)
  const [selected, setSelected] = React.useState({})
  const [order, setOrder] = React.useState('asc')
  const [orderBy, setOrderBy] = React.useState('first_name')
  const [searchValue, setSearchValue] = useState('')
  const [isOpenDeActivateModal, setOpenDeActivateModalFlag] = useState(false)
  const { isTokenVerified, hasVerificationError, isILadmin, companyName } =
    useSelector((state) => state.common)
  const [isOpenImportUsersModal, setOpenImportUsersModal] = useState(false)
  const [isFileDownloading, setDownlStatus] = useState(false)
  const [isSortingChange, setSortingChange] = useState(false)
  const [isSearchingChange, setSearchingChange] = useState(false)
  const [isSortingChangeTemp, setSortingChangeTemp] = useState(false)
  const [isSearchingChangeTemp, setSearchingChangeTemp] = useState(false)
  const [isApiFetching, setisApiFetching] = useState(false)
  const openDeactivateModal = () => {
    if (
      Object.values(selected).flat().length === 0 ||
      Object.values(selected).flat() === undefined
    ) {
      UUIToastService.warningNotify('Select at least one user to deactivate.')
      return false
    }
    setOpenDeActivateModalFlag(true)
  }

  const closeDeActivateModal = () => {
    setOpenDeActivateModalFlag(false)
  }

  const handleAddPageNavigation = () => {
    history.push(config.routes.users.add.path)
  }

  const handleImportPagenavigation = (jobId) => {
    history.push(
      config.routes.bulkUpload.import.path.replace(
        `:${config.routes.bulkUpload.import.paramKeys.jobId}`,
        jobId
      )
    )
  }

  const handleEditUserClick = (userId) => {
    history.push(
      config.routes.users.edit.path.replace(
        `:${config.routes.users.edit.paramKeys.userId}`,
        userId
      )
    )
  }

  const handleBulkActionHistoryClick = () => {
    history.push(config.routes.bulkUpload.bulkActionHistory.path)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
    setSortingChange(true)
    setSortingChangeTemp(!isSortingChangeTemp)
  }

  const deselectingCheckboxHandler = (page) => {
    const newSelecteds = userListInfo.results
      .filter((n) => n.is_active)
      .map((obj) => obj.id.toString())
    setSelected({ ...selected, [page]: newSelecteds })
  }

  const handleSelectAllClick = (event, page) => {
    if (event.target.checked) {
      deselectingCheckboxHandler(page)
      return
    }
    setSelected({ ...selected, [page]: [] })
  }

  const onRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
    setSortingChange(true)
    setSortingChangeTemp(!isSortingChangeTemp)
  }

  const onSearchHandler = (value) => {
    setSearchValue(value)
    setSearchingChange(true)
    setSearchingChangeTemp(!isSearchingChangeTemp)
  }

  const handleSingleCheckBoxClick = (event, id, page) => {
    const selectedTemp = { ...selected }
    if (!(page in selectedTemp)) {
      selectedTemp[page] = []
    }
    const selectedIndex = selectedTemp[page].indexOf(id)
    let newSelected = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedTemp[page], id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedTemp[page].slice(1))
    } else if (selectedIndex === selectedTemp[page].length - 1) {
      newSelected = newSelected.concat(selectedTemp[page].slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedTemp[page].slice(0, selectedIndex),
        selectedTemp[page].slice(selectedIndex + 1)
      )
    }

    setSelected({ ...selected, [page]: newSelected })
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value)
    setPage(1)
    setSelected({})
    setSortingChange(true)
    setSortingChangeTemp(!isSortingChangeTemp)
  }
  const handleActiveUser = (e, id, page) => {
    var isActive = e.target.checked
    dispatch(updateActiveStatus(e.target.checked ? 1 : 0, e.target.id)).then(
      () => {
        if (!isActive) {
          if (Object.values(selected).flat().includes(id.toString())) {
            const selectedTemp = { ...selected }
            selectedTemp[page].splice(
              selectedTemp[page].indexOf(id.toString()),
              1
            )
            setSelected({ ...selected, [page]: selectedTemp[page] })
          }
        }
      }
    )
  }

  const handleDeactivateUsers = () => {
    dispatch(deActivateUsers(Object.values(selected).flat()))
    setOpenDeActivateModalFlag(false)
    setSelected({})
  }

  const importUserHandler = () => {
    setOpenImportUsersModal(true)
  }

  const closeImportUsersModal = () => {
    setOpenImportUsersModal(false)
  }

  const closeImportModal = (jobId) => {
    setOpenImportUsersModal(false)
    handleImportPagenavigation(jobId)
  }

  const handleExportUsers = async () => {
    setDownlStatus(true)
    await dispatch(exportUsers())
      .then((validatedRes) => {
        if (validatedRes.data?.status) {
          dispatch(downloadExecutionJob(validatedRes.data.audit_id, 'status'))
            .then((res) => {
              window.open(res.data.presigned_url, '_self').focus()
            })
            .catch((reject) => {
              if (reject) {
                if (!reject.response.data.status) {
                  UUIToastService.warningNotify(reject.response.data.message)
                }
              }
            })
            .finally(() => {
              setDownlStatus(false)
            })
        }
      })
      .catch((reject) => {
        if (reject) {
          if (!reject.response.data.status) {
            UUIToastService.warningNotify(reject.response.data.message)
            setDownlStatus(false)
          }
        }
      })
  }

  const BulkUploadInfo = [
    {
      label: 'Import users',
      handler: importUserHandler
    },
    {
      label: 'Deactivate users',
      handler: openDeactivateModal
    },
    {
      label: 'Bulk action history',
      handler: handleBulkActionHistoryClick
    }
  ]

  useEffect(() => {
    const roleCanAccess = [
      'travel_itilite_admin',
      'travel_admin',
      'expense_itilite_admin',
      'expense_admin'
    ]
    dispatch(showDataFetchingStatus())
    dispatch(verifyAccessToken(roleCanAccess))
  }, [])

  const dispatch = useDispatch()
  useEffect(() => {
    if (isTokenVerified === true) {
      const pageNum = page
      const searchParam =
        searchValue.trim() !== '' ? '&search=' + searchValue : ''
      const orderingParam =
        orderBy === ''
          ? ''
          : order === 'asc'
          ? '&ordering=' + orderBy
          : '&ordering=-' + orderBy
      const url =
        userListUrl.getUser +
        '?length=' +
        rowsPerPage +
        '&page=' +
        pageNum +
        searchParam +
        orderingParam
      if (isSearchingChange) {
        const delayDebounceFn = setTimeout(() => {
          dispatch(fetchUsersList(url)).then(() => setSearchingChange(false))
        }, 2000)

        return () => clearTimeout(delayDebounceFn)
      }

      if (isSortingChange) {
        setisApiFetching(true)
        dispatch(fetchUsersList(url))
          .then(() => {
            setisApiFetching(false)
            setSortingChange(false)
          })
          .catch(() => {
            setisApiFetching(false)
            setSortingChange(false)
          })
      }
      if (!isSearchingChange && !isSortingChange) {
        dispatch(showDataFetchingStatus())
        dispatch(fetchUsersList(url)).then(() =>
          dispatch(stopDataFetchingStatus())
        )
      }
    }
  }, [isTokenVerified, isSearchingChangeTemp, isSortingChangeTemp])

  return (
    <Box component='div' className={styles.UUIUserManagementList}>
      {!hasVerificationError ? (
        <React.Fragment>
          <Backdrop
            open={isFileDownloading || isApiFetching}
            className={styles.backdrop}
          >
            <CircularProgress color='inherit' />
          </Backdrop>
          {isDataFetching ? (
            <SkeletonLoader />
          ) : (
            <React.Fragment>
              <Box component='div' className={clsx(styles.pageHeader)}>
                <Box
                  component='div'
                  display='flex'
                  justifyContent='space-between'
                  alignItems='center'
                  flexWrap='wrap'
                  style={{ gap: '1rem' }}
                >
                  <Box
                    component='div'
                    display='flex'
                    flexDirection='column'
                    style={{ gap: '0.5rem' }}
                  >
                    <Box component='div' className={styles.headingWrapper}>
                      <Typography
                        variant='h3'
                        className={clsx(styles.heading, styles.headingLarge)}
                      >
                        {isILadmin && companyName
                          ? `${companyName} - Users`
                          : 'Users'}
                      </Typography>
                    </Box>

                    <Box component='div' className={styles.headingWrapper}>
                      <Typography
                        variant='body2'
                        className={clsx(styles.description)}
                      >
                        Create new users, customize user permissions and manage
                        user accounts
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    component='div'
                    className={styles.buttonWrapper}
                    display='flex'
                    justifyContent='space-between'
                    alignItems='center'
                    alignSelf='flex-start'
                  >
                    <Box
                      component='div'
                      className={clsx(styles.buttonSingle, styles.buttonLeft)}
                    >
                      <CustomButton
                        buttonText='Export all users'
                        type='outlined'
                        iconComponent={<ExportIcon width='12' height='12' />}
                        onClickHandler={handleExportUsers}
                      />
                    </Box>
                    <Box
                      component='div'
                      className={clsx(styles.buttonSingle, styles.buttonLeft)}
                    >
                      <CustomSelectButton
                        buttonText='Bulk actions'
                        type='outlined'
                        menuListInfo={BulkUploadInfo}
                      />
                    </Box>
                    <Box
                      component='div'
                      className='pageHeader--button-single button-right'
                    >
                      <CustomButton
                        buttonText='Add user'
                        type='contained'
                        iconComponent={
                          <AddIcon width='9' height='9' color='white' />
                        }
                        onClickHandler={handleAddPageNavigation}
                      />
                    </Box>
                  </Box>
                </Box>
                <Box
                  component='div'
                  display='flex'
                  justifyContent='space-between'
                  alignItems='center'
                />
              </Box>

              <Box className={styles.tablewrapper}>
                <StickyHeadTable
                  data={userListInfo}
                  handleChangeRowsPerPage={handleChangeRowsPerPage}
                  handleChangePage={handleChangePage}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  handleActiveUser={handleActiveUser}
                  handleSelectAllClick={handleSelectAllClick}
                  selected={selected}
                  handleSingleCheckBoxClick={handleSingleCheckBoxClick}
                  orderBy={orderBy}
                  order={order}
                  onRequestSort={onRequestSort}
                  onSearchHandler={onSearchHandler}
                  onEditHandler={handleEditUserClick}
                  disableRowCheckbox={deactivatedUsers}
                />
              </Box>
            </React.Fragment>
          )}
          <CustomModal
            isOpen={
              Object.values(selected).flat().length > 0 && isOpenDeActivateModal
            }
            closeHandler={closeDeActivateModal}
            cancelLabel='Cancel'
            submitLabel='Deactivate'
            submitHandler={handleDeactivateUsers}
            width='508px'
            hideCloseIcon
            modalBodyStyle={{
              padding: '0px'
            }}
            footerStyle={{
              justifyContent: 'center'
            }}
          >
            <Box
              component='div'
              display='flex'
              justifyContent='center'
              alignItems='center'
              flexDirection='column'
              padding='27px 16px 27px'
              className={styles.deActivateModalContainer}
            >
              <Typography className={styles.headerText}>
                {'Are you sure you want to deactivate ' +
                  Object.values(selected).flat().length.toString() +
                  (Object.values(selected).flat().length === 1
                    ? ' user ?'
                    : ' users ?')}
              </Typography>
              <Typography className={styles.description}>
                The{' '}
                {Object.values(selected).flat().length === 1
                  ? ' user '
                  : ' users '}
                won’t be able to access itilite platform
              </Typography>
            </Box>
          </CustomModal>
          <BulkUploadModal
            isOpenImportUsersModal={isOpenImportUsersModal}
            closeImportUsersModal={closeImportUsersModal}
            closeImportModal={closeImportModal}
          />
        </React.Fragment>
      ) : (
        <VerificationError config={config} errMsg={hasVerificationError} />
      )}
    </Box>
  )
}

export default withStore(UserList)
