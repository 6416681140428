import {
  Backdrop,
  Box,
  CircularProgress,
  Divider,
  Typography
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { BreadCrumb } from '../../../UIComponents/Breadcrumb/BreadCrumb'
import clsx from 'clsx'
import styles from './UserBulkUpload.module.scss'
import withStore from '../../../store/withStore'
import { BulkActionHistoryTable } from './Table'
import { useDispatch, useSelector } from 'react-redux'
import { userBulkImport } from '../../../utils/Urls'
import {
  downloadExecutionJob,
  downloadUploadedRecords,
  fetchBulkactionHistory,
  showLoader,
  stopLoader
} from '../../../store/UserBulkImport/actions'
import _env from '../../../../env'
import { verifyAccessToken } from '../../../store'
import { useHistory } from 'react-router-dom'
import UUIToastService from '../../../utils/ToastService'
import { ChevronNext } from '../../../utils/icons/ChevronNextIcon'
import SkeletonLoader from '../CustomFields/SkeletonLoader'

const BulkActionHistory = ({ config }) => {
  _env.setBaseUrl(config.env.baseURL)
  _env.setExpense(config.env.isExpenseProduct)
  _env.setTravel(config.env.isTravelProduct)
  const history = useHistory()
  const [page, setPage] = React.useState(1)
  const [rowsPerPage, setRowsPerPage] = useState(5)
  const [order, setOrder] = React.useState('asc')
  const [orderBy, setOrderBy] = React.useState('id')
  const [searchValue, setSearchValue] = useState('')
  const [isSortingChange, setSortingChange] = useState(false)
  const [isSearchingChange, setSearchingChange] = useState(false)
  const [isSortingChangeTemp, setSortingChangeTemp] = useState(false)
  const [isSearchingChangeTemp, setSearchingChangeTemp] = useState(false)
  const [isApiFetching, setisApiFetching] = useState(false)
  const { isTokenVerified } = useSelector((state) => state.common)
  const { bulkActionFields, loading } = useSelector(
    (state) => state.userBulkImport
  )
  const [isFileDownloading, setDownlStatus] = useState(false)

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
    setSortingChange(true)
    setSortingChangeTemp(!isSortingChangeTemp)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value)
    setPage(1)
    setSortingChange(true)
    setSortingChangeTemp(!isSortingChangeTemp)
  }

  const onRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
    setSortingChange(true)
    setSortingChangeTemp(!isSortingChangeTemp)
  }

  const onSearchHandler = (value) => {
    setSearchValue(value)
    setSearchingChange(true)
    setSearchingChangeTemp(!isSearchingChangeTemp)
  }

  const handleNavigateToImportUserPage = (event, jobId) => {
    history.push(
      config.routes.bulkUpload.import.path.replace(
        `:${config.routes.bulkUpload.import.paramKeys.jobId}`,
        jobId
      )
    )
  }

  const handleDownloadRecords = async (e, type, jobId) => {
    setDownlStatus(true)
    if (e.stopPropagation) e.stopPropagation()
    await dispatch(downloadUploadedRecords(type, jobId))
      .then((validatedRes) => {
        if (validatedRes.data?.status) {
          dispatch(downloadExecutionJob(validatedRes.data.audit_id, 'status'))
            .then((res) => {
              window.open(res.data.presigned_url, '_self').focus()
            })
            .catch((reject) => {
              if (reject) {
                if (!reject.response.data.status) {
                  UUIToastService.warningNotify(reject.response.data.message)
                }
              }
            })
            .finally(() => {
              setDownlStatus(false)
            })
        }
      })
      .catch((reject) => {
        if (reject) {
          if (!reject.response.data.status) {
            UUIToastService.warningNotify(reject.response.data.message)
            setDownlStatus(false)
          }
        }
      })
  }

  const dispatch = useDispatch()
  useEffect(() => {
    const roleCanAccess = [
      'travel_itilite_admin',
      'travel_admin',
      'expense_itilite_admin',
      'expense_admin'
    ]
    dispatch(showLoader())
    dispatch(verifyAccessToken(roleCanAccess))
  }, [])

  useEffect(() => {
    if (isTokenVerified === true) {
      const pageNum = page
      const searchParam =
        searchValue.trim() !== '' ? '&search=' + searchValue : ''
      const orderingParam =
        orderBy === ''
          ? ''
          : order === 'asc'
          ? '&ordering=' + orderBy
          : '&ordering=-' + orderBy
      const url =
        userBulkImport.bulkActionHistory() +
        '?length=' +
        rowsPerPage +
        '&page=' +
        pageNum +
        searchParam +
        orderingParam
      if (isSearchingChange) {
        const delayDebounceFn = setTimeout(() => {
          dispatch(fetchBulkactionHistory(url)).then(() => {
            setSearchingChange(false)
          })
        }, 2000)

        return () => clearTimeout(delayDebounceFn)
      }
      if (isSortingChange) {
        setisApiFetching(true)
        dispatch(fetchBulkactionHistory(url))
          .then(() => {
            setisApiFetching(false)
            setSortingChange(false)
          })
          .catch(() => {
            setisApiFetching(false)
            setSortingChange(false)
          })
      }
      if (!isSearchingChange && !isSortingChange) {
        dispatch(showLoader())
        dispatch(fetchBulkactionHistory(url)).then(() => {
          dispatch(stopLoader())
        })
      }
    }
  }, [isTokenVerified, isSearchingChangeTemp, isSortingChangeTemp])

  return (
    <Box component='div' className={styles.UUIUserManagementImport}>
      <Backdrop
        open={isApiFetching || isFileDownloading}
        className={styles.backdrop}
      >
        <CircularProgress color='inherit' />
      </Backdrop>
      {loading ? (
        <SkeletonLoader />
      ) : (
        <React.Fragment>
          <React.Fragment>
            <Box component='div' className={clsx(styles.pageHeader)}>
              <Box component='div' className={styles.breadcrumb}>
                <BreadCrumb
                  breadCrumbs={[
                    {
                      href: config.routes.users.list.path,
                      label: 'Users',
                      hasLink: true
                    }
                  ]}
                  customStyle={{ color: '#6B7280' }}
                  separator={<ChevronNext />}
                  forward
                />
              </Box>
              <Box
                component='div'
                display='flex'
                justifyContent='space-between'
                alignItems='center'
              >
                <Box component='div' className={styles.headingWrapper}>
                  <Typography
                    variant='h3'
                    className={clsx(styles.heading, styles.headingLarge)}
                  >
                    Bulk action history
                  </Typography>
                </Box>
              </Box>
            </Box>
          </React.Fragment>
          <Divider className={styles.dividerColor} />
          <Box className={styles.tablewrapper}>
            <BulkActionHistoryTable
              data={bulkActionFields}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              handleChangePage={handleChangePage}
              page={page}
              rowsPerPage={rowsPerPage}
              orderBy={orderBy}
              order={order}
              onRequestSort={onRequestSort}
              onSearchHandler={onSearchHandler}
              handleDownloadRecords={handleDownloadRecords}
              handleNavigateToImportUserPage={handleNavigateToImportUserPage}
            />
          </Box>
        </React.Fragment>
      )}
    </Box>
  )
}

export default withStore(BulkActionHistory)
