import React, { Fragment } from 'react'
import './Userpanel.scss'
import { CustomModal } from '../../../UIComponents/Modal'
import { useSelector } from 'react-redux'
import { Box, Typography } from '@material-ui/core'

export default function DeletePanelModal({
  isOpen,
  closeHandler,
  submitHandler,
  openMoveHandler,
  value,
  list,
  isError,
  customSelectHandler,
  isDisabledSubmitBtn
}) {
  const { dialogData } = useSelector((state) => state.userPanel)
  const data = dialogData.data
  const labelData = dialogData.labels

  return (
    <Fragment>
      <CustomModal
        isOpen={isOpen}
        openMoveHandler={openMoveHandler}
        showMoveButton={labelData.showMoveButton}
        closeHandler={closeHandler}
        headerText={labelData.headerText}
        cancelLabel='Cancel'
        submitLabel='Delete'
        submitHandler={submitHandler}
        isDisabledSubmitBtn={isDisabledSubmitBtn}
        width='508px'
        hideCloseIcon={false}
        modalBodyStyle={{
          maxHeight: '600px',
          overflow: 'auto',
          margin: '0px'
        }}
        footerStyle={{
          justifyContent: 'end'
        }}
      >
        <Box
          component='div'
          display='flex'
          justifyContent='center'
          alignItems='center'
          flexDirection='column'
          padding='8px 24px'
        >
          <Typography>
            Are you sure about deleting {data.name}? This action can’t be undone
          </Typography>
        </Box>
      </CustomModal>
    </Fragment>
  )
}
