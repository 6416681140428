import React from 'react'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import Typography from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link'
import { Box } from '@material-ui/core'
import classes from './BreadCrumb.module.scss'
import { NavigateNextIcon } from '../../utils/icons/NavigateNextIcon'

// eslint-disable-next-line no-unused-vars
function handleClick(event) {
  event.preventDefault()
}

export const BreadCrumb = (props) => {
  const {
    breadCrumbs,
    separator = <NavigateNextIcon width='6' height='10' />,
    forward = false,
    customStyle = {}
  } = props

  return (
    <Box display='flex' alignItems='center' gridGap='8px'>
      {forward ? null : separator}
      <Breadcrumbs separator={separator} aria-label='breadcrumb'>
        {breadCrumbs &&
          breadCrumbs.length > 0 &&
          breadCrumbs.map((_c, _i) => {
            return _c.hasLink ? (
              <Link
                style={customStyle}
                href={_c.href}
                key={_i}
                className={classes.label}
              >
                {_c.label}
              </Link>
            ) : (
              <Typography className={classes.label}> {_c.label}</Typography>
            )
          })}
      </Breadcrumbs>
      {forward ? separator : null}
    </Box>
  )
}
