import { VERIFY_ACCESS_TOKEN, SET_VERIFICATION_ERROR } from './types'

const initialState = {
  id: null,
  email: null,
  company: null,
  companyName: null,
  isILadmin: 1,
  applicable_role: [],
  isTokenVerified: false,
  hasVerificationError: false
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case VERIFY_ACCESS_TOKEN:
      return {
        ...state,
        id: action.payload.id,
        email: action.payload.email,
        company: action.payload.company,
        companyName: action.payload.company_name,
        isILadmin: action.payload.is_iladmin,
        applicable_role: action.payload.applicable_role,
        isTokenVerified: true
      }

    case SET_VERIFICATION_ERROR:
      return {
        ...state,
        hasVerificationError: action.payload
      }
    default:
      return state
  }
}

export default reducer
