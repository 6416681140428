import { Box, Typography } from '@material-ui/core'
import React from 'react'
import withStore from '../../../store/withStore'
import { useDispatch, useSelector } from 'react-redux'
import { openDialog } from '../../../store/userPanel/actions'
import UserPanel from '../UserPanel'
import _env from '../../../../env'

const Departments = (props) => {
  const dispatch = useDispatch()
  const { userPanelData } = useSelector((state) => state.userPanel)
  const { config, isProfile } = props
  _env.init(config, isProfile)

  const openDeleteModal = (data) => {
    dispatch(
      openDialog(
        {
          headerText: `Delete ${data.name}?`,
          cancelLabel: 'Cancel',
          submitLabel: 'Yes, Delete',
          moveLabel: 'Replace/Move Users'
        },
        <Box
          component='div'
          display='flex'
          justifyContent='center'
          alignItems='center'
          flexDirection='column'
        >
          <Typography>
            Are you sure about deleting {data.name}? This action can’t be undone
          </Typography>
        </Box>,
        data
      )
    )
  }

  const openEditModal = (data) => {
    dispatch(
      openDialog(
        {
          headerText: `Edit ${data.name}?`,
          cancelLabel: 'Cancel',
          submitLabel: 'Save',
          width: '400px'
        },
        '',
        data
      )
    )
  }

  return (
    <React.Fragment>
      <UserPanel
        title='Department'
        headingName='Departments'
        subHeading={`Add your organization's departments so that employees can be assigned to their departments`}
        tableName='department'
        module='department'
        handleDeleteDialog={openDeleteModal}
        handleEditDialog={openEditModal}
        data={userPanelData.results}
        config={config}
      />
    </React.Fragment>
  )
}

export default withStore(Departments)
