import {
  SHOW_LOADER,
  STOP_LOADER,
  SET_CUSTOM_FIELDS,
  REMOVE_CUSTOM_FIELD,
  UPDATE_CUSTOM_FIELD,
  SET_MODAL,
  RESET_EDIT_MODAL,
  SET_EDIT_MODAL
} from './types'
import api from '../../utils/Api'
import { customFieldsUrls } from '../../utils/Urls'
import UUIToastService from '../../utils/ToastService'

export const showLoader = () => {
  return {
    type: SHOW_LOADER
  }
}

export const stopLoader = () => {
  return {
    type: STOP_LOADER
  }
}

export const toggleModal = (payload) => {
  return {
    type: SET_MODAL,
    payload
  }
}

export const resetEditModal = () => {
  return {
    type: RESET_EDIT_MODAL
  }
}

export const setEditModal = (payload) => {
  return {
    type: SET_EDIT_MODAL,
    payload
  }
}

export const setCustomFields = (payload) => {
  return {
    type: SET_CUSTOM_FIELDS,
    payload
  }
}

export const removeCustomField = (payload) => {
  return {
    type: REMOVE_CUSTOM_FIELD,
    payload
  }
}

export const updateCustomField = (payload) => {
  return {
    type: UPDATE_CUSTOM_FIELD,
    payload
  }
}

export const fetchCustomFields = () => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(showLoader())
      api
        .get(customFieldsUrls.customFields())
        .then((res) => {
          if (res.data.status === true) {
            dispatch(
              setCustomFields(res.data.result.filter((row) => row.is_active))
            )
            resolve()
          } else {
            UUIToastService.warningNotify(res.data.message)
            reject(res)
          }
        })
        .catch((err) => {
          UUIToastService.warningNotify(
            err?.response?.data?.message || 'Opps, Something went wrong.'
          )
          reject(err)
        })
        .finally(() => {
          dispatch(stopLoader())
        })
    })
  }
}

export const deleteCustomField = (attributeColumn) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      api
        .delete(customFieldsUrls.customFields(), {
          data: {
            attribute_column: attributeColumn
          }
        })
        .then((res) => {
          if (res.data.status === true) {
            dispatch(removeCustomField(attributeColumn))
            UUIToastService.sNotify(res.data.message)
            resolve()
          } else {
            UUIToastService.warningNotify(res.data.message)
            reject(res)
          }
        })
        .catch((err) => {
          UUIToastService.warningNotify(
            err?.response?.data?.message || 'Opps, Something went wrong.'
          )
          reject(err)
        })
    })
  }
}

export const editCustomFieldLabel = (payload) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      api
        .put(customFieldsUrls.customFields(), payload)
        .then((res) => {
          if (res.data.status === true) {
            dispatch(updateCustomField(payload))
            UUIToastService.sNotify(res.data.message)
            resolve()
          } else {
            UUIToastService.warningNotify(res.data.message)
            reject(res)
          }
        })
        .catch((err) => {
          UUIToastService.warningNotify(
            err?.response?.data?.message || 'Opps, Something went wrong.'
          )
          reject(err)
        })
    })
  }
}
